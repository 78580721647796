import React, { useContext } from 'react'

import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'
import moment from 'moment'

import { formatCpfCnpj, formatMoney } from 'utils'

import { FormNegativationContext } from '../../context'
import { NegativationSteps, StepsEnum } from '../../utils'
import { S } from './'
import Term from './components/Term'

const Activation: React.FC = () => {
  const { step, acceptTerm, billingSelected, infosCustomer, updateAcceptTerm } =
    useContext(FormNegativationContext)

  const addressIndex = infosCustomer?.addresses?.length - 1
  const address = infosCustomer?.addresses?.[addressIndex]

  if (step !== StepsEnum[NegativationSteps.ACTIVATION]) {
    return null
  }

  return (
    <>
      <S.Section>
        <S.Header>Resumo da negativação</S.Header>
        <S.Content>
          <Typography fontSize="12px" color="#757675">
            Revise os dados da cobrança do seu cliente:
          </Typography>

          <S.Section>
            <Typography variant="mdBold">Cobrança</Typography>
            <Box
              display="flex"
              flexDirection="column"
              gap="8px"
              marginTop="10px"
            >
              <S.Infos>
                <Typography variant="mdLight" color="#757675">
                  Data da negativação
                </Typography>
                <S.Separator />
                <Typography variant="mdBold">
                  {moment().format('DD/MM/yyyy')}
                </Typography>
              </S.Infos>
              <S.Infos>
                <Typography variant="mdLight" color="#757675">
                  Valor da cobrança
                </Typography>
                <S.Separator />
                <Typography variant="mdBold">
                  {formatMoney(billingSelected?.amount_billed)}
                </Typography>
              </S.Infos>
              <S.Infos>
                <Typography variant="mdLight" color="#757675">
                  Custo da negativação
                </Typography>
                <S.Separator />
                <Typography variant="mdBold">{formatMoney('19.9')}</Typography>
              </S.Infos>
            </Box>
          </S.Section>
          <S.Section>
            <Typography variant="mdBold">Pagador</Typography>
            <Box
              display="flex"
              flexDirection="column"
              gap="8px"
              marginTop="10px"
            >
              <S.Infos>
                <Typography variant="mdLight" color="#757675">
                  Nome
                </Typography>
                <S.Separator />
                <Typography variant="mdBold">{infosCustomer?.name}</Typography>
              </S.Infos>
              <S.Infos>
                <Typography variant="mdLight" color="#757675">
                  CPF/CNPJ
                </Typography>
                <S.Separator />
                <Typography variant="mdBold">
                  {formatCpfCnpj(infosCustomer?.document)}
                </Typography>
              </S.Infos>
              <S.Infos>
                <Typography variant="mdLight" color="#757675">
                  Endereço
                </Typography>
                <S.Separator />
                <Typography variant="mdBold">
                  {`${address?.neighborhood}, ${address?.number}, ${address?.city} - ${address?.state}`}
                </Typography>
              </S.Infos>
            </Box>
          </S.Section>
        </S.Content>
      </S.Section>
      <S.Section>
        <S.Header>Termos</S.Header>
        <S.Content>
          <S.Section
            sx={{ display: 'flex', flexDirection: 'column', gap: '2px' }}
          >
            <Typography fontSize="12px" variant="smLight" color="#757675">
              1. O custo dessa operação é de R$ 19,90 e será descontado no
              momento em que a negativação for aprovada;
            </Typography>
            <Typography fontSize="12px" variant="smLight" color="#757675">
              2. O presente serviço consiste na solicitação de negativação de
              devedores do banco de dados do Boa Vista;
            </Typography>
            <Typography fontSize="12px" variant="smLight" color="#757675">
              3. Após a solicitação de negativação, a qual poderá ser aprovada
              ou não, ocorrerá o fluxo de negativação;
            </Typography>
            <Typography fontSize="12px" variant="smLight" color="#757675">
              4. É de sua responsabilidade o fornecimento das informações reais
              e atualizadas em relação às cobranças e ao cliente a serem
              encaminhadas para negativação;
            </Typography>
            <Typography fontSize="12px" variant="smLight" color="#757675">
              5. É de sua responsabilidade solicitar à Base de Clientes, até 5
              dias após o pagamento, a exclusão dos registros cujo os clientes,
              por qualquer motivo, não devam figurar na base de dados de
              inadimplentes.
            </Typography>

            <S.Term>
              <Term />
            </S.Term>

            <RadioGroup
              aria-labelledby="group-type"
              value={acceptTerm}
              onChange={(event) => {
                updateAcceptTerm(Boolean(event))
              }}
            >
              <FormControlLabel
                value={'true'}
                control={<Radio />}
                label="Aceito os termos e condições"
              />
            </RadioGroup>
          </S.Section>
        </S.Content>
      </S.Section>
    </>
  )
}

export default Activation
