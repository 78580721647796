import {
  Table as CTable,
  TableCell as CTableCell,
  TableRow as CTableRow,
  tableCellClasses
} from '@mui/material'
import { styled } from '@mui/material'

export const Table = styled(CTable)(() => ({
  '.MuiTableCell-root': {
    border: 0
  },
  boxShadow: '0',
  overflowX: 'auto',
  overflowY: 'auto'
}))

export const TableHeadCell = styled(CTableCell)(({ theme }) => ({
  cursor: 'default',
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.neutral['500'],
    fontWeight: '600',
    padding: '8px 4px 7px'
  },
  [`&.${tableCellClasses.body}`]: {
    border: 0,
    boxShadow: 0,
    fontSize: 14,
    padding: '8px 4px 7px'
  }
}))

export const TableCell = styled(CTableCell)(({ theme }) => ({
  cursor: 'pointer',
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.neutral['500'],
    fontWeight: '600',
    padding: '8px 4px 7px'
  },
  [`&.${tableCellClasses.body}`]: {
    border: 0,
    boxShadow: 0,
    fontSize: 14,
    padding: '8px 4px 7px'
  }
}))

type TableRowProps = {
  hoverInRow?: boolean
}

export const TableRow = styled(CTableRow)<TableRowProps>(
  ({ theme, hoverInRow }) => ({
    '&:hover': {
      ...(hoverInRow && { background: `${theme.palette.primary[200]}10` })
    },
    '& td, & th': {
      borderBottom: '1px solid #E3E6E3 !important'
    }
  })
)
