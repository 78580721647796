import { Messages } from 'react-big-calendar'

export const mountDateCalendar = (array: string[], start: boolean): Date => {
  return new Date(
    Number(array[0]),
    Number(array[1]) - 1,
    Number(array[2]),
    start ? 6 : 12,
    start ? 30 : 0,
    0
  )
}

export const Language: Messages = {
  today: 'Hoje',
  previous: 'Voltar',
  next: 'Próximo',
  month: 'Calendário',
  week: 'Semana',
  day: 'Dia',
  date: 'Data',
  time: 'Horário',
  event: 'Evento',
  noEventsInRange: 'Nenhum recebível agendado para liberação.',
  agenda: 'Lista'
}
