import { DataService } from 'api/DataService'
import { NFeParams } from 'models/NFe'

export class NFeService {
  public static async getNFeDetails(nfs_uuid: string): Promise<NFeParams> {
    const response = await DataService({
      type: 'GET',
      url: `/v1/nfses/${nfs_uuid}`
    })

    if (response.status === 200) {
      return response?.data?.record
    }

    return {} as NFeParams
  }
}
