export const getHoverText = (status: string) => {
  switch (status) {
    case 'pending':
      return {
        text: 'A nota fiscal foi criada e está em processo de emissão'
      }
    case 'issue':
      return {
        text: 'A nota fiscal foi emitida e está aguardando a confirmação'
      }
    case 'confirmed':
      return {
        text: 'A nota fiscal foi emitida e confirmada'
      }
    case 'canceled':
      return {
        text: 'A nota fiscal foi cancelada'
      }
    case 'failed':
      return {
        text: 'Erro ao emitir a nota fiscal'
      }
    default:
      window.newrelic.noticeError(
        new Error(
          `Erro ao mapear o texto da tooltip de notas fiscais: ${status}`
        )
      )
      return {
        text: 'Status desconhecido'
      }
  }
}
