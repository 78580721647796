import React from 'react'

import { Modal } from '@mui/material'
import Image from 'next/image'

import * as S from './styles'

type ModalImageProps = {
  image: string
  setImage: React.Dispatch<React.SetStateAction<string>>
}

const ModalImage = ({ image, setImage }: ModalImageProps) => {
  return (
    <Modal open={!!image} onClose={() => setImage('')}>
      <>
        <S.Wrapper>
          <Image
            src={image}
            alt="asd"
            style={{ maxHeight: '90%', maxWidth: '90%' }}
          />
        </S.Wrapper>
        <S.Overlay onClick={() => setImage('')} />
      </>
    </Modal>
  )
}

export default ModalImage
