import {
  darken,
  IconButton,
  Box as MUIBox,
  styled,
  Typography
} from '@mui/material'

import { Container } from '../'

export const Content = styled(Container)(({ theme }) => ({
  borderRadius: '8px',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  height: '92%',
  maxHeight: '100%',
  overflowY: 'hidden',
  padding: 32,
  width: '92%',

  [theme.breakpoints.down('md')]: {
    maxHeight: '100%',
    width: '92%'
  }
}))

export const CloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  right: 5,
  top: 5
}))

export const Box = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

export const Header = styled(MUIBox)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    width: '100%',
    gap: '0.625rem'
  }
}))

export const Heading = styled(Typography)`
  align-items: center;
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
`

export const ContainerDetailsBalance = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}))

export const TableWrapper = styled('div')(({ theme }) => ({
  flex: 1,
  height: '100%',
  overflowY: 'auto',
  '& tr:hover': {
    cursor: 'pointer',
    background: theme.palette.neutral[800]
  }
}))

export const ReportButton = styled('button')(({ theme }) => ({
  ['&:disabled']: {
    background: darken(theme.palette.secondary[200], 0.35),
    cursor: 'not-allowed'
  },
  ['&:focus, &:hover']: {
    outline: `1px solid ${theme.palette.neutral[100]}`
  },
  alignItems: 'center',
  all: 'inherit',
  border: `1px solid ${theme.palette.neutral[500]}`,
  borderRadius: 4,
  boxSizing: 'border-box',
  cursor: 'pointer',
  display: 'flex',
  fontWeight: 400,
  lineHeight: 1.63,
  marginLeft: 'auto',
  maxWidth: 170,
  outline: '1px solid transparent',
  padding: '6.8px 14px',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%'
  }
}))

export const PlunderButton = styled('button')(({ theme }) => ({
  ['&:disabled']: {
    background: darken(theme.palette.secondary[200], 0.35),
    cursor: 'not-allowed'
  },
  ['&:focus']: {
    outline: `1px solid ${theme.palette.neutral[100]}`
  },
  ['&:focus, &:hover']: {
    borderColor: theme.palette.neutral[100]
  },
  alignItems: 'center',
  all: 'inherit',
  background: theme.palette.neutral[200],
  border: '1px solid transparent',
  borderRadius: 4,
  boxSizing: 'border-box',
  color: theme.palette.neutral[900],
  cursor: 'pointer',
  display: 'flex',
  fontWeight: 400,
  lineHeight: 1.63,
  marginLeft: 8,
  maxWidth: 170,
  padding: '6.8px 14px',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    marginLeft: '0px',
    marginBottom: '0.625rem'
  }
}))

export const AlertWrapper = styled('div')(() => ({
  position: 'absolute',
  right: 10,
  top: 90,
  zIndex: 99999
}))

export const Overlay = styled('div')(() => ({
  background: '#00000020',
  display: 'grid',
  inset: '0 0 0 0',
  placeItems: 'center',
  position: 'fixed',
  zIndex: 1200
}))
