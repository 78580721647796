import { styled } from '@mui/system'

export const Overlay = styled('div')(() => ({
  width: '100vw',
  height: '100vh',

  position: 'fixed',
  inset: '0 0 0 0',

  zIndex: '-1'
}))

export const Wrapper = styled('div')(() => ({
  width: 'fit-content',
  height: 'fit-content',

  '& img': {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 8
  }
}))
