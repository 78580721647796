import React from 'react'

export interface ModalProps {
  open: boolean
  handleClose: (refresh: boolean) => void
  size?: 'small' | 'large' | 'extraLarge' | 'hue'
  zIndex?: number
  disablePortal?: boolean
  children: React.ReactNode
  width?: string | number
  height?: string | number
  maxWidth?: string | number
  maxHeight?: string | number
  title?: string
  footer?: React.ReactNode
}

export interface ModalRootProps {
  size: 'small' | 'large' | 'extraLarge' | 'hue'
  width?: string | number
  height?: string | number
  maxWidth?: string | number
  maxHeight?: string | number
}
