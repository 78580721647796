import { Box, styled } from '@mui/material'

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '12px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column'
  }
}))

interface SelectTypeProps {
  actived?: boolean
}

export const SelectType = styled(Box)<SelectTypeProps>(
  ({ actived, theme }) => ({
    cursor: 'pointer',
    background: actived
      ? 'linear-gradient(263deg, #00FF9B -3.46%, #00F4F5 104.78%);'
      : '#FFF',
    borderRadius: '10px',
    padding: '6px 16px',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      marginTop: '12px',
      width: '100%'
    }
  })
)
