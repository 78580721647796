import { VariantButton } from '@clientbase/clientbase-library'

enum ChipSize {
  small = 0,
  medium = 1,
  large = 2
}

enum CurveSide {
  no = 0,
  left = 1,
  right = 2
}

export const getCurveSideString = (
  curveSide: CurveSide | undefined
): 'right' | 'left' | undefined => {
  switch (curveSide) {
    case CurveSide.left:
      return 'left'
    case CurveSide.right:
      return 'right'
    default:
      return undefined
  }
}

export interface BannerParams {
  uuid: string
  banner_type?: string
  title: string
  subtitle?: string
  has_chip?: boolean
  chip_label?: string
  button_variant?: VariantButton
  button_text: string
  link: string
  bg_color: string
  curve_color: string
  chip_color: string
  chip_size?: ChipSize
  curve_side?: CurveSide
  show_on_all_pages: boolean
}

export interface Banner {
  bannerType?: string
  title: string
  subtitle?: string
  hasChip?: boolean
  chipLabel?: string
  buttonVariant?: VariantButton
  buttonText: string
  link?: string
  bgColor: string
  curveColor: string
  chipColor: string
  titleFontColor: string
  subtitleFontColor: string
  chipFontColor: string
  chipSize?: ChipSize
  curveSide?: CurveSide
  showOnAllPages: boolean
}

export function constructorBanner(data: any) {
  if (!data) {
    return null
  }
  return {
    bannerType: data?.banner_type,
    title: data?.title,
    subtitle: data?.subtitle,
    hasChip: data?.has_chip,
    chipLabel: data?.chip_label,
    buttonVariant: data?.button_variant,
    buttonText: data?.button_text,
    link: data?.link,
    bgColor: data?.bg_color,
    curveColor: data?.curve_color,
    chipColor: data?.chip_color,
    titleFontColor: data?.title_font_color,
    subtitleFontColor: data?.subtitle_font_color,
    chipFontColor: data?.chip_font_color,
    chipSize: data?.chip_size,
    curveSide: data?.curve_side,
    showOnAllPages: data?.show_on_all_pages
  } as Banner
}
