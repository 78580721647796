import { styled } from '@mui/material'

export const FixedWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 'wrap',
  justifyContent: 'end',
  alignItems: 'top',
  border: '1px solid white',
  position: 'fixed',
  bottom: '0%',
  right: '0%',
  padding: '0 30px',
  backgroundColor: 'white',
  width: '100%',
  height: '60px',
  [theme.breakpoints.down(508)]: {
    position: 'relative',
    padding: '0px',
    left: '2.5%',
    marginBottom: '15px'
  }
}))

export const FixedWrapperChildren = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  justifyContent: 'flex-end',
  borderTop: '2px solid #F7FAF7',
  [theme.breakpoints.down(450)]: {
    justifyContent: 'center',
    paddingTop: '0px'
  }
}))

export const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px',
  alignContent: 'center',
  [theme.breakpoints.down(450)]: {
    justifyContent: 'center'
  }
}))
