import React from 'react'

import { Box, Typography } from '@mui/material'
import { DatePickerProps } from '@mui/x-date-pickers'

import * as S from './DatePicker.styles'

export interface DatePickerComponent<T, U> extends DatePickerProps<T, U> {
  label?: string
  helperText?: string
  error?: boolean
}

export const DatePicker: React.FC<DatePickerComponent<any, any>> = ({
  label,
  helperText,
  error,
  ...rest
}) => {
  return (
    <Box width="100%">
      <Typography
        mb="4px"
        variant="mdLight"
        sx={(theme) => ({
          color: error ? theme.palette.error[300] : 'black'
        })}
      >
        {label}
      </Typography>
      <S.DatePicker
        {...rest}
        error={error}
        PopperProps={{
          placement: 'bottom-start'
        }}
        maxDate={
          !rest.maxDate
            ? new Date(new Date().setFullYear(new Date().getFullYear() + 2))
            : rest.maxDate
        }
      />
      {helperText && (
        <Typography color="#CC2942" mt="4px" variant="smBold">
          {helperText}
        </Typography>
      )}
    </Box>
  )
}
