import { useCallback, useEffect, useState } from 'react'

import { getAPIClient } from 'api/axios'
import axios, { AxiosError } from 'axios'

interface FetchProps {
  url?: string
  method?: 'POST' | 'GET' | 'PATCH' | 'DELETE'
  shouldRun?: boolean
}

interface StateParams {
  error: null | AxiosError | Error | string
  result: any
  status: 'idle' | 'pending' | 'settled' | 'error'
  code: null | number
}

export const useFetch = ({
  url,
  method = 'GET',
  shouldRun = false
}: FetchProps) => {
  const [state, setState] = useState<StateParams>({
    result: null,
    error: null,
    code: null,
    status: 'idle'
  })

  const client = getAPIClient()

  const run = useCallback(
    ({
      data,
      paramUrl,
      query
    }: {
      data?: any
      paramUrl?: string
      query?: string
    }) => {
      setState({
        code: null,
        error: null,
        result: null,
        status: 'pending'
      })

      return client({
        data,
        method,
        url: paramUrl
          ? `${url}/${paramUrl}${query ? query : ''}`
          : `${url}${query ? query : ''}`
      })
        .then((response) => {
          const data = response?.data ? response?.data : {}
          setState({
            error: null,
            result: {
              data: data?.records ? data?.records : data?.record,
              message: data?.message,
              meta: data?.meta
            },
            code: response.status,
            status: 'settled'
          })
        })
        .catch((error) => {
          const errors = error as AxiosError
          setState({
            error:
              axios.isAxiosError(errors) && errors.response
                ? errors.response.data?.message
                : null,
            code:
              axios.isAxiosError(errors) && errors.response
                ? errors.response.status
                : 401,
            result: null,
            status: 'error'
          })
        })
    },
    []
  )

  useEffect(() => {
    if (shouldRun) run({})
    return () => {
      setState({
        code: null,
        error: null,
        result: null,
        status: 'idle'
      })
    }
  }, [shouldRun, run])

  return [run, state.result, state.error, state.status] as const
}
