import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import {
  AutoCompleteState,
  Button,
  StateParams,
  Textfield
} from '@clientbase/clientbase-library'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/system'
import { DataService } from 'api/DataService'
import axios from 'axios'
import { useOnboardingContext } from 'context/OnboardingContext'
import { ActivateAccountFormsProps } from 'templates/ActivateAccount/ActivateAccount'

import InputController from 'components/InputController'

import { Debouncer, onlyNumber, UFOptions } from 'utils'

import { AddressFormSchema, schemaAddressForm } from './'

export const initialAddressFormSchemaValue = {
  city: '',
  complement: '',
  neighborhood: '',
  number: '',
  postal_code: '',
  street: ''
} as AddressFormSchema

const AddressForm = ({
  sucessSubmit,
  handleBackStep
}: ActivateAccountFormsProps) => {
  const { handleChange, values } = useOnboardingContext()

  const [stateError, setStateError] = useState<string | undefined>()
  const [stateValue, setStateValue] = useState<StateParams | null>({
    label: '',
    value: ''
  })

  const { handleSubmit, control, watch, setValue } = useForm<AddressFormSchema>(
    {
      defaultValues: values['2'],
      resolver: yupResolver(schemaAddressForm)
    }
  )

  const { postal_code } = watch()

  const onSubmit: SubmitHandler<AddressFormSchema> = async (data) => {
    const postal_code = onlyNumber(data?.postal_code)
    const response = await DataService({
      type: 'PATCH',
      data: {
        addresses_attributes: [
          { ...data, postal_code, state: stateValue?.value.trim() }
        ]
      },
      url: '/v1/onboarding/address'
    })

    !response.error && handleChange(2, data)
    return sucessSubmit({ error: response?.error, message: response?.message })
  }

  const getCEP = async (cep: string) => {
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      const { localidade, logradouro, bairro, uf } = response.data

      !!localidade && setValue('city', localidade)
      !!bairro && setValue('neighborhood', bairro)
      !!logradouro && setValue('street', logradouro)
      setStateValue(UFOptions.filter((cep) => cep.value === uf)[0])
    } catch (err) {
      return
    }
  }
  const awaitForSubmitCEP = Debouncer(getCEP, 1000)

  useEffect(() => {
    if (onlyNumber(postal_code).length === 8) {
      awaitForSubmitCEP(onlyNumber(postal_code))
    }
  }, [postal_code])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputController control={control} name="postal_code">
        <Textfield label="CEP:" mask="cep" />
      </InputController>
      <InputController control={control} name="street">
        <Textfield label="Logradouro:" />
      </InputController>
      <div>
        <InputController control={control} name="number">
          <Textfield label="Número:" />
        </InputController>
        <InputController control={control} name="complement">
          <Textfield label="Complemento:" />
        </InputController>
        <AutoCompleteState
          error={stateError}
          setStateError={setStateError}
          setStateValue={setStateValue}
          stateValue={stateValue}
        />
      </div>
      <div>
        <InputController control={control} name="neighborhood">
          <Textfield label="Bairro:" />
        </InputController>
        <InputController control={control} name="city">
          <Textfield label="Cidade:" />
        </InputController>
      </div>

      <Box mt={2} display="flex" gap={1.5}>
        <Button
          fullWidth
          variantButton="secondary"
          type="button"
          onClick={handleBackStep}
          icon="keyboardArrowLeft"
        >
          VOLTAR
        </Button>
        <Button fullWidth variantButton="green" type="submit">
          CONTINUAR
        </Button>
      </Box>
    </form>
  )
}

export default AddressForm
