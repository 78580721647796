import { Dispatch, SetStateAction } from 'react'

import { Icon } from '@clientbase/clientbase-library'
import { Box, IconButton } from '@mui/material'
import useTrackGTMEvents from 'hooks/use-track-gtm-events'
import { Banner, getCurveSideString } from 'models/Banner'
import { useRouter } from 'next/router'

import * as S from './NewsBanner.styles'
import Svg from './svg'

type NewsBannerProps = {
  banner: Banner
  setIsBannerOpen: Dispatch<SetStateAction<boolean>>
}

const NewsBanner: React.FC<NewsBannerProps> = ({
  banner,
  setIsBannerOpen
}: NewsBannerProps) => {
  const {
    bannerType,
    hasChip = false,
    chipLabel,
    title,
    subtitle,
    buttonVariant = 'primary',
    buttonText,
    link = '',
    curveColor,
    bgColor,
    chipColor,
    titleFontColor,
    subtitleFontColor,
    chipFontColor,
    curveSide
  } = banner

  const { push } = useRouter()

  const closeBanner = () => setIsBannerOpen(false)

  const redirectToLink = () => push(link)

  const trackEvent = useTrackGTMEvents()
  const handleClickBanner = () => {
    trackEvent({
      event: 'banner_click',
      bannerType: bannerType as string
    })
  }

  return (
    <S.Div id="click_on_banner" onClick={handleClickBanner}>
      <IconButton
        onClick={closeBanner}
        sx={{
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: 99,
          aspectRatio: '1 / 1',
          display: 'auto'
        }}
      >
        <Icon icon="close" sx={{ color: '#FFF' }} fontSize="small" />
      </IconButton>
      <S.Wrapper
        link={!!link}
        bgColor={bgColor}
        onClick={() => link && redirectToLink()}
      >
        <Svg
          curveColor={curveColor}
          direction={getCurveSideString(curveSide)}
        />
        {hasChip && (
          <S.Chip bgColor={chipColor} color={chipFontColor}>
            {chipLabel}
          </S.Chip>
        )}
        <Box flexGrow={1} color={'#fff'} zIndex={3}>
          <S.Title color={titleFontColor}>
            <div dangerouslySetInnerHTML={{ __html: title }} />
          </S.Title>
          <S.Subtitle color={subtitleFontColor}>
            <div dangerouslySetInnerHTML={{ __html: subtitle || '' }} />
          </S.Subtitle>
        </Box>
        <S.ButtonCustom variantButton={buttonVariant}>
          <S.ButtonText>{buttonText}</S.ButtonText>
        </S.ButtonCustom>
      </S.Wrapper>
    </S.Div>
  )
}

export default NewsBanner
