import moment from 'moment'

type MessageTrackingItem = {
  dateSent: string
  campaignMedium: string
  campaign: string
  status: string
}

export type MessageTracking = MessageTrackingItem[]

export const preExpiration = [
  'Envio de fatura',
  'Lembrete de vencimento',
  'Abertura da fatura'
]

export const posExpiration = [
  'Confirmação de pagamento',
  'Fatura em atraso',
  'Cobrança expirada'
]

const notificationDetailMapper = (
  messageTracking: MessageTracking,
  tab: number
) => {
  const filteredItems =
    tab === 0
      ? messageTracking.filter((item) => preExpiration.includes(item.campaign))
      : messageTracking.filter((item) => !preExpiration.includes(item.campaign))
  return filteredItems.length > 0
    ? filteredItems.map((item) => ({
        dateSent: moment(item?.dateSent).format('DD/MM/YYYY HH:mm:ss'),
        campaingMedium: item?.campaignMedium,
        campaing: item?.campaign,
        status: item.status
      }))
    : []
}

export default notificationDetailMapper
