import * as yup from 'yup'

export const schemaBankForm = yup.object({
  account: yup.string().required('Número da conta obrigatório'),
  agency: yup.string().required('Agência obrigatória'),
  account_dv: yup.string(),
  bank: yup
    .object({
      name: yup.string(),
      uuid: yup.string()
    })
    .required('Selecione um banco')
    .nullable(true)
})

export type BankFormSchema = yup.InferType<typeof schemaBankForm>
