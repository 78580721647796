import { GetServerSideProps, NextPage } from 'next'
import { parseCookies } from 'nookies'

import decodeBase64 from 'utils/decode-base64-to-string'
import { destroySession } from 'utils/DestroySession'
import { CustomerTabs } from 'utils/tabs'

export const REDIRECT_URL_BY_ROLE = {
  admin: '/visao-geral',
  operator: `/clientes?tab=${CustomerTabs.CUSTOMERS}`,
  financial_operator: `/clientes?tab=${CustomerTabs.CUSTOMERS}`,
  user: '/visao-geral',
  guest: `/clientes?tab=${CustomerTabs.CUSTOMERS}`
}

export const Home: NextPage = () => {
  return null
}

export default Home

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { ['nextauth.role']: role, ['nextauth.token']: token } =
    parseCookies(ctx)

  if (!role && !token) {
    return {
      redirect: {
        destination: '/login',
        permanent: false
      }
    }
  }

  const decryptRole = decodeBase64(role)
  const isValidRole = Object.keys(REDIRECT_URL_BY_ROLE).find(
    (k) => k === decryptRole
  )

  if (!isValidRole) {
    destroySession(ctx)
    return {
      redirect: {
        destination: '/login',
        permanent: false
      }
    }
  }

  if (!!token && !!decryptRole) {
    return {
      redirect: {
        destination: REDIRECT_URL_BY_ROLE[decryptRole] ?? '/login',
        permanent: false
      }
    }
  }

  return {
    redirect: {
      destination: '/login',
      permanent: false
    }
  }
}
