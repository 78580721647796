import { Typography } from '@mui/material'
import Image from 'next/image'

import * as S from './Empty.styles'

type EmptyProps = {
  description: string
  pt?: boolean
}

const Empty = ({ description, pt = false }: EmptyProps) => {
  return (
    <S.Wrapper pt={pt}>
      <S.ImageWrapper>
        <Image src="/only-icon.png" alt="logo da clientbase" layout="fill" />
      </S.ImageWrapper>
      <Typography variant="mdLight" sx={{ cursor: 'default' }}>
        {description}
      </Typography>
    </S.Wrapper>
  )
}

export default Empty
