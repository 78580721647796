import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'

export const DynamicTableSkeleton = () => {
  return (
    <Grid width="100%">
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        gap={1}
      >
        <Skeleton width="80%" height={40} animation="wave" variant="text" />
        <Skeleton width="80%" height={40} animation="wave" variant="text" />
        <Skeleton width="80%" height={40} animation="wave" variant="text" />
        <Skeleton width="80%" height={40} animation="wave" variant="text" />
        <Skeleton width="80%" height={40} animation="wave" variant="text" />
      </Box>

      <Skeleton
        width="100%"
        height={400}
        animation="wave"
        variant="rectangular"
      />

      <Box display="flex" justifyContent="flex-end" gap={1}>
        <Skeleton width={120} height={40} animation="wave" variant="text" />
        <Skeleton width={30} height={40} animation="wave" variant="text" />
        <Skeleton width={70} height={40} animation="wave" variant="text" />
        <Skeleton width={100} height={40} animation="wave" variant="text" />
      </Box>
    </Grid>
  )
}
