import { IconButton, Box as MUIBox, styled, Typography } from '@mui/material'

import { Container } from '../'

export const Content = styled(Container)(({ theme }) => ({
  padding: 26,
  borderRadius: '8px',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  height: '92%',
  maxHeight: '100%',
  overflowY: 'hidden',
  paddingBottom: 32,
  width: '92%',

  [theme.breakpoints.down('md')]: {
    maxHeight: '100%',
    width: '92%'
  }
}))

export const CloseButton = styled(IconButton)(() => ({
  cursor: 'pointer',
  position: 'absolute',
  right: 5,
  top: 5
}))

export const Header = styled('div')(({ theme }) => ({
  alignItems: 'start',
  borderBottom: `3px solid ${theme.palette.neutral[700]}`,
  display: 'grid',
  gap: 10,
  gridTemplateColumns: 'repeat(auto-fit, minmax(340px, 1fr))',
  paddingBottom: 10
}))

export const Heading = styled(Typography)`
  align-items: center;
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
`

export const StatusText = styled(Typography)(() => ({
  fontSize: '14px'
}))

export const Box = styled(MUIBox)(() => ({
  marginTop: '-50px',
  display: 'flex',
  flexDirection: 'column'
}))

export const TableWrapper = styled('div')(() => ({
  flex: 1,
  height: '100%',
  overflowY: 'auto'
}))

interface NotifyProps {
  notViewed: boolean
}

export const Separator = styled('span')(() => ({
  width: '8px'
}))

export const NotifyWrapper = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  gap: '8px'
}))

export const Notify = styled('div')<NotifyProps>(({ notViewed, theme }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  background: notViewed ? theme.palette.success[300] : theme.palette.error[300]
}))
