/* eslint-disable sort-keys */

import { Icon } from '@clientbase/clientbase-library'
import { Box, Tooltip } from '@mui/material'

import { ButtonRounded } from 'components/button'
import Can from 'components/Can'
import {
  TableNameWrapper,
  TableNickname
} from 'components/DynamicTable/DynamicTable.styles'

import { formatDate } from 'utils/FormatDate'
import { formatMoney } from 'utils/FormatMoney'

import { theme } from 'styles/theme'

import * as S from './index.styles'

type ConvertToRowsParams = {
  records: any
  actions: any
}

type CreateDataTableProps = {
  name: string
  due_date: string
  status: string
  amount: string
  uuid: string
  actions: any
}

const chargeStatusOption = {
  pending: {
    label: 'Pendente',
    color: theme.palette.neutral[700],
    disabled: true
  },
  created_payment: {
    label: 'Pendente',
    color: theme.palette.neutral[700],
    disabled: true
  },
  open_payment: {
    label: 'Aberta',
    color: theme.palette.secondary[300]
  },
  no_payment: {
    label: 'Expirada',
    color: theme.palette.error[300]
  },
  expiring: {
    color: theme.palette.error[400],
    label: 'Expirada'
  },
  paid: {
    label: 'Paga',
    color: theme.palette.primary[200]
  },
  cancelling: {
    label: 'Cancelada',
    color: theme.palette.error[300]
  },
  cancelled: {
    label: 'Cancelada',
    color: theme.palette.error[300]
  },
  expired: {
    label: 'Expirada',
    color: theme.palette.error[300]
  },
  suspended: {
    label: 'Suspensa',
    color: theme.palette.neutral[700]
  }
}

export const createDataTable = ({
  uuid,
  name,
  due_date,
  status,
  amount,
  actions
}: CreateDataTableProps) => {
  return {
    uuid,
    name: (
      <TableNameWrapper>
        <TableNickname>{name}</TableNickname>
      </TableNameWrapper>
    ),
    due_date: formatDate(due_date),
    status: (
      <S.Status status={status}>{chargeStatusOption[status].label}</S.Status>
    ),
    amount: formatMoney(amount),
    actions: (
      <Can roles={['admin', 'financial_operator', 'operator', 'user']}>
        <Tooltip
          enterTouchDelay={0}
          arrow={true}
          placement="top"
          title="Detalhes cobrança"
        >
          <Box
            maxWidth="50px"
            zIndex={999}
            onClick={() => actions.handleOpenModal(uuid)}
          >
            <ButtonRounded variant="transparent" data-cy="open-billing">
              <Icon
                icon="settings"
                sx={{ color: theme.palette.neutral[200] }}
              />
            </ButtonRounded>
          </Box>
        </Tooltip>
      </Can>
    )
  }
}

export const convertToRows = ({ records, actions }: ConvertToRowsParams) =>
  records?.map((record: any) => {
    if (!record) return {}
    return createDataTable({
      uuid: record?.uuid,
      name: record?.customer?.name,
      due_date: record?.due_date,
      status: record?.status,
      amount: record?.amount_billed,
      actions
    })
  })
