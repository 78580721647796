export const NegativationSteps = Object.freeze({
  SELECT: 'Seleção',
  REVISION: 'Revisão',
  ACTIVATION: 'Ativação'
})

export const STEPS = [
  NegativationSteps.SELECT,
  NegativationSteps.REVISION,
  NegativationSteps.ACTIVATION
]

export const StepsEnum = Object.freeze({
  [NegativationSteps.SELECT]: 0,
  [NegativationSteps.REVISION]: 1,
  [NegativationSteps.ACTIVATION]: 2
})
