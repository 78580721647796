import React, { useContext } from 'react'

import { Button } from '@clientbase/clientbase-library'
import { ChargeContext } from 'templates/Charge/context'
import ModalNegativation from 'templates/v2/Negativation/components/FormNegativation'

import Can from 'components/Can'

import { theme } from 'styles/theme'

import { ModalChargeDetailContext } from '../../context'
import { getStatus } from '../../ModalChargeDetail.utils'
import { S } from './'

const Actions: React.FC = () => {
  const { invoice, updateModal } = useContext(ModalChargeDetailContext)
  const { toogleModal, modal: modalCharge } = useContext(ChargeContext)

  const toggleModal = () => {
    toogleModal('modalNegativationDetail')
  }

  const handleOpenEditCharge = () => {
    updateModal((prevState) => ({ ...prevState, isOpenEditCharge: true }))
  }

  function handleOpenDialogCancel(uuid: string) {
    updateModal((prevState) => ({
      ...prevState,
      isOpenDialogCancel: true,
      uuid
    }))
  }

  function handleOpenDialogAntecipate(uuid: string) {
    updateModal((prevState) => ({
      ...prevState,
      isOpenDialogAntecipate: true,
      uuid
    }))
  }

  function handleOpenMarkAsPaid() {
    updateModal((prevState) => ({
      ...prevState,
      isOpenModalPaid: true
    }))
  }

  if (getStatus(invoice?.status)?.label === 'Paga') {
    return null
  }

  return (
    <S.FixedWrapper>
      <S.FixedWrapperChildren>
        <S.ButtonWrapper>
          <Can roles={['admin', 'financial_operator', 'operator', 'user']}>
            <>
              {['no_payment', 'expiring'].includes(invoice?.status) && (
                <Button
                  sx={{
                    [theme.breakpoints.down(450)]: {
                      width: '100%'
                    }
                  }}
                  onClick={toggleModal}
                  hasFocus
                >
                  Negativar
                </Button>
              )}

              <Button
                sx={{
                  [theme.breakpoints.down(450)]: {
                    width: '100%'
                  }
                }}
                hasFocus
                onClick={handleOpenEditCharge}
                variantButton="secondary"
              >
                Editar fatura
              </Button>

              {getStatus(invoice?.status)?.label !== 'Cancelada' && (
                <Button
                  sx={{
                    [theme.breakpoints.down(450)]: {
                      width: '100%'
                    }
                  }}
                  hasFocus
                  onClick={() => handleOpenDialogCancel(invoice?.uuid)}
                  style={{
                    backgroundColor: theme.palette.neutral[400]
                  }}
                >
                  Cancelar fatura
                </Button>
              )}

              {getStatus(invoice?.status)?.label === 'Pendente' && (
                <Button
                  sx={{
                    [theme.breakpoints.down(450)]: {
                      width: '100%'
                    }
                  }}
                  hasFocus
                  onClick={() => handleOpenDialogAntecipate(invoice?.uuid)}
                  style={{
                    backgroundColor: theme.palette.info[300]
                  }}
                >
                  Adiantar emissão
                </Button>
              )}

              <Button
                sx={{
                  [theme.breakpoints.down(450)]: {
                    width: '100%'
                  }
                }}
                variantButton="green"
                bgColor={theme.palette.primary.main}
                hasFocus
                onClick={handleOpenMarkAsPaid}
              >
                Marcar como paga
              </Button>
            </>
          </Can>
        </S.ButtonWrapper>
      </S.FixedWrapperChildren>
      <ModalNegativation
        toggleModal={toggleModal}
        modalNegativation={modalCharge?.modalNegativationDetail}
        invoice={invoice}
      />
    </S.FixedWrapper>
  )
}

export default Actions
