import moment from 'moment'

import * as S from './Status.styles'

const statusMapper = {
  pending: {
    label: 'Pendente',
    disabled: true
  },
  processing: {
    label: 'Processando',
    disabled: true
  },
  created_payment: {
    label: 'Pendente',
    disabled: true
  },
  open_payment: {
    label: 'Aberta'
  },
  no_payment: {
    label: 'Expirada'
  },
  expiring: {
    label: 'Expirada'
  },
  paid: {
    label: 'Paga'
  },
  active: {
    label: 'Ativa'
  },
  confirmed: {
    label: 'Confirmada'
  },
  cancelling: {
    label: 'Cancelada'
  },
  canceled: {
    label: 'Cancelada'
  },
  cancelled: {
    label: 'Cancelada'
  },
  expired: {
    label: 'Expirada'
  },
  finished: {
    label: 'Finalizada'
  },
  suspended: {
    label: 'Suspensa'
  },
  with_error: {
    label: 'Com erro'
  },
  succeeded: {
    label: 'Confirmada'
  },
  failed: {
    label: 'Cancelada'
  },
  archived: {
    label: 'Arquivado'
  },
  blocked: {
    label: 'Bloqueado'
  },
  deleted: {
    label: 'Deletado'
  }
}

export type StatusKeys = keyof typeof statusMapper

type StatusProps = {
  status: StatusKeys
  fontIsThini?: boolean
  dueDate?: string
}

export const Status = ({ status, fontIsThini, dueDate }: StatusProps) => {
  const today = moment().startOf('day').format('YYYY-MM-DD')
  const isLate =
    status === 'open_payment' && dueDate && moment(dueDate).isBefore(today)
  return (
    <S.Status fontIsThini={fontIsThini} status={status} dueDate={dueDate}>
      {isLate ? 'Atrasada' : statusMapper[status].label}
    </S.Status>
  )
}

export default Status
