import React from 'react'

import { Modal } from '@clientbase/clientbase-library'
import { InvoiceParams } from 'models/Invoice'
import { ChargeBodyModalClientProps } from 'templates/Customers/Modals/BodyModalNewChargeOrRecurrence/BodyModalNewChargeOrRecurrence'

import EditCharge from './ModalEditCharge.utils'

type EditChargeProps = Pick<ChargeBodyModalClientProps, 'handleCloseModal'>

type ModalEditChargeProps = {
  isOpenModalEditCharge: boolean
  uuid: string
  charge: InvoiceParams
  reloadCharge: () => void
  setReloadTable?: React.Dispatch<React.SetStateAction<boolean>>
  handleCloseModal?: (refresh?: boolean) => void
} & EditChargeProps

const ModalEditCharge = ({
  isOpenModalEditCharge,
  handleCloseModal,
  uuid,
  charge,
  reloadCharge,
  setReloadTable,
  ...rest
}: ModalEditChargeProps) => {
  const resetCharge = () => {
    reloadCharge()
    !!setReloadTable && setReloadTable((s) => !s)
  }

  return (
    <Modal
      handleClose={handleCloseModal}
      open={isOpenModalEditCharge}
      disablePortal={false}
      title="Editar Cobrança"
      width="100%"
      maxWidth="85vw"
      maxHeight="95vh"
      height="auto"
    >
      <EditCharge
        resetCharge={resetCharge}
        handleCloseModal={handleCloseModal}
        onClose={handleCloseModal}
        customer={charge?.customer}
        uuid={uuid}
        discounts={charge?.discounts}
        charge={charge}
        {...rest}
      />
    </Modal>
  )
}

export default ModalEditCharge
