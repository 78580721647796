import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  Button,
  Dialog,
  Loader,
  Textarea
} from '@clientbase/clientbase-library'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'
import { DataService } from 'api/DataService'
import { CustomerParams } from 'models/Customers'
import { DiscountParams } from 'models/Discounts'
import { InvoiceParams } from 'models/Invoice'
import moment from 'moment'
import BillingMethod from 'templates/Customers/Modals/BodyModalNewChargeOrRecurrence/Components/BillingMethod'
import Infos from 'templates/Customers/Modals/BodyModalNewChargeOrRecurrence/Components/Infos'
import OptionsAdditionals from 'templates/Customers/Modals/BodyModalNewChargeOrRecurrence/Components/OptionsAdditionals'
import PopoverNewVariant from 'templates/Customers/Modals/components/NewVariant'
import { v4 as uuidv4 } from 'uuid'

import IdentifyInternal from 'components/IdentifyInternal/IdentifyInternal'
import { IdentifyInternal as IIdentifyInternal } from 'components/IdentifyInternal/IdentifyInternal.interface'

import {
  currency,
  formatCents,
  formatCentsToSendToBackEnd,
  onlyNumber
} from 'utils'
import { toastProps } from 'utils/types/toast-props'

import { theme } from 'styles/theme'

import { enumNature } from '../ModalInvoice/ModalInvoice.utils'
import { EditChargeSchema, schemaFormEditCharge } from './schema'
import { DiscountField } from './template/Discounts.interface'

export interface ChargeBodyModalClientProps {
  resetCharge: (dataForm: EditChargeSchema) => void
  onClose: () => void
  handleCloseModal: () => void
  revalidateTable?: () => void
  customer: CustomerParams
  uuid: string
  discounts: DiscountParams[]
  charge: InvoiceParams
}

const initialDialogSettings = {
  isOpen: false,
  description: '',
  title: '',
  formData: {} as EditChargeSchema
}

function EditCharge({
  resetCharge,
  onClose,
  revalidateTable,
  customer,
  uuid,
  discounts,
  charge
}: ChargeBodyModalClientProps) {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    register,
    formState: { errors }
  } = useForm<EditChargeSchema>({
    defaultValues: {
      discountPolicy: false,
      interestPolicy: false,
      interestFee: '2.0',
      interestFine: '1.0',
      payments: ['pix'],
      max_installments: 12,
      max_installments_without_fee: 12,
      iss_retention: 'yes',
      issue_when: 'on_billing_issued',
      nature_operation: '1',
      amount_type: 'amount_paid',
      nfse_issuer_uuid: '',
      service_list_code: '',
      frequency: 1,
      totalCycles: 1,
      totalCyclesCustom: ''
    },
    resolver: yupResolver(schemaFormEditCharge)
  })
  const { uuid: customerUuid, name } = customer
  const { payments, description } = watch()

  const [dialog, setDialog] = useState(initialDialogSettings)
  const [loading, setLoading] = useState(false)

  const [addFieldDiscount, setAddFieldDiscount] = useState<DiscountField[]>([])

  const [identifyInternal, setIdentifyInternal] = useState<IIdentifyInternal[]>(
    []
  )

  const handleOpenDialog = ({
    title,
    description,
    formData
  }: {
    title: string
    description: string
    formData: EditChargeSchema
  }) => {
    setDialog({ title, description, isOpen: true, formData })
  }

  const handleCloseDialog = () => {
    setDialog((s) => ({ ...s, isOpen: false }))
  }

  const handleSubmitForm: SubmitHandler<EditChargeSchema> = async (
    dataForm
  ) => {
    setLoading(true)
    const amountBilledSplitted = String(dataForm.amountBilled).split('.')
    const amountBilled =
      amountBilledSplitted[1]?.length === 1
        ? dataForm.amountBilled + '0'
        : Number(String(dataForm.amountBilled).replace('.', ''))
            .toString()
            .replace(/\B(?=(\d{2})(?!\d))/g, '.')

    const billing = {
      total_cycles:
        dataForm?.totalCycles === 13
          ? dataForm?.totalCyclesCustom
          : dataForm?.totalCycles,
      amount_billed: amountBilled,
      description: dataForm.description,
      due_date: moment(dataForm.dueDate).format('YYYY-MM-DD'),
      discount_policy: dataForm.discountPolicy ? 4 : 1,
      interest_policy: dataForm.interestPolicy ? 3 : 1,
      ...(dataForm.interestPolicy && {
        interest_fee: dataForm.interestFee,
        interest_fine: dataForm.interestFine
      }),
      discounts_attributes: [] as {
        amount: number
        days: number
        policy: number
      }[],
      payments: (dataForm.payments ?? []).map((payment) => {
        if (payment !== 'credit_card_installments') {
          return { payment_type: payment }
        }

        return {
          payment_type: payment,
          max_installments: dataForm.max_installments,
          max_installments_without_fee: dataForm.max_installments_without_fee
        }
      }),
      credit_card_uuid: dataForm?.credit_card_uuid?.value,
      nfse_policy: !dataForm?.interestInvoice
        ? 'no_nfse'
        : dataForm?.issue_when,
      ...(dataForm?.interestInvoice && {
        nfse_scheduled_attributes: {
          amount_type: dataForm?.amount_type,
          ...(dataForm?.amount_type === 'amount_custom' &&
            dataForm?.amountCustom && {
              amount: currency(dataForm?.amountCustom)
                .replace('.', '')
                .replace(',', '.')
            }),
          description: dataForm?.descriptionInvoice,
          iss_retention: dataForm?.iss_retention === 'yes' ? true : false,
          nfse_issuer_uuid: dataForm?.nfse_issuer_uuid,
          nfse_issuer_service_uuid: dataForm?.service_list_code,
          nature_operation: Number(dataForm?.nature_operation)
        }
      }),
      metadata: {},
      expiration_date: moment(dataForm.dueDate)
        .add(dataForm.expirationField, 'days')
        .format('YYYY-MM-DD')
    }

    if (dataForm.discountPolicy) {
      addFieldDiscount.forEach(({ amount, date }, index) => {
        const discount = moment(date).format('YYYY-MM-DD')
        const amountBilled = watch(`amountBilled` as any)

        const attributes = {
          amount:
            addFieldDiscount[index].type === '0'
              ? Number(
                  (
                    Number(
                      formatCentsToSendToBackEnd(onlyNumber(amountBilled))
                    ) *
                    (Number(addFieldDiscount[index]?.amount) / 100)
                  ).toFixed(2)
                )
              : Number(
                  currency((!!amount && amount) || '0')
                    .replace('.', '')
                    .replace(',', '.')
                ),
          days: moment(dataForm.dueDate).diff(moment(discount), 'days'),
          policy: dataForm.discountPolicy ? 2 : 1
        }
        billing.discounts_attributes.push(attributes)
      })
    }

    if (identifyInternal.length > 0) {
      identifyInternal.map(({ name, value }) => {
        billing.metadata = { ...billing.metadata, [name as string]: value }
      })
    }

    const id = toast.loading('Editando a fatura...')

    const response = await DataService({
      data: billing,
      type: 'PATCH',
      url: `/v3/billings/${uuid}`
    })

    const errorMessage =
      response.error && (response.detail_error || response.message)

    toast.update(id, {
      render: response.error ? errorMessage : response.data.message,
      type: response.error ? 'error' : 'success',
      ...toastProps
    })

    if (!response.error) {
      onClose()
      resetCharge(dataForm)
    }

    handleCloseDialog()
    !!revalidateTable && revalidateTable()
    setLoading(false)
  }

  const handleClickToSubmit: SubmitHandler<EditChargeSchema> = async (
    dataForm
  ) => {
    if (!dataForm.payments?.length) return
    let isValid = true

    if (dataForm.discountPolicy) {
      addFieldDiscount.forEach((current) => {
        if (current.errors?.amount?.isError) {
          isValid = false
        }
        if (current.errors?.date?.isError) {
          isValid = false
        }
      })

      setAddFieldDiscount((current) => {
        return current.map((obj) => {
          let messageDate = undefined
          let messageAmount = undefined
          if (!obj.errors?.date?.isError)
            if (obj.date === undefined || !moment(obj.date).isValid()) {
              isValid = false
              messageDate = 'Campo obrigatório.'
            }
          if (!obj.errors?.amount?.isError)
            if (obj.amount === undefined || obj.amount === '') {
              isValid = false
              messageAmount = 'Campo obrigatório.'
            }

          if (messageAmount || messageDate) {
            return {
              ...obj,
              errors: {
                amount: messageAmount
                  ? { message: messageAmount, isError: true }
                  : { ...obj?.errors?.amount },
                date: messageDate
                  ? { message: messageDate, isError: true }
                  : { ...obj?.errors?.date }
              }
            }
          }
          return { ...obj }
        })
      })
    }

    if (identifyInternal.length > 0) {
      identifyInternal.forEach((current) => {
        if (
          current.errors?.name?.isError ||
          current?.name === undefined ||
          current?.name === ''
        ) {
          isValid = false
        }
        if (
          current.errors?.value?.isError ||
          current?.value === undefined ||
          current?.value === null
        ) {
          isValid = false
        }
      })

      setIdentifyInternal((current) => {
        return current.map((obj) => {
          let messageName = undefined
          let messageValue = undefined
          if (!obj.errors?.name?.isError) {
            if (obj.name === undefined || obj.name === '') {
              messageName = 'Campo obrigatório.'
            }
          }
          if (!obj.errors?.value?.isError) {
            if (obj.value === undefined || obj.value === '') {
              messageValue = 'Campo obrigatório.'
            }
          }

          if (messageName || messageValue) {
            return {
              ...obj,
              errors: {
                name: messageName
                  ? { message: messageName, isError: true }
                  : { ...obj?.errors?.name },
                value: messageValue
                  ? { message: messageValue, isError: true }
                  : { ...obj?.errors?.value }
              }
            }
          }
          return { ...obj }
        })
      })
    }

    if (isValid) {
      handleOpenDialog({
        title: 'Editar Cobrança',
        description: 'Confirma editar a cobrança deste cliente?',
        formData: dataForm
      })
    }
  }

  useEffect(() => {
    if (discounts.length > 0) {
      const array: DiscountField[] = []
      discounts.forEach(({ amount, days }) => {
        const dueDate = new Date(charge?.dueDate)

        dueDate.setDate(dueDate.getDate() + 1)

        dueDate.setDate(dueDate.getDate() - Number(days))

        const parseAmount = onlyNumber(formatCents(amount)) || 0

        array.push({
          amount: parseAmount.toString(),
          date: dueDate,
          uuid: uuidv4()
        })
      })
      setAddFieldDiscount(array)
    } else {
      setAddFieldDiscount([
        {
          date: undefined,
          amount: undefined,
          errors: undefined,
          uuid: uuidv4()
        }
      ])
    }

    if (charge?.metadata) {
      const metadata = [] as IIdentifyInternal[]

      Object.keys(charge?.metadata).map((key) => {
        metadata.push({
          name: key,
          value: charge?.metadata[key],
          uuid: uuidv4()
        })
      })
      setIdentifyInternal(metadata)
    }
  }, [])

  const getCharge = async () => {
    const parseAmount = onlyNumber(formatCents(charge.amountBilled)) || '0'
    const dueDate = moment(charge.dueDate) as unknown as string

    const parceledCreditCard = charge?.payments.find(
      (payment) => payment.paymentType === 'credit_card_installments'
    )

    if (parceledCreditCard) {
      setValue(
        'max_installments',
        parceledCreditCard?.paymentable.max_installments ?? 12
      )
      setValue(
        'max_installments_without_fee',
        parceledCreditCard?.paymentable.max_installments_without_fee ?? 12
      )
    }
    setValue(
      'expirationField',
      moment(charge.expirationField).diff(charge.dueDate, 'day').toString()
    )
    setValue('amountBilled', parseAmount)
    setValue('description', charge.description ? charge.description : '')
    setValue('discountPolicy', charge?.discounts.length > 0)
    setValue('interestPolicy', charge.interestPolicy !== 'no_interest')
    setValue('dueDate', new Date(dueDate))
    setValue('payments', charge.paymentType.split(';').filter(Boolean))
    setValue('credit_card_uuid.label', '')
    setValue('credit_card_uuid.value', '')
    setValue('interestFee', charge.interestFee || '2.0')
    setValue('interestFine', charge.interestFine || '1.0')

    setValue('interestInvoice', charge?.nfsesScheduled ? true : false)
    if (charge?.nfsesScheduled) {
      const {
        amount,
        amount_type,
        description,
        iss_retention,
        nature_operation,
        nfse_issuer,
        nfse_issuer_service
      } = charge.nfsesScheduled
      setValue('iss_retention', iss_retention ? 'yes' : 'no')
      setValue('service_list_code', nfse_issuer_service?.uuid)
      setValue('amountCustom', amount ? amount.toString() : undefined)
      setValue('descriptionInvoice', description)
      setValue('nfse_issuer_uuid', nfse_issuer?.uuid)
      setValue('issue_when', charge?.nfsePolicy)
      setValue('amount_type', amount_type)
      setValue('nature_operation', enumNature[nature_operation])
    }
  }

  useEffect(() => {
    getCharge()
  }, [uuid])

  if (loading) {
    return <Loader />
  }

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap="18px">
        <Infos
          control={control}
          dueDate={charge.dueDate}
          expirationField={charge.expirationField}
          edit={true}
          nameCustomer={name as string}
        />

        <BillingMethod
          control={control}
          payments={payments}
          register={register}
          watch={watch}
          customerUuid={customerUuid}
          errors={errors}
          edit={true}
        />

        <Box>
          <Controller
            control={control}
            name="description"
            render={({ field: { onChange, value } }) => (
              <Textarea
                fullWidth={true}
                onChange={onChange}
                placeholder="Descrição"
                value={value}
                maxLength={2000}
                name="description"
              />
            )}
          />
          <PopoverNewVariant
            setDescription={(message) => setValue('description', message)}
            description={description}
          />
        </Box>

        <OptionsAdditionals
          addFieldDiscount={addFieldDiscount as any}
          control={control}
          setAddFieldDiscount={setAddFieldDiscount as any}
          watch={watch}
          setValue={setValue}
        />

        <Box mt="18px" width="100%">
          <IdentifyInternal
            indetifyInternal={identifyInternal}
            setIdentifyInternal={setIdentifyInternal}
          />
        </Box>

        <Button
          buttonSize="lg"
          type="submit"
          hasFocus
          onClick={handleSubmit(handleClickToSubmit)}
          bgColor={theme.palette.primary[200]}
        >
          EDITAR
        </Button>
      </Box>

      <Dialog
        icon="description"
        title={dialog.title}
        description={dialog.description}
        setIsOpenDialog={handleCloseDialog}
        isOpenDialog={dialog.isOpen}
        cancelButton
        cancelButtonLabel="NÃO"
      >
        <Button
          autoFocus
          disabled={loading}
          hasFocus
          onClick={() => handleSubmitForm(dialog.formData)}
          width="100%"
        >
          SIM
        </Button>
      </Dialog>
    </Box>
  )
}

export default EditCharge
