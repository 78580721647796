import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'

import { Icons } from '@clientbase/clientbase-library'

export const initialDialogSettings = {
  dialog: {
    isOpenDialog: false,
    title: '',
    description: '',
    action: () => null,
    uuid: ''
  },
  handleChangeDialog: () => null,
  handleCallAction: () => null,
  handleToggleDialog: () => null
}

export type Dialog = {
  isOpenDialog: boolean
  title: string
  description?: string
  action: (uuid?: string) => void
  icon?: Icons
  uuid?: string
}

type DialogContext = {
  dialog: Dialog
  handleChangeDialog: (props: Dialog) => void
  handleToggleDialog: () => void
  handleCallAction: () => void
}

const DialogContext = createContext<DialogContext>(initialDialogSettings)

export const DialogProvider = ({ children }: PropsWithChildren) => {
  const [dialog, setDialog] = useState<Dialog>(initialDialogSettings.dialog)

  const handleChangeDialog = useCallback(
    (props: Dialog) => setDialog(props),
    [dialog]
  )

  const handleToggleDialog = useCallback(() => {
    setDialog((s) => ({ ...s, isOpenDialog: !s.isOpenDialog }))
  }, [dialog, setDialog])

  const handleCallAction = useCallback(() => {
    try {
      dialog.action()
    } finally {
      handleToggleDialog()
    }
  }, [dialog])

  const value = useMemo(
    () => ({
      dialog,
      handleChangeDialog,
      handleToggleDialog,
      handleCallAction
    }),
    [dialog, handleChangeDialog, handleToggleDialog, handleCallAction]
  )

  return (
    <DialogContext.Provider value={value}>{children}</DialogContext.Provider>
  )
}

const useDialog = () => {
  const context = useContext(DialogContext)
  if (!context) {
    throw new Error('⚠️ useDialog should be used within a DialogProvider')
  }

  return context
}

export default useDialog
