import React from 'react'

import { Icon, Icons } from '@clientbase/clientbase-library'
import { Box, Typography } from '@mui/material'
import { InvoiceParams } from 'models/Invoice'
import moment from 'moment'
import { rgba } from 'polished'

import { formatMoney } from 'utils'
import { formatDate } from 'utils/FormatDate'

import { theme } from 'styles/theme'

import { ContainerIcon } from './ModalChargeDetail.styles'

export const getStatus = (status: string, dueDate?: string) => {
  const today = moment().startOf('day').format('YYYY-MM-DD')
  switch (status) {
    case 'cancelled':
      return {
        color: theme.palette.error[400],
        label: 'Cancelada'
      }
    case 'cancelling':
      return {
        color: rgba(theme.palette.error[300], 0.3),
        fontColor: theme.palette.error[300],
        label: 'Cancelada'
      }
    case 'created_payment':
      return {
        label: 'Pendente',
        color: theme.palette.neutral[700],
        fontColor: '#777877',
        disabled: true
      }
    case 'no_payment':
      return {
        color: theme.palette.error[400],
        label: 'Expirada'
      }
    case 'expiring':
      return {
        color: theme.palette.error[400],
        label: 'Expirada'
      }
    case 'open_payment':
      if (dueDate && moment(dueDate).isBefore(today)) {
        return {
          color: rgba(theme.palette.alert[400], 0.3),
          fontColor: theme.palette.alert[300],
          label: 'Atrasada'
        }
      }
      return {
        color: theme.palette.secondary[600],
        fontColor: theme.palette.secondary[200],
        label: 'Aberta'
      }
    case 'paid':
      return {
        color: theme.palette.primary[500],
        fontColor: theme.palette.primary[200],
        label: 'Paga'
      }
    case 'pending':
      return {
        color: rgba(theme.palette.neutral[700], 0.3),
        fontColor: theme.palette.neutral[400],
        label: 'Pendente'
      }
    default:
      window.newrelic.noticeError(
        new Error(
          `Erro ao mapear status do modal de detalhes da cobrança: ${status}`
        )
      )
      return {
        color: rgba(theme.palette.neutral[700], 0.3),
        fontColor: theme.palette.neutral[400],
        label: 'Status desconhecido'
      }
  }
}

export const getHoverText = (status: string, dueDate?: string) => {
  const today = moment().startOf('day').format('YYYY-MM-DD')
  switch (status) {
    case 'created_payment':
      return {
        text: 'A cobrança está agendada para emissão'
      }
    case 'pending':
      return {
        text: 'A cobrança está agendada para emissão'
      }
    case 'open_payment':
      if (dueDate && moment(dueDate).isBefore(today)) {
        return {
          text: 'A cobrança está em aberto e a data de vencimento já passou'
        }
      }
      return {
        text: 'A cobrança está em aberto e aguarda pagamento'
      }
    case 'paid':
      return {
        text: 'A cobrança foi paga'
      }
    case 'expiring':
      return {
        text: 'A cobrança não foi paga dentro do prazo máximo'
      }
    case 'cancelling':
      return {
        text: 'A cobrança foi cancelada'
      }
    case 'cancelled':
      return {
        text: 'A cobrança foi cancelada'
      }
    default:
      window.newrelic.noticeError(
        new Error(`Erro ao mapear o texto da tooltip de cobranças: ${status}`)
      )
      return {
        text: 'Status desconhecido'
      }
  }
}

export const ChargeEnum = {
  boleto: 'Boleto',
  cash: 'Dinheiro',
  credit_card: 'Cartão de crédito',
  other: 'Outros',
  pix: 'Pix',
  transfer: 'Transferência (TED/DOC)',
  direct_payment: 'Recebimento manual',
  credit_card_recurring: 'Cartão Recorrente',
  credit_card_installments: 'Cartão Parcelado'
}

export const DiscountPolicyEnum = {
  fixed: 'Sim',
  no_discount: 'Não',
  percentage: 'Sim',
  tiers: 'Sim'
}

export function sendToWhatsApp(data: InvoiceParams) {
  const { customer, amountBilled, dueDate } = data
  window.open(
    `https://api.whatsapp.com/send?text=Oi,%20${
      customer.name || ''.trim().replace(' ', '%20')
    }!%20Segue%20sua%20fatura%20no%20valor%20de%20${formatMoney(
      amountBilled
    )}%20e%20vencimento%20${formatDate(
      dueDate
    )}.%20https://fatura.clientbase.com.br/${data?.uuid}&mark=true`,
    '_blank'
  )
}

export function openInvoice(uuid: string) {
  window.open(`https://fatura.clientbase.com.br/${uuid}`, '_blank')
}

export function renderTitle(label: string, icon: Icons, iconColor?: string) {
  return (
    <Box alignItems="center" display="flex" gap="8px">
      <ContainerIcon>
        <Icon icon={icon} colorSX={iconColor} />
      </ContainerIcon>
      <Box>
        <Typography variant="mdLight">{label}</Typography>
      </Box>
    </Box>
  )
}

export function cardTittle(
  label: string,
  icon: Icons | JSX.Element,
  iconColor?: string
) {
  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" alignItems="center" gap="8px">
        <ContainerIcon>
          {typeof icon === 'object' ? (
            React.cloneElement(icon, { color: iconColor })
          ) : (
            <Icon icon={icon} colorSX={iconColor} />
          )}
        </ContainerIcon>
        <Typography variant="mdLight">{label}</Typography>
      </Box>
    </Box>
  )
}
