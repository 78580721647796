export interface MessageTrackingParams {
  campaign: string
  campaignMedium: string
  status: string
  dateSent: string
  uuid: string
}

export function constructorMessageTracking(data: any) {
  const message_trackings: MessageTrackingParams[] = data.map(
    (message_tracking: any) => {
      return {
        campaign: message_tracking?.campaign,
        campaignMedium: message_tracking?.medium,
        status: message_tracking?.status,
        dateSent: message_tracking?.date_sent,
        uuid: message_tracking?.uuid
      }
    }
  )

  return message_trackings
}
