/* eslint-disable sort-keys */
import React, { useEffect, useState } from 'react'

import { Icon, Modal } from '@clientbase/clientbase-library'
import { IconButton, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { DataService } from 'api/DataService'

import { DynamicTableSkeleton } from 'components/DynamicTable/skeleton'
import EmptyReport from 'components/Empty/EmptyReport/EmptyReport'
import { Table } from 'components/Table'

import * as S from './ModalReport.styles'
import { convertToRows } from './ModalReport.utils'

export type ReportResponse = {
  created_at: Date
  downloaded: boolean
  filters: string
  filter_by: string
  merchant_uuid: string
  model:
    | 'Billing'
    | 'Receivable'
    | 'Customer'
    | 'Transfer'
    | 'Contract'
    | 'Nfse'
  status: number
  uuid: string
}

export type ModalReportProps = {
  isOpenModalReport: boolean
  handleCloseReportModal: () => void
}
export const ModalReport = ({
  isOpenModalReport,
  handleCloseReportModal
}: ModalReportProps) => {
  const [loading, setLoading] = useState(true)
  const [reports, setReports] = useState<ReportResponse[]>([])
  const [tableProps, setTableProps] = useState({
    page: 0,
    rowsPerPage: 25,
    totalCount: 0
  })

  useEffect(() => {
    const getUserReports = async () => {
      setLoading(true)
      const resp = await DataService({
        type: 'GET',
        url: `/v1/reports?per=${tableProps.rowsPerPage}&page=${
          tableProps.page + 1
        }`
      })
      const data: ReportResponse[] = (await resp.data?.records) || []
      if (data.length > 0) {
        const reportsMapped = data.map((el) => ({
          ...el,
          ...JSON.parse(el.filters)
        }))
        setReports(reportsMapped)

        setTableProps((prevState) => ({
          ...prevState,
          totalCount: Number(resp.data.meta.total_count)
        }))
      }
      setLoading(false)
    }

    getUserReports()
  }, [tableProps.page, tableProps.rowsPerPage, isOpenModalReport])
  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    setTableProps((s) => ({ ...s, page }))
  }

  const handleChangeRowsPerPage = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const rowsPerPage = Number(e.target.value || 25)
    setTableProps((s) => ({ ...s, rowsPerPage }))
  }

  return (
    <Modal
      aria-hidden={!isOpenModalReport}
      handleClose={handleCloseReportModal}
      open={isOpenModalReport}
      width="100%"
      maxWidth="85vw"
      maxHeight="95vh"
      height="100%"
    >
      <S.Header>
        <S.Box>
          <S.Heading variant="lgLight">
            <Icon icon="event" />
            Relatórios
          </S.Heading>
          <Typography variant="mdBold">
            Histórico de relatórios exportados
            <Tooltip
              enterTouchDelay={0}
              title="Os relatórios são deletados automaticamente depois de 7 dias."
            >
              <IconButton>
                <Icon height="20px" icon="info" width="20px" />
              </IconButton>
            </Tooltip>
          </Typography>
        </S.Box>
      </S.Header>

      <S.TableWrapper>
        {loading ? (
          <DynamicTableSkeleton />
        ) : (
          <>
            {reports.length > 0 ? (
              <Table
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                header={[
                  { id: 'type', label: 'Tipo' },
                  { id: 'due_date', label: 'Data/hora solicitação' },
                  //              { id: "filters", label: "Filtros aplicados" },
                  { id: 'status', label: 'Status' },
                  { id: 'actions', label: 'Download' }
                ]}
                rows={convertToRows(reports)}
                select={{ useSelect: false }}
                {...tableProps}
              />
            ) : (
              <Box pt={5}>
                <EmptyReport />
              </Box>
            )}
          </>
        )}
      </S.TableWrapper>
    </Modal>
  )
}

export default ModalReport
