import { AddressesParams, constructorAddresses } from 'models/Addresses'

export interface MerchantParams {
  address: AddressesParams
  birthdate: string
  createdAt: string
  document: string
  legalName: string
  legalType: string
  name: string
  status: string
  updatedAt: string
  uuid: string
  vertical: string
}

export function constructorMerchant(data: any) {
  return {
    address: constructorAddresses(data?.address),
    birthdate: data?.birthdate,
    createdAt: data?.created_at,
    document: data?.document,
    legalName: data?.legal_name,
    legalType: data?.legal_type,
    name: data?.name,
    status: data?.status,
    updatedAt: data?.updated_at,
    uuid: data?.uuid,
    vertical: data?.vertical
  } as MerchantParams
}
