export interface NotesParams {
  annotableType: string
  comment: string
  createdAt: string
  merchantUuid: string
  updatedAt: string
  uuid: string
}

export function constructorNotes(data: any) {
  const notes: NotesParams[] = data.map((note: any) => {
    return {
      annotableType: note?.annotable_type,
      comment: note?.comment,
      createdAt: note?.createdAt,
      merchantUuid: note?.merchant_uuid,
      updatedAt: note?.updated_at,
      uuid: note?.uuid
    }
  })

  return notes
}
