import {
  Box,
  tableCellClasses,
  TableCell as TableCellMui,
  Table as TableMui,
  TableRow as TableRowMui
} from '@mui/material'
import { styled } from '@mui/material'

export const Table = styled(TableMui)(() => ({
  '.MuiTableCell-root': {
    border: 0
  },
  boxShadow: '0',
  overflowX: 'auto',
  overflowY: 'auto',
  tbody: {
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px'
  }
}))

export const TableCell = styled(TableCellMui)(({ theme }) => ({
  cursor: 'pointer',

  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.neutral['500'],
    backgroundColor: '#F9FBFC',
    fontWeight: '600',
    padding: '8px 10px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px'
  },
  [`&.${tableCellClasses.body}`]: {
    border: 0,
    boxShadow: 0,
    fontSize: 14,
    padding: '8px 10px'
  }
}))

export const TableRow = styled(TableRowMui)(({ theme }) => ({
  '&:hover': {
    background: theme.palette.neutral[800]
  },
  '& td, & th': {
    borderBottom: '1px solid #E3E6E3 !important',
    [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
      minWidth: 200
    }
  }
}))

export const TableNameWrapper = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: 11,
  [`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: 1
  }
}))

export const TableNickname = styled('span')(({ theme }) => ({
  color: theme.palette.neutral[400],
  fontSize: 12
}))

export const WrapperPagination = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'end',
  marginRight: '12px',
  width: '100%',

  '& .MuiTablePagination-root': {
    width: '100%'
  }
}))
