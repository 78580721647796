import React, { useContext } from 'react'
import { MdLink } from 'react-icons/md'
import { toast } from 'react-toastify'

import { Button, Chip, Icon } from '@clientbase/clientbase-library'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tooltip,
  Typography
} from '@mui/material'
import { DataService } from 'api/DataService'
import { getChargeEnum } from 'templates/v2/Products/Page/Products.utils'

import { formatMoney } from 'utils'
import { formatDate } from 'utils/FormatDate'
import { toastProps } from 'utils/types/toast-props'

import { theme } from 'styles/theme'

import { ModalChargeDetailContext } from '../../context'
import { cardTittle } from '../../ModalChargeDetail.utils'
import { S } from './'
import { getStatus } from './PaymentDetails.utils'

const PaymentDetails: React.FC = () => {
  const { paids, invoice, updateDialog, updateReload, updateDialogReverse } =
    useContext(ModalChargeDetailContext)

  const handleOpenCancelManuallyReceiptDialog = (isDirectPayment: boolean) => {
    if (!isDirectPayment) {
      handleOpenReversePayment()
      return
    }

    updateDialog({
      isOpenDialog: true,
      action: () => handleCancelManualReceipt(),
      description: 'Confirma cancelar o recebimento manual?',
      title: 'Cancelar recebimento manual',
      icon: 'info'
    })
  }

  const handleOpenReversePayment = () => {
    const uuid = getCardPaymentUuid()
    updateDialogReverse({ isOpen: true, uuid: uuid })
  }

  const handleCancelManualReceipt = async () => {
    const uuid = getPaymentUuid()

    const id = toast.loading('Cancelando o recebimento manual...')

    const response = await DataService({
      url: `/v1/payments/${uuid}/direct_payment`,
      type: 'DELETE'
    })

    response.status < 400 && updateReload((prevState) => !prevState)
    response.status < 400 && handleCloseCancelManuallyReceiptDialog()
    const errorMessage =
      response.error && (response.detail_error || response.message)

    toast.update(id, {
      render: response.error ? errorMessage : response.data.message,
      type: response.error ? 'error' : 'success',
      ...toastProps
    })

    // !!setReloadTable && setReloadTable((s) => !s)
  }

  const handleCloseCancelManuallyReceiptDialog = () => {
    updateDialog({
      isOpenDialog: false,
      action: () => ({}),
      description: '',
      title: '',
      icon: 'info'
    })
  }

  const getPaymentUuid = () => {
    const uuid =
      invoice?.payments.find(
        (payment) =>
          payment.status === 'paid' &&
          payment.paymentType === 'direct_payment' &&
          payment
      )?.uuid || ''

    return uuid
  }

  const getCardPaymentUuid = () => {
    const uuid =
      invoice?.payments.find((payment) => payment.status === 'paid' && payment)
        ?.uuid || ''

    return uuid
  }

  if (paids?.length === 0 || paids === null || paids === undefined) {
    return null
  }

  return (
    <Accordion
      sx={{
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        '&:before': {
          display: 'none'
        }
      }}
    >
      <AccordionSummary
        expandIcon={<Icon icon="keyboardArrowDown" />}
        aria-controls="paymentDetails-content"
        id="paymentDetails"
      >
        {cardTittle(
          'Detalhes do pagamento',
          'paid',
          `${theme.palette.primary[200]}`
        )}
      </AccordionSummary>
      {!!paids && (
        <AccordionDetails
          sx={{ maxWidth: '100%', borderTop: '1px solid #E3E6E3' }}
        >
          {paids.map((paid) => (
            <>
              <Box
                display="flex"
                gap={1.25}
                alignItems="end"
                margin={'10px 0px 10px 0px'}
              >
                <Typography variant="mdLight" color="#777777">
                  Pagamento:
                </Typography>
                <S.Separator />
                <Typography variant="mdBold" display="flex" alignItems="center">
                  {formatDate(paid?.datePaid) || ''}
                </Typography>
              </Box>
              <Box
                display="flex"
                gap={1.25}
                alignItems="end"
                margin={'10px 0px 10px 0px'}
              >
                <Typography variant="mdLight" color="#777777">
                  Valor pago
                </Typography>
                <S.Separator />
                <Typography variant="mdBold" display="flex" alignItems="center">
                  {formatMoney(Number(paid?.amountPaid))}
                </Typography>
              </Box>
              <Box
                display="flex"
                gap={1.25}
                alignItems="end"
                margin={'10px 0px 10px 0px'}
              >
                <Typography variant="mdLight" color="#777777">
                  Valor líquido
                </Typography>
                <S.Separator />
                <Typography variant="mdBold" display="flex" alignItems="center">
                  {formatMoney(Number(paid?.amountPaid) - Number(paid?.fee))}
                </Typography>
              </Box>
              <Box
                display="flex"
                gap={1.25}
                alignItems="end"
                margin={'10px 0px 10px 0px'}
              >
                <Typography variant="mdLight" color="#777777">
                  Taxas
                </Typography>
                <S.Separator />
                <Typography variant="mdBold" display="flex" alignItems="center">
                  {formatMoney(Number(paid?.fee))}
                </Typography>
              </Box>
              {paid.receivables.length > 0 &&
                paid.receivables.map(({ expected_on, status }) => (
                  <>
                    {expected_on ? (
                      <Box
                        display="flex"
                        gap={1.25}
                        alignItems="end"
                        margin="10px 0px 10px 0px"
                      >
                        <Typography variant="mdLight" color="#777777">
                          Data de liberação do saldo:
                        </Typography>
                        <S.Separator sx={{ marginTop: '4px' }} />
                        <Typography
                          variant="mdBold"
                          display="flex"
                          alignItems="center"
                        >
                          {formatDate(expected_on)}
                        </Typography>
                      </Box>
                    ) : null}

                    <Box
                      display="flex"
                      gap={1.25}
                      alignItems="end"
                      margin="10px 0px 10px 0px"
                    >
                      <Typography variant="mdLight" color="#777777">
                        Status:
                      </Typography>
                      <S.Separator sx={{ marginTop: '4px' }} />
                      <S.Status status={status}>
                        {getStatus(status).label}
                      </S.Status>
                    </Box>
                  </>
                ))}
              <Typography variant="mdLight" color="#777777">
                Forma de pagamento
              </Typography>
              <Box
                display="flex"
                gap={1.25}
                alignItems="end"
                margin={'10px 0px 10px 0px'}
              >
                <Typography variant="mdBold" display="flex" alignItems="center">
                  <Box alignItems="center" display="flex">
                    <Box
                      display="flex"
                      flexWrap={'wrap'}
                      alignItems={'center'}
                      gap={'8px'}
                    >
                      {paid?.paymentType === 'direct_payment' && (
                        <Box>
                          <Chip
                            sx={{
                              bgcolor: theme.palette.neutral[400]
                            }}
                            key={'Recebimento manual'}
                            label={'RECEBIMENTO MANUAL'}
                          />
                        </Box>
                      )}

                      {paid?.paymentType === 'credit_card_installments' && (
                        <Box>
                          <Chip
                            sx={{
                              bgcolor: theme.palette.neutral[400]
                            }}
                            key={`Cartão parcelado`}
                            label={
                              paid.paymentable?.installments &&
                              paid.paymentable?.installments > 1
                                ? `CARTÃO PARCELADO ${paid.paymentable?.installments}x`
                                : 'CARTÃO À VISTA'
                            }
                          />
                        </Box>
                      )}
                      {paid?.paymentType !== 'credit_card_installments' && (
                        <Chip
                          sx={{
                            bgcolor: theme.palette.neutral[400]
                          }}
                          key={
                            paid?.paymentType !== 'direct_payment'
                              ? getChargeEnum(paid?.paymentType)
                              : getChargeEnum(paid?.paymentable?.directType)
                          }
                          label={
                            paid?.paymentType !== 'direct_payment'
                              ? getChargeEnum(paid?.paymentType).toUpperCase()
                              : getChargeEnum(
                                  paid?.paymentable?.directType
                                ).toUpperCase()
                          }
                        />
                      )}
                    </Box>

                    {invoice?.billingDocuments.length > 0 && (
                      <Box ml="8px">
                        <Tooltip
                          arrow={true}
                          placement="top"
                          title="Visualizar comprovante de pagamento"
                        >
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <a
                              href={
                                invoice?.billingDocuments[0].docFileUrl || ''
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <MdLink
                                style={{ marginRight: 8 }}
                                size={24}
                                color={theme.palette.neutral[200]}
                              />
                            </a>
                          </Box>
                        </Tooltip>
                      </Box>
                    )}

                    {paid?.paymentType !== 'pix' &&
                      paid?.paymentType !== 'boleto' && (
                        <Button
                          variantButton="filters"
                          sx={{ gap: '8px' }}
                          icon="delete"
                          iconColor="#494949"
                          onClick={() =>
                            handleOpenCancelManuallyReceiptDialog(
                              paid?.paymentType === 'direct_payment'
                            )
                          }
                        >
                          {paid?.paymentType !== 'direct_payment'
                            ? 'Estornar'
                            : 'Excluir'}
                        </Button>
                      )}
                  </Box>
                </Typography>
              </Box>
            </>
          ))}
          {invoice?.status === 'paid' && invoice?.notes[0]?.comment && (
            <>
              <Typography variant="mdLight" color="#777777">
                Anotações:
              </Typography>
              <Box
                display="flex"
                gap={1.25}
                alignItems="end"
                margin={'10px 0px 10px 0px'}
              >
                <Typography variant="mdBold" display="flex" alignItems="center">
                  {invoice?.notes[0]?.comment}
                </Typography>
              </Box>
            </>
          )}
        </AccordionDetails>
      )}
    </Accordion>
  )
}

export default PaymentDetails
