import { ReactElement } from 'react'

import { useAuthContext, UserRole, UserStatus } from 'context/AuthContext'

type CanProps = {
  children: ReactElement
  roles: UserRole[] | UserRole
  status?: UserStatus[] | UserStatus
}

const Can = ({ children, roles, status }: CanProps) => {
  const { user } = useAuthContext()

  if (!user) return null

  const userRoles = Array.isArray(roles) ? roles : [roles]

  if (
    (userRoles.includes(user.role) && !status, userRoles.includes(user.role))
  ) {
    return children
  }

  const userStatus = Array.isArray(status) ? status : [status]

  if (userRoles.includes(user.role) && userStatus.includes(user.status)) {
    return children
  }

  return null
}

export default Can
