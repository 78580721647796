import { Box, styled } from '@mui/material'
import { rgba } from 'polished'

import { theme } from 'styles/theme'

export const getStyle = (status: string) => {
  switch (status) {
    case 'pending':
      return {
        label: 'Pendente',
        background: rgba(theme.palette.neutral[700], 0.3),
        color: theme.palette.neutral[400]
      }
    case 'issued':
      return {
        label: 'Processando',
        background: rgba(theme.palette.alert[300], 0.3),
        color: theme.palette.alert[300]
      }
    case 'confirmed':
      return {
        label: 'Emitida',
        background: rgba(theme.palette.primary[300], 0.3),
        color: theme.palette.primary[300]
      }
    case 'canceled':
      return {
        label: 'Cancelada',
        background: rgba(theme.palette.error[300], 0.3),
        color: theme.palette.error[300]
      }
    case 'failed':
      return {
        label: 'Falhado',
        background: rgba(theme.palette.error[300], 0.3),
        color: theme.palette.error[300]
      }
    default:
      window.newrelic.noticeError(
        new Error(`Erro ao mapear status da Invoice details: ${status}`)
      )
      return {
        label: 'Status desconhecido',
        background: rgba(theme.palette.neutral[700], 0.3),
        color: theme.palette.neutral[400]
      }
  }
}

export const ContainerIcon = styled('div')(() => ({
  width: '35px',
  height: '35px',
  background: '#F8F8F8',
  borderRadius: '8px',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '22px'
}))

export const WrapperHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}))

export const WrapperContent = styled('div')(() => ({
  display: 'flex',
  border: '1px solid #EDEBEB',
  borderRadius: '4px',
  padding: '15px',
  boxSizing: 'border-box'
}))

export const Separator = styled('div')(() => ({
  width: '1fr',
  flexGrow: '1',
  borderTop: '0.5px dashed #CBCBCB',
  alignSelf: 'center',
  marginTop: '2px'
}))

export const WrapperFooter = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
  justifyContent: 'end'
}))

type StatusProps = {
  status: string
}

export const Status = styled('div')<StatusProps>(({ status }) => ({
  width: 'fit-content',
  padding: '4px 8px',
  fontWeight: 600,
  borderRadius: 9999,
  ...getStyle(status)
}))
