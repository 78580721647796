export interface DiscountParams {
  amount: string
  billingUuid: string
  createdAt: string
  days: number
  policy: string
  recurrenceUuid: string
  updatedAt: string
  uuid: string
  discountableType: string
}

export function constructorDiscounts(data: any) {
  const discounts = data.map(
    (discount: any) =>
      ({
        amount: discount?.amount,
        billingUuid: discount?.billing_uuid,
        createdAt: discount?.created_at,
        days: discount?.days,
        policy: discount?.policy,
        recurrenceUuid: discount?.recurrence_uuid,
        updatedAt: discount?.updated_at,
        uuid: discount?.uuid,
        discountableType: discount?.discountable_type
      } as DiscountParams)
  )
  return discounts
}
