import { Box, Skeleton } from '@mui/material'

import { UpdateWrapper } from './styles'

const UpdateItemsSkeleton = () => (
  <>
    {[...new Array(4)].map((_, i) => (
      <UpdateWrapper key={i}>
        <Skeleton
          variant="rectangular"
          width={93}
          height={93}
          sx={{ borderRadius: 5 }}
        />

        <Box display="flex" flexDirection="column" gap={1}>
          <Skeleton variant="rectangular" width={200} height={20} />
          <Skeleton variant="rectangular" width={180} height={8} />
        </Box>

        <Skeleton variant="rectangular" width={20} height={20} />
      </UpdateWrapper>
    ))}
  </>
)

export default UpdateItemsSkeleton
