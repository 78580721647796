import React from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'

interface CustomProps extends NumericFormatProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumberFormatCustom = React.forwardRef<typeof NumericFormat, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props

    const MAX_LIMIT = 100.0

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          })
        }}
        thousandSeparator
        valueIsNumericString
        suffix="%"
        decimalScale={2}
        isAllowed={(values) => {
          const { floatValue } = values
          const float = floatValue || 0

          return float <= MAX_LIMIT
        }}
      />
    )
  }
)

export default NumberFormatCustom
