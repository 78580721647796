import { useState } from 'react'
import { toast } from 'react-toastify'

import { AlertColor } from '@mui/material'
import { DataService } from 'api/DataService'

import { BetweenDate } from 'components/DateRangePicker'

import { objectToQueryString } from 'utils'
import BetweenDateToQueryString from 'utils/between-date-to-query-string'
import { toastProps } from 'utils/types/toast-props'

export type Alert = {
  severity: AlertColor
  title?: string
  message: string
}

type ExportReportProps<T> = {
  filters?: T
  model:
    | 'Customer'
    | 'Billing'
    | 'Receivable'
    | 'Transfer'
    | 'billing_transfer'
    | 'Recurrence'
    | 'Nfse'
    | 'ProductLink'
  setIsOpenDialog?: (isOpenDialog: boolean) => void
  functionToCallAfterSubmitReport?: (message: string) => void
  functionToCallAfterSubmitTransfer?: (message: string) => void
  uuid?: string
  betweenDate?: BetweenDate
  filterByKey?: 'due' | 'transfer'
}
export const useExportReport = <T>({
  filters,
  model,
  setIsOpenDialog,
  uuid,
  filterByKey,
  betweenDate,
  functionToCallAfterSubmitReport,
  functionToCallAfterSubmitTransfer
}: ExportReportProps<T>) => {
  const [loading, setLoading] = useState(false)

  const exportReport = async (data: any) => {
    setLoading(true)
    const id = toast.loading('Solicitando relatório...')
    const response = await DataService({
      data,
      type: 'POST',
      url: '/v1/reports'
    })

    const errorMessage =
      response.error && (response.detail_error || response.message)

    toast.update(id, {
      render: response.error ? errorMessage : response.data.message,
      type: response.error ? 'error' : 'success',
      ...toastProps
    })

    !!setIsOpenDialog && setIsOpenDialog(false)
    !!functionToCallAfterSubmitReport &&
      functionToCallAfterSubmitReport(
        response.error ? errorMessage : response.data.message
      )
    setLoading(false)
  }
  const handleRequestWithdrawal = async () => {
    setLoading(true)
    const id = toast.loading('Solicitando transferência...')
    const response = await DataService({
      type: 'POST',
      url: '/v1/transfers'
    })

    const errorMessage =
      response.error && (response.detail_error || response.message)

    toast.update(id, {
      render: response.error ? errorMessage : response.data.message,
      type: response.error ? 'error' : 'success',
      ...toastProps
    })

    !!setIsOpenDialog && setIsOpenDialog(true)
    !!functionToCallAfterSubmitTransfer &&
      functionToCallAfterSubmitTransfer(
        response.error ? errorMessage : response.data.message
      )
    setLoading(false)
  }

  const handleExportReport = async () => {
    setLoading(true)
    let date = {}

    if (betweenDate && filterByKey) {
      date = BetweenDateToQueryString({ betweenDate, filterByKey })
    }

    if (!filters) {
      exportReport({ model })
    } else {
      const filter_by = objectToQueryString({
        filterBy: { ...filters, ...date },
        uuid
      })

      const data = { filter_by, model }

      setLoading(false)
      exportReport(data)
    }
  }

  return {
    handleExportReport,
    handleRequestWithdrawal,
    loading
  }
}
