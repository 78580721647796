import { Box, styled } from '@mui/material'

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gridTemplateColumns: '1fr 1fr',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '8px',
  marginBottom: 0,
  marginTop: '-50px',
  width: 'calc(100% - 60px)',
  [theme.breakpoints.down(450)]: {
    display: 'grid',
    width: '100%',
    marginTop: 0
  }
}))
