import React, { useContext, useEffect } from 'react'

import { Box } from '@mui/material'
import { InvoiceParams } from 'models/Invoice'

import Steps from 'components/Steps'

import ButtonStep from './ButtonStep/ButtonStep'
import Confirmation from './Confirmation'
import { FormNegativationContext } from './context'
import Footer from './Footer'
import * as S from './FormNegativation.styles'
import Activation from './Steps/Activation'
import Revision from './Steps/Revision'
import Selection from './Steps/Selection'
import { STEPS } from './utils'

interface FormNegativationProps {
  invoice?: InvoiceParams
}

const FormNegativation: React.FC<FormNegativationProps> = ({ invoice }) => {
  const { step, updateBillingSelected, updateInfosCustomer, updateStep } =
    useContext(FormNegativationContext)

  useEffect(() => {
    if (invoice) {
      updateBillingSelected(invoice)
      updateInfosCustomer(invoice?.customer)
      updateStep(1)
    }
  }, [invoice])

  return (
    <S.Wrapper>
      <Box width="100%" maxWidth="400px">
        <Steps steps={STEPS} activeStep={step} />
      </Box>
      <Selection />
      <Revision />
      <Activation />
      <ButtonStep />
      <Confirmation />
      <Footer />
    </S.Wrapper>
  )
}

export default FormNegativation
