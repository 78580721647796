declare global {
  interface Window {
    dataLayer: any[]
  }
}

type trackEventProps = {
  event: string
  [key: string]: string | number
}

const useTrackGTMEvents = () => {
  const trackEvent = ({ event, ...props }: trackEventProps) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event,
        ...props
      })
    } else {
      console.error('"window.dataLayer" is not defined')
    }
  }
  return trackEvent
}

export default useTrackGTMEvents
