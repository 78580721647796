import { CreditCardParams, constructorCreditCard } from 'models/CreditCard'

export interface RecurrenceCardParams {
  createdAt: string
  creditCard: CreditCardParams
  nextChargeDate: string
  recurrenceUuid: string
  status: string
  updatedAt: string
  uuid: string
}

export function constructorCreditCards(creditCards: any) {
  return creditCards.map(
    (creditCard: any) =>
      ({
        createdAt: creditCard?.created_at,
        creditCard: constructorCreditCard(creditCard?.credit_card),
        nextChargeDate: creditCard?.next_charge_date,
        recurrenceUuid: creditCard?.recurrence_uuid,
        status: creditCard?.status,
        updatedAt: creditCard?.updated_at,
        uuid: creditCard?.uuid
      } as RecurrenceCardParams)
  )
}
