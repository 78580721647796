import { styled } from '@mui/material'

export const Time = styled('span')(({ theme }) => ({
  '&:nth-of-type(1)': {
    color: theme.palette.neutral[300]
    // marginBottom: 9
  },
  '&:nth-of-type(2)': {
    color: theme.palette.neutral[100],
    fontWeight: 600
  },
  display: 'flex',
  flexDirection: 'column',
  fontSize: 12,
  textTransform: 'uppercase'
}))
