export interface AddressesParams {
  addressType?: string
  addressableType?: string
  city?: string
  complement?: string
  country?: string
  countryCode?: string
  createdAt?: string
  neighborhood?: string
  numberHome?: string
  postalCode?: string
  state?: string
  street?: string
  updatedAt?: string
  uuid?: string
}

export function constructorAddresses(data: any) {
  return {
    addressType: data?.address_type,
    addressableType: data?.addressable_type,
    city: data?.city,
    complement: data?.complement,
    country: data?.country,
    countryCode: data?.country_code,
    createdAt: data?.created_at,
    neighborhood: data?.neighborhood,
    numberHome: data?.number,
    postalCode: data?.postal_code,
    state: data?.state,
    street: data?.street,
    updatedAt: data?.updated_at,
    uuid: data?.uuid
  }
}
