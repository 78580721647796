import StepConnector, {
  stepConnectorClasses
} from '@mui/material/StepConnector'
import styled from '@mui/system/styled'

export const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#56BB4D'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#56BB4D',
      background: '#56BB4D',
      color: '#fff'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1
  }
}))

export const QontoStepIconRoot = styled('div')<{
  ownerState: { active?: boolean }
}>(({ ownerState }) => ({
  display: 'flex',
  alignItems: 'center',
  color: 'transparent',
  borderRadius: 1000,
  '& .QontoStepIcon-completedIcon': {
    color: '#fff',
    background: '#56BB4D',
    boxShadow: 'none',
    zIndex: 1,
    fontSize: 18,
    borderRadius: '50%'
  },
  '& .QontoStepIcon-circle': {
    boxShadow: `inset 0 0 0 3px ${ownerState.active ? '#56BB4D' : '#A3A6A2'}`,
    width: 18,
    height: 18,
    borderRadius: '50%',
    backgroundColor: 'currentColor'
  },
  ...(ownerState.active && {
    color: 'transparent'
  })
}))
