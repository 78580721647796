import * as React from 'react'
import { SVGProps } from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}
const PixIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#494949"
      d="M28.125 14.5c-.123-.523-.256-1.041-.564-1.495a4.655 4.655 0 0 0-.6-.736c-.923-.889-1.852-1.778-2.77-2.671a.615.615 0 0 0-.477-.188c-.461.01-.923.005-1.385.005-.738 0-1.37.252-1.887.755-1.272 1.22-2.539 2.445-3.806 3.664-.328.316-.697.553-1.164.623a1.86 1.86 0 0 1-1.657-.529c-1.267-1.215-2.534-2.424-3.785-3.649-.63-.622-1.385-.909-2.272-.904-.477 0-.96.005-1.436 0a.583.583 0 0 0-.457.173c-.933.909-1.872 1.807-2.805 2.706a3.822 3.822 0 0 0-1.134 2.089.996.996 0 0 1-.051.163v.987c.118.47.246.939.492 1.368a4.1 4.1 0 0 0 .713.904c.924.894 1.852 1.778 2.77 2.671a.598.598 0 0 0 .477.188c.605-.02 1.21.015 1.82-.03.729-.054 1.33-.345 1.837-.834l3.77-3.63c.846-.81 2.005-.814 2.851-.005 1.252 1.205 2.508 2.405 3.75 3.615.584.573 1.277.86 2.113.845.436-.01.872-.005 1.308 0a.506.506 0 0 0 .384-.154c.954-.923 1.913-1.837 2.862-2.76a3.766 3.766 0 0 0 1.047-1.97c.015-.074.036-.143.056-.213v-.987ZM7.409 11.026c.693-.144 1.18.153 1.647.622 1.056 1.066 2.154 2.094 3.241 3.126.18.167.17.271-.005.434-1.005.953-1.995 1.92-2.995 2.874-.99.943-.708.904-2.303.899h-.026c-.159.014-.277-.05-.385-.158-.795-.77-1.6-1.531-2.39-2.306a2.126 2.126 0 0 1-.113-2.943c.662-.746 1.442-1.383 2.129-2.104.338-.34.677-.592 1.2-.444Zm15.29.02c.261.034.548-.085.79.152.717.716 1.461 1.408 2.184 2.119 1.042 1.022 1.036 2.335-.005 3.358-.58.567-1.2 1.1-1.744 1.703-.41.45-.856.637-1.466.583-.37-.035-.677-.123-.94-.38A556.238 556.238 0 0 0 18 15.197c-.174-.163-.153-.257.006-.415 1.118-1.066 2.236-2.128 3.333-3.214.385-.375.79-.617 1.36-.524Z"
    />
    <path
      fill="#494949"
      d="M15.483 28.115c.497-.126.995-.247 1.442-.518.299-.181.577-.393.826-.64l4.605-4.659c.06-.06.179-.11.134-.216-.045-.09-.164-.055-.253-.05-1.17.045-2.119-.423-2.93-1.248-1.173-1.198-2.352-2.385-3.53-3.572-.448-.453-.831-.448-1.284.01-1.178 1.192-2.372 2.364-3.526 3.577-.865.906-1.9 1.298-3.123 1.278-.114 0-.288-.07-.338.05-.04.101.12.181.2.262 1.456 1.48 2.918 2.948 4.37 4.433.652.664 1.403 1.112 2.318 1.268.03.005.06.025.09.035.338-.01.666-.01 1-.01Zm-.373-1.655c-.656 0-1.149-.141-1.527-.509-.925-.9-1.825-1.831-2.735-2.747-.09-.09-.11-.15.02-.226.532-.302.975-.72 1.402-1.152.896-.906 1.796-1.812 2.686-2.728.154-.156.239-.16.393 0 .95.971 1.91 1.938 2.86 2.904.293.296.596.583.954.8.194.115.184.206.03.357-.85.85-1.686 1.706-2.536 2.556-.458.473-1.01.755-1.547.744ZM14.486 1.875c-.517.12-1.028.252-1.485.544a4.238 4.238 0 0 0-.78.62c-1.525 1.547-3.055 3.099-4.58 4.65-.055.056-.17.111-.135.192.04.1.164.05.244.05.969-.025 1.873.167 2.668.771.173.131.323.287.477.439 1.202 1.214 2.399 2.433 3.601 3.648.14.14.288.287.492.322.288.05.527-.035.74-.257 1.217-1.244 2.444-2.484 3.666-3.723.601-.61 1.312-1.018 2.166-1.134.244-.035.497-.02.745-.03.065-.005.145.02.184-.05.04-.076-.04-.122-.084-.162-1.6-1.622-3.19-3.25-4.8-4.857-.536-.53-1.196-.842-1.937-.973-.064-.01-.129-.035-.188-.05h-.994ZM10.76 6.903c0-.075.065-.115.11-.16.82-.832 1.63-1.674 2.459-2.49.963-.947 2.19-1.008 3.189-.096.949.872 1.828 1.824 2.737 2.741.114.116.055.182-.054.252-.388.257-.73.575-1.059.907-.933.953-1.872 1.895-2.801 2.852-.15.151-.234.141-.373-.005-.9-.927-1.808-1.844-2.717-2.761-.418-.423-.845-.826-1.361-1.124-.055-.025-.115-.05-.13-.116Z"
    />
  </svg>
)
export default PixIcon
