import React, { useContext } from 'react'
import { toast } from 'react-toastify'

import { Button } from '@clientbase/clientbase-library'
import { Box } from '@mui/material'
import { NegativationService } from 'services/NegativationService'

import { toastProps } from 'utils/types/toast-props'

import { FormNegativationContext } from '../context'
import { NegativationSteps, StepsEnum } from '../utils'

const ButtonStep: React.FC = () => {
  const {
    step,
    billingSelected,
    infosCustomer,
    billings,
    fromRef,
    acceptTerm,
    updateStep,
    updateConfirmationDialog
  } = useContext(FormNegativationContext)

  const backStep = () => {
    const objDiv = document.getElementById('modal') as HTMLElement
    objDiv.scrollTop = 0

    updateStep((prevState) => prevState - 1)
  }

  const nextStep = async () => {
    const objDiv = document.getElementById('modal') as HTMLElement

    if (step === StepsEnum[NegativationSteps.REVISION]) {
      objDiv.scrollTop = 0
      fromRef?.current?.requestSubmit()
    } else if (step === StepsEnum[NegativationSteps.ACTIVATION]) {
      if (acceptTerm) {
        const id = toast.loading('Criando negativação...')
        const response = await NegativationService.createNegativation({
          billing_uuid: billingSelected?.uuid,
          customer_uuid: infosCustomer?.uuid
        })

        if (!response?.error) {
          toast.update(id, {
            render: response?.data?.message,
            type: 'success',
            ...toastProps
          })
          updateConfirmationDialog({
            open: true,
            uuid: response?.data?.record?.uuid
          })
        } else {
          toast.update(id, {
            render: response?.message,
            type: 'error',
            ...toastProps
          })
        }
      } else {
        toast.error('Aceite os termos para prosseguir.')
      }
    } else {
      if (!billingSelected) {
        toast.info('Selecione uma cobrança antes de continuar.')
      } else {
        objDiv.scrollTop = 0
        updateStep((prevState) => prevState + 1)
      }
    }
  }

  const labelNext =
    step === StepsEnum[NegativationSteps.ACTIVATION]
      ? 'SOLICITAR NEGATIVAÇÃO'
      : 'PRÓXIMO'

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row' }}
      boxSizing="border-box"
      paddingY="16px"
      gap={{ xs: '8px', sm: '8px', md: '32px' }}
    >
      {step === StepsEnum[NegativationSteps.REVISION] && billings === null
        ? null
        : step !== StepsEnum[NegativationSteps.SELECT] && (
            <Button fullWidth variantButton="secondary" onClick={backStep}>
              VOLTAR
            </Button>
          )}

      <Button
        fullWidth
        variantButton="green"
        {...(step === StepsEnum[NegativationSteps.ACTIVATION] &&
          !acceptTerm && {
            sx: (theme: any) => ({
              background: theme.palette.neutral[500]
            })
          })}
        disabled={
          step === StepsEnum[NegativationSteps.ACTIVATION] && !acceptTerm
        }
        onClick={nextStep}
      >
        {labelNext}
      </Button>
    </Box>
  )
}

export default ButtonStep
