import * as yup from 'yup'

export const schemaAddressForm = yup.object({
  street: yup.string().required('Rua obrigatório.'),
  number: yup.string().required('Número obrigatório.'),
  complement: yup.string(),
  neighborhood: yup.string().required('Bairro obrigatório.'),
  city: yup.string().required('Cidade obrigatório.'),
  postal_code: yup.string().required('CEP obrigatório.')
})

export type AddressFormSchema = yup.InferType<typeof schemaAddressForm>
