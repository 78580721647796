import { styled } from '@mui/material'

export const Wrapper = styled('div')(() => ({
  marginTop: 15,
  marginBottom: 2
}))

export const ChargeSession = styled('div')(() => ({
  width: 'auto',
  marginTop: 12,
  padding: 12,
  border: '1px solid #EDEBEB',
  borderRadius: '5px'
}))

export const Separator = styled('div')(() => ({
  width: '1fr',
  flexGrow: '1',
  borderTop: '0.5px dashed #CBCBCB',
  alignSelf: 'center',
  marginTop: '2px'
}))

export const ButtonRecurrenceView = styled('button')(({ theme }) => ({
  all: 'inherit',
  borderRadius: 999,
  cursor: 'pointer',
  outline: '1px solid transparent',
  ['&:focus-within, &:hover']: {
    outlineColor: theme.palette.neutral[100]
  }
}))
