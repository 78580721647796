import React, { ForwardRefRenderFunction, forwardRef } from 'react'

import * as S from './Button.styles'

export type ButtonProps = {
  children: React.ReactNode
  variant?: 'outlined' | 'text' | 'contained' | 'gray' | 'green'
  fullWidth?: boolean
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const Button: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
  { variant = 'outlined', children, fullWidth, ...rest },
  ref
) => (
  <S.Wrapper variant={variant} fullWidth={fullWidth} {...rest} ref={ref}>
    <span>{children}</span>
  </S.Wrapper>
)

export default forwardRef(Button)
