import React, { useContext } from 'react'

import { Icon } from '@clientbase/clientbase-library'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from '@mui/material'
import moment from 'moment'

import { NegativateStatus } from 'utils/enums'

import { theme } from 'styles/theme'

import { ModalChargeDetailContext } from '../../context'
import { cardTittle } from '../../ModalChargeDetail.utils'
import { S } from './'

const NegativationDetail: React.FC = () => {
  const { invoice } = useContext(ModalChargeDetailContext)
  if (!invoice?.negativation) {
    return null
  }
  return (
    <Accordion
      sx={{
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        '&:before': {
          display: 'none'
        }
      }}
    >
      <AccordionSummary
        expandIcon={<Icon icon="keyboardArrowDown" />}
        aria-controls="payerDetails-content"
        id="payerDetails"
      >
        {cardTittle(
          'Detalhes da negativação',
          'thumbDownAlt',
          `${theme.palette.primary[200]}`
        )}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          borderTop: '1px solid #E3E6E3'
        }}
      >
        <div>
          <Box
            display="flex"
            gap={1.25}
            alignItems="end"
            margin={'10px 0px 10px 0px'}
          >
            <Typography variant="mdLight" color="#777777">
              Data negativação:
            </Typography>
            <S.Separator sx={{ marginTop: '4px' }} />
            <Typography variant="mdBold" display="flex" alignItems="center">
              {moment(invoice?.negativation?.created_at).format('DD/MM/yyyy')}
            </Typography>
          </Box>
          <Box
            display="flex"
            gap={1.25}
            alignItems="end"
            margin={'10px 0px 10px 0px'}
          >
            <Typography variant="mdLight" color="#777777">
              Etapa do processo:
            </Typography>
            <S.Separator sx={{ marginTop: '4px' }} />
            <Typography variant="mdBold" display="flex" alignItems="center">
              {NegativateStatus[invoice?.negativation?.status]}
            </Typography>
          </Box>
          {/* TODO: Verificar termo aditivo */}
          {/* <Box
            display="flex"
            gap={1.25}
            alignItems="end"
            margin={'10px 0px 10px 0px'}
          >
            <Typography variant="mdLight" color="#777777">
              Termo aditivo:
            </Typography>
            <S.Separator sx={{ marginTop: '4px' }} />
            <Typography
              variant="mdBold"
              display="flex"
              alignItems="center"
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              Visualizar termo
            </Typography>
          </Box> */}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default NegativationDetail
