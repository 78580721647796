import { styled } from '@mui/material'

export const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Content = styled('div')`
  background: #fff;
  border-radius: 4px;
  padding: 4px;
`
