import { BetweenDate } from 'components/DateRangePicker'

import { formatMomentDate } from 'utils/FormatDate'

type BetweenDateToQueryStringParams = {
  betweenDate: BetweenDate
  filterByKey: 'due' | 'transfer' | ''
}

const BetweenDateFormat = ({
  betweenDate,
  filterByKey
}: BetweenDateToQueryStringParams) => {
  const startDate = betweenDate.start
  const endDate = betweenDate.end

  const dateValue = `${formatMomentDate(startDate)}|${formatMomentDate(
    endDate
  )}`
  const date = {
    [`${filterByKey !== '' ? `${filterByKey}_` : ''}date_between`]: dateValue
  }

  return date
}

export default BetweenDateFormat
