import { styled } from '@mui/material'

export const Wrapper = styled('div')(() => ({
  width: '100%',

  ['& .form']: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '8px'
  }
}))
