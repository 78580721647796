import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { Button, Textfield } from '@clientbase/clientbase-library'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Typography } from '@mui/material'
import { DataService } from 'api/DataService'
import { useOnboardingContext } from 'context/OnboardingContext'
import { ActivateAccountFormsProps } from 'templates/ActivateAccount/ActivateAccount'

import AutoCompleteBank from 'components/AutoCompleteBank'
import InputController from 'components/InputController'

import { BankFormSchema, schemaBankForm } from './'

export const initialBankDataFormValue = {}

const BankDataForm = ({
  sucessSubmit,
  handleBackStep,
  onboarding
}: ActivateAccountFormsProps) => {
  const { handleChange, values, fetchOnboarding } = useOnboardingContext()

  const { handleSubmit, control } = useForm<BankFormSchema>({
    defaultValues: values['3'],
    resolver: yupResolver(schemaBankForm)
  })

  const onSubmit: SubmitHandler<BankFormSchema> = async (data) => {
    const { bank, ...rest } = data

    const response = await DataService({
      type: 'POST',
      data: {
        bank_account: {
          ...rest,
          bank_uuid: bank.uuid
        }
      },
      url: '/v1/bank_accounts'
    })

    const error = response?.status >= 400 ? true : false

    if (!error) {
      await DataService({
        type: 'PATCH',
        url: '/v1/onboarding/bank_account_saved'
      })

      handleChange(3, data)
    }

    return sucessSubmit({ error, message: response?.message })
  }

  useEffect(() => {
    if (!onboarding) {
      fetchOnboarding()
    }
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Typography variant="mdBold">
          Informe os dados da sua conta bancária
        </Typography>
        <ul>
          <li>
            <Typography variant="smLight">
              CPF deve receber em uma conta bancária PF, da mesma titularidade.
            </Typography>
          </li>
          <li>
            <Typography variant="smLight">
              CNPJ deve receber em uma conta bancária PJ, da mesma titularidade.
            </Typography>
          </li>
        </ul>
      </div>

      <div>
        <Textfield
          value={values[1].legal_name}
          label="Nome do titular"
          disabled
        />
        <Textfield value={values[1].document} label="CPF/CNPJ" disabled />
        <AutoCompleteBank control={control} name="bank" />
        <InputController control={control} name="agency">
          <Textfield label="Número da agência bancária:" />
        </InputController>
        <InputController control={control} name="account">
          <Textfield label="Número da conta:" />
        </InputController>
        <InputController control={control} name="account_dv">
          <Textfield label="Digito verificador da conta:" />
        </InputController>
      </div>

      <Box mt={2} display="flex" gap={1.5}>
        <Button
          fullWidth
          variantButton="secondary"
          type="button"
          onClick={handleBackStep}
        >
          {'<-'} VOLTAR
        </Button>
        <Button fullWidth variantButton="green" type="submit">
          CONTINUAR
        </Button>
      </Box>
    </form>
  )
}

export default BankDataForm
