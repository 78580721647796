import { useState } from 'react'

import { Icon } from '@clientbase/clientbase-library'
import { Box, useMediaQuery } from '@mui/material'
import Image from 'next/image'

import ModalImage from 'components/ModalImage'

import {
  ItemContent,
  ImageWrapper,
  ItemHeading,
  Link,
  UpdateWrapper,
  ItemDescription,
  CustomChip
} from './styles'
import { IsExpandedProps } from './UpdatesRoot'

export type UpdateRecords = {
  uuid: string
  title: string
  description: string
  tags: string
  link: string
  created_at: string
  updated_at: string
  image_url: string
}

type UpdateItemProps = {
  isExpanded: { [key: string]: IsExpandedProps }
  expandText: (uuid: string) => void
  notViewed: boolean
  item: UpdateRecords
}

const UpdateItem = (item: UpdateItemProps) => {
  const [image, setImage] = useState('')
  const linkProps = item?.item.link && {
    href: item.item.link,
    target: '_blank'
  }
  const isMobile = useMediaQuery('(max-width:800px)')
  const renderIcon = () => {
    return (
      <Box>
        <Link {...linkProps}>
          <Box
            display="flex"
            bgcolor="#fff"
            justifyContent="center"
            alignItems="center"
            height="24px"
            width="24px"
            borderRadius="6px"
          >
            <Icon
              icon="arrowForward"
              colorSX="#757675"
              width="20px"
              height="20px"
            />
          </Box>
        </Link>
      </Box>
    )
  }

  return (
    <Box>
      <UpdateWrapper hasLink={!!item.item.link} notViewed={item.notViewed}>
        <ImageWrapper onClick={() => setImage(item.item.image_url)}>
          <Image src={item.item.image_url} alt={item.item.title} fill />
        </ImageWrapper>
        {isMobile && item.item.link && renderIcon()}
        <ItemContent>
          <Box>
            <Link {...linkProps}>
              <ItemHeading>{item.item.title}</ItemHeading>
              <ItemDescription
                isExpanded={item.isExpanded[item.item.uuid]?.isExpandedText}
              >
                {item.item.description}
              </ItemDescription>
            </Link>
            <CustomChip
              notViewed={item.notViewed}
              onClick={() => {
                item.expandText(item.item.uuid)
              }}
              label={
                item.isExpanded[item.item.uuid]?.isExpandedText
                  ? 'Ver menos'
                  : 'Ver mais'
              }
            />
          </Box>
          {!isMobile && item.item.link && renderIcon()}
        </ItemContent>
        <ModalImage image={image} setImage={setImage} />
      </UpdateWrapper>
    </Box>
  )
}

export default UpdateItem
