import React, { useContext, useEffect } from 'react'

import { Autocomplete, Textfield } from '@clientbase/clientbase-library'
import { Box, Typography } from '@mui/material'
import { CustomerService } from 'services/CustomerService'

import { FormNegativationContext } from '../../context'
import { S } from './'
import Table from './Table'

const Selection: React.FC = () => {
  const {
    step,
    customers,
    selectedCustomer,
    updateCustomers,
    updateSelectedCustomer,
    updateBillings
  } = useContext(FormNegativationContext)

  const getCustomers = async () => {
    updateCustomers(await CustomerService.getCustomerAutocomplete())
  }

  useEffect(() => {
    if (customers.length === 0) getCustomers()
  }, [])

  if (step !== 0) {
    return null
  }

  return (
    <>
      <Typography maxWidth="830px" textAlign="center">
        Nos próximos passos, vamos orientá-lo sobre como realizar uma
        negativação por meio do <b>Boa Vista</b>. O custo desse serviço é de{' '}
        <b>R$19,90</b> e seu cliente será notificado com uma fatura atualizada
        para pagamento da dívida. Caso o pagamento não seja efetuado, o CPF/CNPJ
        do cliente será negativado no Boa Vista.
      </Typography>

      <S.Section>
        <S.Header>Seleção de cobrança</S.Header>
        <S.Content>
          <Box padding="30px 10px" boxSizing="border-box">
            <Autocomplete
              options={customers}
              value={selectedCustomer}
              onChange={(_, option) => {
                updateBillings(null)
                updateSelectedCustomer(option)
              }}
              renderOption={(props, option) => {
                const optionTyped = option as {
                  label: string
                  value: string
                }
                return (
                  <Box component="li" {...props} key={optionTyped.value}>
                    {optionTyped.label}
                  </Box>
                )
              }}
              autoHighlight
              renderInput={(params) => (
                <Textfield
                  placeholder="Procurar por nome do cliente"
                  {...params}
                />
              )}
            />
          </Box>
          <Table />
        </S.Content>
      </S.Section>
    </>
  )
}

export default Selection
