import moment from 'moment'

type HistoricItem = {
  event: string
  description: string
  date_change: string
  user: string
}

export type Historic = HistoricItem[]

const historicChangesMapper = (historic: Historic) => {
  return historic.map((item) => ({
    event: item?.event,
    description: item?.description,
    date_change: moment(item?.date_change).format('DD/MM/YYYY HH:mm:ss'),
    user: item.user
  }))
}

export default historicChangesMapper
