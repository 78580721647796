import { useRef } from 'react'

function Debouncer(fc: (text: string) => void, time: number) {
  const timeoutRef = useRef<number | null>(null)

  const awaitForSubmit = (text: string) => {
    // eslint-disable-next-line
    window.clearTimeout(timeoutRef.current!)

    timeoutRef.current = window.setTimeout(() => {
      fc(text)
    }, time)
  }

  return awaitForSubmit
}

export default Debouncer
