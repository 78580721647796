import React from 'react'

import { styled, Tabs as TabsMui, TabsProps } from '@mui/material'
import Link from 'next/link'

import Tab from 'components/Tab/Tab'

import getCurrentMonth from 'utils/get-current-month'
import { ChargeTabs } from 'utils/tabs'
import { ObjectLabelValue } from 'utils/types/object-label-value'

interface TabsExtendsProps extends TabsProps {
  value: number | string
  tabs: Array<string> | Array<ObjectLabelValue>
  onChange: (event: React.SyntheticEvent, newValue: number | string) => void
  fontSize?: number
}

const Tabs = styled(
  ({ tabs, fontSize = 15, onChange, ...props }: TabsExtendsProps) => (
    <TabsMui
      {...props}
      onChange={onChange}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />
      }}
      variant="scrollable"
      scrollButtons="auto"
      allowScrollButtonsMobile
    >
      {tabs.map((tab, i) => {
        const tabValue = typeof tab === 'string' ? i : tab.value

        const newQuery = {
          tab: tabValue.toString(),
          ...(tab === ChargeTabs.CHARGES && {
            due_date_between: getCurrentMonth().join('|')
          }),
          ...(tab === ChargeTabs.TRANSFERS && {
            transfer_date_between: getCurrentMonth().join('|')
          })
        }

        const href = `${window.location.pathname}?${new URLSearchParams(
          newQuery
        ).toString()}`

        return (
          <Tab
            fontSize={fontSize}
            label={
              <Link
                href={href}
                style={{ textDecoration: 'none', color: 'inherit' }}
                onClick={(e) => {
                  e.preventDefault()
                  onChange(e, tabValue)
                }}
              >
                {typeof tab === 'string' ? tab : tab.label}
              </Link>
            }
            value={tabValue}
            key={i}
          />
        )
      })}
    </TabsMui>
  )
)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.main,
    bottom: '8px',
    display: 'flex',
    justifyContent: 'center'
  },
  '& .MuiTabs-scrollButtons.Mui-disabled ': {
    opacity: '0.3'
  },
  '& .MuiTabs-scrollButtons': {
    width: '30px'
  }
}))

export default Tabs
