const formatCents = (amount: string | undefined) => {
  if (!amount) return '0'
  return String(amount).split('.')[1].length === 1 ? amount + '0' : amount
}

export const formatCentsToSendToBackEnd = (amount: string | undefined) => {
  if (!amount) return '0'
  return (
    amount.substring(0, amount.length - 2) +
    '.' +
    amount.substring(amount.length - 2, amount.length)
  )
}

export default formatCents
