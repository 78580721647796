type Customer = {
  uuid: string
}

export type Group = {
  id: string
  name: string
  groupColor?: string
  icon?: string
  customers: string[]
  actived: boolean
}

export type GroupParams = {
  uuid?: string
  name?: string
  group_color?: string
  icon?: string
  customers?: Customer[]
  actived: boolean
}

export const constructorGroup = (group: GroupParams) =>
  ({
    id: group.uuid,
    name: group.name,
    groupColor: group.group_color,
    icon: group?.icon,
    customers: group?.customers?.length
      ? group.customers.map((c) => c.uuid)
      : [''],
    actived: false
  } as const)
