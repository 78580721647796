import React, { useContext } from 'react'

import { Icon } from '@clientbase/clientbase-library'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tab,
  Tabs,
  Typography
} from '@mui/material'
import notificationDetailMapper, {
  MessageTracking
} from 'models/Charge/NotificationDetail'

import { Table } from 'components/Table'

import { theme } from 'styles/theme'

import { ModalChargeDetailContext } from '../../context'
import { cardTittle, getStatus } from '../../ModalChargeDetail.utils'
import { S } from './'

const NotificationDetails: React.FC = () => {
  const { invoice, tab, updateTab } = useContext(ModalChargeDetailContext)

  const handleChangeTab = (tab: number) => {
    updateTab(tab)
  }

  if (getStatus(invoice?.status)?.label === 'Pendente') {
    return null
  }

  return (
    <Accordion
      sx={{
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        '&:before': {
          display: 'none'
        }
      }}
    >
      <AccordionSummary
        expandIcon={<Icon icon="keyboardArrowDown" />}
        aria-controls="notificationDetails-content"
        id="notificationDetails"
      >
        {cardTittle(
          'Detalhes das notificações',
          'notificationImportant',
          `${theme.palette.primary[200]}`
        )}
      </AccordionSummary>
      <AccordionDetails sx={{ borderTop: '1px solid #E3E6E3' }}>
        <Box
          display="flex"
          flexWrap="wrap"
          sx={{
            border: '1px solid #E3E5E3',
            borderRadius: '5px'
          }}
        >
          <Tabs
            sx={{ width: '100%' }}
            value={tab}
            onChange={(_, s) => handleChangeTab(s)}
            textColor="inherit"
            indicatorColor="primary"
            aria-label="tabs"
          >
            <Tab sx={{ minWidth: '50%' }} value={0} label="Pré-vencimento" />
            <S.Divider />
            <Tab sx={{ minWidth: '50%' }} value={1} label="Pós-vencimento" />
          </Tabs>
        </Box>
        {notificationDetailMapper(
          invoice?.messageTracking as MessageTracking,
          tab
        ).length ? (
          <div>
            {invoice?.messageTracking && (
              <Table
                header={[
                  { id: 'data', label: 'Data' },
                  { id: 'campaingMedium', label: 'Meio' },
                  { id: 'type', label: 'Tipo' },
                  { id: 'status', label: 'Status' }
                ]}
                rows={notificationDetailMapper(
                  invoice?.messageTracking as MessageTracking,
                  tab
                )}
                select={{ useSelect: false }}
                hiddePagination
              />
            )}
          </div>
        ) : (
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            alignContent="center"
          >
            <Typography marginTop="10px">
              Nenhuma notificação de{' '}
              {tab === 0 ? 'pré-vencimento' : 'pós-vencimento'} encontrada.
            </Typography>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default NotificationDetails
