import { DataService } from 'api/DataService'

import { ObjectLabelValue } from 'utils/types/object-label-value'

export class CustomerService {
  public static async getCustomerAutocomplete(): Promise<
    Array<ObjectLabelValue>
  > {
    const response = await DataService({
      type: 'GET',
      url: '/v1/customers_autocomplete'
    })

    if (response.status === 200) {
      return response.data.records.map(
        (record: { name: string; uuid: string }) =>
          ({
            label: record.name,
            value: record.uuid
          } as ObjectLabelValue)
      )
    }

    return []
  }
  public static async getCustomerGroupsAutocomplete(): Promise<
    Array<ObjectLabelValue>
  > {
    const response = await DataService({
      url: '/v1/customer_groups/autocomplete',
      type: 'GET'
    })

    if (response.status === 200) {
      return response.data.records.map(
        (record: { name: string; uuid: string }) =>
          ({
            label: record.name,
            value: record.uuid
          } as ObjectLabelValue)
      )
    }

    return []
  }
  public static async getCustomerDetail(uuid: string): Promise<any> {
    const response = await DataService({
      url: `/v1/customers/${uuid}`,
      type: 'GET'
    })

    if (response.status === 200) {
      return response?.data?.record
    }

    return {}
  }

  public static async updateCustomer(uuid: string, data: any): Promise<any> {
    const response = await DataService({
      url: `/v1/customers/${uuid}`,
      type: 'PATCH',
      data
    })

    if (response.status === 200) {
      return response?.data
    }

    return {}
  }
}
