import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import { DataService } from 'api/DataService'
import { parseCookies, setCookie } from 'nookies'

import {
  AddressFormSchema,
  initialAddressFormSchemaValue
} from 'components/ActivateAccountForms/Address'
import {
  BankFormSchema,
  initialBankDataFormValue
} from 'components/ActivateAccountForms/BankData'
import {
  InformationFormSchema,
  initialInformationFormSchemaValue
} from 'components/ActivateAccountForms/Information'

import { onlyNumber } from 'utils'

type FormTypes = InformationFormSchema | AddressFormSchema | BankFormSchema
type Status =
  | 'enabled'
  | 'trial'
  | 'checking'
  | 'blocked'
  | 'suspended'
  | 'rejected'
  | 'disabled'
  | 'demo'

export type OnboardingContextProps = {
  step: number
  fetching: boolean
  setStep: React.Dispatch<React.SetStateAction<number>>
  handleChange: (step: number, value: FormTypes) => void
  status: Status
  fetchOnboarding: () => void
  values: {
    1: InformationFormSchema
    2: AddressFormSchema
    3: BankFormSchema
  }
}

const initialOnboardingValues = {
  values: {
    1: initialInformationFormSchemaValue,
    2: initialAddressFormSchemaValue,
    3: initialBankDataFormValue
  }
} as OnboardingContextProps

const stepsMapper = {
  infos: 1,
  informations: 1,
  address: 2,
  bank_account: 3,
  document_document_with_photo: 4,
  document_residency_proof: 4,
  issued_billing: 4,
  activation_requested: 5,
  finished: 5
}

const maxAge = 1000 * 60 * 60 * 24 * 6 // 6 dias

const OnboardingFormContext = createContext<OnboardingContextProps>(
  initialOnboardingValues
)

type OnboardingProviderProps = {
  children: React.ReactNode
}

export const OnboardingProvider = ({ children }: OnboardingProviderProps) => {
  const [step, setStep] = useState(1)
  const [fetching, setFetching] = useState(true)
  const [values, setValues] = useState(initialOnboardingValues.values)
  const [status, setStatus] = useState('' as Status)

  const hasWindow = typeof window !== 'undefined'

  const handleChange = useCallback(
    (step: number, formValue: FormTypes) => {
      setValues((s) => ({ ...s, [step]: formValue }))
    },
    [setValues]
  )

  const fetchOnboarding = useCallback(async () => {
    setFetching(true)
    const url = '/v1/onboarding'
    const response = await DataService({ url, type: 'GET' })
    const data = response?.data?.record

    const isCPF = data?.informations?.document
      ? onlyNumber(data.informations.document).length <= 11
      : true

    setValues((s) => ({
      ...s,
      1: { ...data?.informations, isCPF } ?? initialOnboardingValues.values[1],
      2: data?.address ?? initialOnboardingValues.values[2],
      3: data?.bank_account ?? initialOnboardingValues.values[3]
    }))
    setStatus(response.data.merchant_status as Status)
    setCookie(undefined, 'nextauth.status', response.data.merchant_status, {
      maxAge
    })

    setStep(stepsMapper[data?.step || 'activation_requested'])
    setFetching(false)
  }, [setValues])

  useEffect(() => {
    const { ['nextauth.status']: local_status, ['nextauth.token']: token } =
      parseCookies()

    if (local_status === 'enabled' || !token) return
    fetchOnboarding()
  }, [hasWindow && window?.location?.pathname])

  const value = useMemo(
    () => ({
      values,
      status,
      handleChange,
      fetchOnboarding,
      step,
      setStep,
      fetching
    }),
    [values, status, handleChange, fetchOnboarding, step, setStep, fetching]
  )

  return (
    <OnboardingFormContext.Provider value={value}>
      {children}
    </OnboardingFormContext.Provider>
  )
}

export const useOnboardingContext = (): OnboardingContextProps => {
  const context = useContext(OnboardingFormContext)

  if (!context) {
    throw new Error(
      'useOnboardingContext should be used within OnboardingFormProvider.'
    )
  }

  return context
}
