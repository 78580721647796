import React from 'react'
import { FieldValues } from 'react-hook-form'

import { Box } from '@mui/material'

import PaymentsType from 'components/PaymentsType'

import FrequencyControls from '../FrequencyControls'
import { BillingMethodProps } from './BillingMethod'

const BillingMethod = <T extends FieldValues>({
  control,
  payments,
  register,
  watch,
  customerUuid,
  errors,
  edit,
  storedCycleValue,
  setStoredCycleValue,
  isUnique,
  setIsUnique
}: BillingMethodProps<T>) => {
  return (
    <>
      {!edit && (
        <>
          <FrequencyControls
            watch={watch}
            control={control}
            setStoredCycleValue={setStoredCycleValue}
            storedCycleValue={storedCycleValue}
            isUnique={isUnique}
            setIsUnique={setIsUnique}
          />
        </>
      )}

      <Box mt="16px" mb="4px">
        <PaymentsType
          payments={payments}
          register={register}
          control={control}
          errors={errors}
          customerUuid={customerUuid}
          watch={watch}
        />
      </Box>
    </>
  )
}

export default BillingMethod
