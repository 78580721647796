/* eslint-disable sort-keys */
import { Icon } from '@clientbase/clientbase-library'
import { Box } from '@mui/material'
import { DataService } from 'api/DataService'
import moment from 'moment'

import { ButtonRounded } from 'components/button'

import { theme } from 'styles/theme'

import { ReportResponse } from './ModalReport'
import * as S from './ModalReport.styles'

const statusInNumberMapper = {
  1: 'Pendente',
  2: 'Processando',
  3: 'Disponível',
  9: 'Erro'
}

const typeMapper = {
  Customer: 'Clientes',
  Billing: 'Cobranças',
  BillingDetailed: 'Cobranças detalhadas',
  Receivable: 'Recebíveis',
  Transfer: 'Transferências',
  TransferDetailed: 'Transferências detalhadas',
  billing_transfer: 'Detalhes da transferência',
  Contract: 'Contratos',
  Recurrence: 'Recorrências',
  recurrence: 'Recorrências',
  Nfse: 'Notas Fiscais',
  ProductLink: 'Link de Pagamentos'
}

// const filtersMapper = (k: string, v: string | number) => {
//  if (!k) return [];
//
//  const filtersKeyRename = {
//    customer_name: {
//      label: "Cliente: ",
//      value: v,
//    },
//    amount_billed: {
//      label: "Valor: ",
//      value: formatMoney(v),
//    },
//    status: {
//      label: "",
//      value: v,
//    },
//    due_date_month: {
//      label: "Mês: ",
//      value: v,
//    },
//    due_date_year: {
//      label: "Ano: ",
//      value: v,
//    },
//  };
//
//  return [filtersKeyRename[k].label, filtersKeyRename[k].value];
// };
const documentViewed = async (uuid: string) => {
  const resp = await DataService({
    type: 'POST',
    url: `/v1/reports/${uuid}/downloaded`
  })
  return resp
}

const handleDownload = async (uuid: string) => {
  try {
    const resp = await DataService({
      type: 'GET',
      url: `/v1/reports/${uuid}/excel`
    })
    const data = await resp.data

    window.open(data.record.url, '_blank')
  } catch (err) {
    return
  }
  documentViewed(uuid)
}

export const createDataTable = (
  downloaded: boolean,
  uuid: string,
  type: string,
  due_date: Date,
  // filters: string,
  status: number
) => {
  // if (!filters) return {};
  const date = moment(due_date).format('DD/MM/YYYY HH:mm')

  // const filter = filters
  //   ?.replace(/:/gi, "=")
  //   .split(",")
  //   .map((el) => el.split("="))
  //   .map(([k, v]) => filtersMapper(k, v))
  //   .filter(Boolean);

  return {
    type: (
      <S.NotifyWrapper>
        {downloaded || status === 9 ? (
          <S.Separator />
        ) : (
          <div>
            <S.Notify notViewed={true} />
          </div>
        )}
        <S.StatusText>{typeMapper[type]}</S.StatusText>
      </S.NotifyWrapper>
    ),
    date,
    //    filters: filter.join(" + ").replace(/ ,/g, " "),
    status: statusInNumberMapper[status],
    backgroundColor: downloaded ? '' : status !== 9 ? '#F2F5F2' : '',
    actions: (
      <Box>
        <ButtonRounded
          onClick={() => handleDownload(uuid)}
          variant="transparent"
        >
          <Icon icon="download" sx={{ color: theme.palette.neutral[200] }} />
        </ButtonRounded>
      </Box>
    )
  }
}

export const convertToRows = (reports: ReportResponse[]) =>
  reports?.map((report: ReportResponse) => {
    if (!report) return {}

    return createDataTable(
      report.downloaded,
      report.uuid,
      report.model,
      report.created_at,
      // report.filter_by,
      report.status
    )
  })
