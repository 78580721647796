import { Chip, styled } from '@mui/material'

import { theme } from 'styles/theme'

interface NotifyProps {
  notViewed: boolean
}
interface ItemDescriptionProps {
  isExpanded: boolean
}

interface UpdateWrapperProps {
  notViewed?: boolean
  hasLink?: boolean
}

export const Wrapper = styled('button')(({ theme }) => ({
  ['&:focus, &:hover']: {
    outline: `1px auto ${theme.palette.neutral[200]}`,
    outlineOffset: '3px'
  },
  alignItems: 'center',
  all: 'inherit',
  border: `2px solid black`,
  borderRadius: '50%',
  cursor: 'pointer',
  display: 'flex',
  height: '32px',
  justifyContent: 'center',
  position: 'relative',
  width: '32px',
  [theme.breakpoints.down(770)]: {
    border: '2px solid #000',
    width: '26px',
    height: '26px',
    fontSize: '10px'
  }
}))

export const Header = styled('header')(() => ({
  boxSizing: 'border-box',
  padding: 18,
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'space-between',
  borderBottom: '1px solid #E3E6E3'
}))

export const Heading = styled('h3')(() => ({
  margin: 0,
  padding: 0,
  font: 'normal 700 16px/140% Inter, sans-serif'
}))

export const Subtitle = styled('p')(() => ({
  margin: 0,
  padding: 0,
  font: 'normal 500 12px Inter, sans-serif'
}))

export const Content = styled('div')(() => ({
  padding: '25px 18px',
  display: 'flex',
  flexDirection: 'column',
  gap: '2',
  '> div + div': {
    boxSizing: 'border-box',
    paddingTop: 10,
    paddingBottom: 10,
    borderTop: '1px solid #E3E6E3'
  }
}))

export const Status = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.alert[300],
  border: '2px solid #fff',
  borderRadius: '50%',
  bottom: '-5px',
  height: '12px',
  position: 'absolute',
  right: '-5px',
  width: '12px',
  [theme.breakpoints.down(770)]: {
    width: '10px',
    height: '10px'
  }
}))

export const UpdateWrapper = styled('div')<UpdateWrapperProps>(
  ({ theme, notViewed, hasLink }) => ({
    [theme.breakpoints.down(800)]: {
      marginBottom: '10px',
      alignItems: 'end',
      gridTemplateColumns: hasLink ? '240px auto' : '100%',
      justifyItems: hasLink ? 'end' : 'center'
    },
    alignItems: 'center',
    display: 'grid',
    padding: '10px',
    justifyItems: 'center',
    columnGap: '20px',
    gridTemplateColumns: '240px auto',
    borderRadius: '8px',
    backgroundColor: notViewed ? theme.palette.info[400] : 'white'
  })
)

export const ItemContent = styled('div')(() => ({
  alignItems: 'center',
  display: 'grid',
  gridTemplateColumns: 'auto 40px',
  [theme.breakpoints.down(800)]: {
    gridTemplateColumns: 'auto',
    gridColumn: '1/-1'
  }
}))

export const CustomChip = styled(Chip)<NotifyProps>(({ notViewed }) => ({
  marginTop: '8px',
  color: '#757675',
  height: '20px',
  backgroundColor: notViewed ? ' #fff' : '',
  '&:hover': {
    backgroundColor: '#f0f0f0'
  }
}))

export const Info = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 20,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column'
  }
}))

export const IconWrapper = styled('div')(() => ({
  display: 'flex',
  bgcolor: '#fff',
  justifyContent: 'center',
  alignItems: 'center',
  height: '24px',
  width: '24px',
  borderRadius: '6px'
}))

export const Link = styled('a')(() => ({
  width: 'fit-content',
  textDecoration: 'none',
  color: '#757675'
}))

export const ImageWrapper = styled('div')(() => ({
  position: 'relative',
  width: 200,
  height: 110,
  boxSizing: 'border-box',
  borderRadius: '5px',
  cursor: 'zoom-in',
  '& img': {
    borderRadius: '8px',
    objectFit: 'cover'
  }
}))

export const ItemHeading = styled('h2')(() => ({
  margin: '0 0 5px',
  font: 'normal 700 14px/140% Inter, sans-serif',
  color: '#231F20',
  [theme.breakpoints.down(800)]: {
    marginTop: '10px'
  }
}))

export const ItemDescription = styled('p')<ItemDescriptionProps>(
  ({ isExpanded }) => ({
    margin: 0,
    font: 'normal 500 12px Inter, sans-serif',
    maxWidth: '40ch',
    color: 'inherit',
    textWrap: 'wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: isExpanded ? 'auto' : 4,
    lineClamp: 2,
    whiteSpace: 'normal'
  })
)

export const TagsWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  maxWidth: 232,
  overflow: 'hidden',
  overflowX: 'auto',
  padding: '8px 0',
  gap: 6
}))

export const ItemTag = styled('span')(() => ({
  margin: 0,
  font: 'normal 500 12px Inter, sans-serif',
  color: '#4C98FF',
  background: '#E1ECFE',
  width: 'fit-content',
  padding: '2px 8px',
  borderRadius: '9999px'
}))
