import { createContext, useState } from 'react'

import { Negativation } from 'models/Negativation'

import {
  DialogCancelParams,
  DialogContactParams,
  DialogRecoverParams,
  ModalDetailParams,
  ModalInvoiceParams,
  NegativationProps,
  NegativationState
} from './index.interface'

export const NegativationContext = createContext({} as NegativationState)

export function NegativationProvider({ children }: NegativationProps) {
  const [modalForm, setModalForm] = useState<boolean>(false)
  const [modalDetail, setModalDetail] = useState<ModalDetailParams>({
    open: false,
    uuid: ''
  })

  const [modalInvoice, setModalInvoice] = useState<ModalInvoiceParams>({
    open: false,
    uuid: ''
  })

  const [dialogCancel, setDialogCancel] = useState<DialogCancelParams>({
    open: false
  })
  const [dialogContact, setDialogContact] = useState<DialogContactParams>({
    open: false
  })
  const [dialogRecover, setDialogRecover] = useState<DialogRecoverParams>({
    open: false,
    uuid: ''
  })

  const [negativation, setNegativation] = useState<Negativation>(
    {} as Negativation
  )

  const [reloadTable, setReloadTable] = useState<boolean>(false)

  return (
    <NegativationContext.Provider
      value={{
        modalForm,
        updateModalForm: setModalForm,
        modalDetail,
        updateModalDetail: setModalDetail,
        negativation: negativation,
        updateNegativation: setNegativation,
        dialogCancel,
        updateDialogCancel: setDialogCancel,
        dialogRecover,
        updateDialogRecover: setDialogRecover,
        reloadTable,
        updateReloadTable: setReloadTable,
        dialogContact,
        updateDialogContact: setDialogContact,
        modalInvoice,
        updateModalInvoice: setModalInvoice
      }}
    >
      {children}
    </NegativationContext.Provider>
  )
}
