import { styled } from '@mui/material'

export const Header = styled('div')(() => ({
  borderTopLeftRadius: '3px',
  borderTopRightRadius: '3px',
  background: '#F8F8F8',
  paddingTop: '4px',
  paddingBottom: '4px',
  fontSize: '16px',
  fontWeight: '600',
  textAlign: 'center'
}))

export const Content = styled('div')(() => ({
  border: '1px solid #EDEBEB',
  padding: '25px 10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '36px'
}))

export const Section = styled('div')(() => ({
  width: '100%'
}))

export const Separator = styled('div')(() => ({
  width: '1fr',
  flexGrow: '1',
  borderTop: '0.5px dashed #CBCBCB',
  alignSelf: 'center',
  marginTop: '2px'
}))

export const Infos = styled('div')(() => ({
  display: 'flex',
  gap: '12px',
  alignItems: 'end'
}))

export const Term = styled('div')(() => ({
  padding: '15px',
  maxHeight: '150px',
  overflowX: 'auto',
  borderRadius: '8px',
  border: '1px solid #D7D7D7',
  marginTop: '22px',
  marginBottom: '12px',
  fontSize: '12px'
}))
