function currency(e: string | number) {
  let value = ''
  if (typeof e === 'number') {
    value = e.toFixed(2).toString()
  } else {
    value = e
  }
  if (value.indexOf('.') !== -1) {
    const toNumber = parseFloat(value).toFixed(2)
    value = toNumber.toString()
    value.replace('.', '')
  }
  value = value.replace(/\D/g, '')
  value = value.replace(/(\d)(\d{2})$/, '$1,$2')
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.')

  e = value
  return e
}

const formatMoney = (value: string | number | undefined) => {
  if (!value) return 'R$ 0,00'
  return Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    style: 'currency'
  }).format(Number(value))
}

function onlyNumber(e: string) {
  return e.replace(/\D/g, '')
}

function formatMoneyToRequest(amount: string): number {
  return Number(currency(amount).replace('.', '').replace(',', '.'))
}

export { currency, formatMoney, onlyNumber, formatMoneyToRequest }
