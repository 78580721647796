import * as React from 'react'
import { SVGProps } from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}
const BoletoIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#494949"
      d="M1.25 26.25v-5.688h1.875v3.813h3.813v1.875H1.25Zm21.781 0v-1.875h3.813v-3.813h1.875v5.688H23.03Zm-18.25-3.469V7.188h2.5V22.78h-2.5Zm3.782 0V7.188h1.312V22.78H8.562Zm3.812 0V7.188h2.594V22.78h-2.594Zm3.906 0V7.188h3.782V22.78H16.28Zm5.094 0V7.188h1.313V22.78h-1.313Zm2.594 0V7.188h1.187V22.78H23.97ZM1.25 9.438V3.75h5.688v1.875H3.124v3.813H1.25Zm25.594 0V5.624H23.03V3.75h5.688v5.688h-1.875Z"
    />
  </svg>
)
export default BoletoIcon
