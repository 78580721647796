import * as React from 'react'
import { SVGProps } from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}
const CreditCardIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#494949"
      d="M27.5 6.875v16.25c0 .5-.188.938-.563 1.313-.375.375-.812.562-1.312.562H4.375c-.5 0-.938-.188-1.313-.563-.374-.375-.562-.812-.562-1.312V6.875c0-.5.188-.938.563-1.313C3.437 5.188 3.875 5 4.375 5h21.25c.5 0 .938.188 1.313.563.375.375.562.812.562 1.312ZM4.375 10.281h21.25V6.875H4.375v3.406Zm0 4.031v8.813h21.25v-8.813H4.375Z"
    />
  </svg>
)
export default CreditCardIcon
