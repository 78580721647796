import Image from 'next/image'

import * as S from './Empty.styles'

const Empty = () => {
  return (
    <S.Wrapper>
      <S.Header>
        <S.Heading>Ops, não encontramos nenhum resultado.</S.Heading>
      </S.Header>

      <S.ImageWrapper>
        <Image
          src="/images/empty/empty-negativation.svg"
          alt="pasta vazia"
          layout="fill"
        />
      </S.ImageWrapper>

      <S.Description>
        Não encontramos nenhum cobrança que pode ser negativada. Você pode
        negativar cobranças que já estão vencidas ou cobranças expiradas.
      </S.Description>
    </S.Wrapper>
  )
}

export default Empty
