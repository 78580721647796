import axios from 'axios'
import { parseCookies } from 'nookies'

export const baseURL = 'https://api.clientbase.com.br'

export function getAPIClient(ctx?: any) {
  const { ['nextauth.token']: token } = parseCookies(ctx)

  const api = axios.create({ baseURL })

  if (token) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`
  }

  return api
}
