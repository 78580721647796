import {
  Button as ButtonMui,
  Drawer,
  IconButton,
  Link,
  styled,
  Theme
} from '@mui/material'

export const LogoWrapper = styled('div')(() => ({
  cursor: 'pointer',
  width: '100%',
  paddingTop: 4,
  paddingLeft: 16
}))

export const ImageWrapper = styled('div')(() => ({
  height: 48,
  maxWidth: 48,
  width: '100%',
  margin: '0 auto',
  position: 'relative'
}))

export const ButtonsWrapper = styled('div')(() => ({
  '& span': {
    marginRight: 11
  },
  '&, & span': {
    textTransform: 'none'
  },
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  marginTop: 50
}))

export const Label = styled('span')(({ theme }) => ({
  color: theme.palette.neutral[400]
}))

export const Footer = styled('footer')(() => ({
  display: 'flex',
  flexDirection: 'column',
  bottom: 21,
  left: 12,
  marginTop: '30px'
}))

export const Option = styled(Link)(({ theme }) => ({
  alignItems: 'center',
  color: theme.palette.neutral[400],
  display: 'flex',
  gap: 10
}))

type ButtonProps = {
  selected?: boolean
}

const buttonModifiers = {
  isSelected: (theme: Theme) => `
    background: ${theme.palette.neutral['A100']};

    svg {
      transition: color 200ms linear;
      color: ${theme.palette.primary[700]};
    }

    span {
      color: ${theme.palette.neutral[100]};
      font-weight: 600;
    }
  `
}

export const Button = styled(ButtonMui)<ButtonProps>(
  ({ theme, selected }) => `
  background: transparent;
  border-radius: 12px;
  color: ${theme.palette.neutral[400]};
  font-size: 15px;
  padding: 14px 15px;
  width: 100%;

  text-align: left;
  justify-content: start;

  transition: all 200ms linear;

  &:hover {
    ${buttonModifiers.isSelected(theme)}
  }

  ${!!selected && buttonModifiers.isSelected(theme)}
`
)

export const CloseButton = styled(IconButton)(
  ({ theme }) => `
  position: absolute;
  right: 20px;
  top: 25px;

  &:focus {
    box-shadow: 0 0 2px 0 ${theme.palette.neutral[200]};
  }
`
)

export const HandleToggleIsExpandedButton = styled('button')(
  ({ theme }) => `
  border: 0;
  outline: 0;

  width: 50px;
  height: 50px;
  border-radius: 100px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  position: absolute;
  right: -20px;
  top: 90px;
  opacity: 0;
  background: ${theme.palette.neutral[700]};
  transition: box-shadow 200ms, scale 250ms;

  & path {
    color: ${theme.palette.text.secondary};
  }

  &:hover {
    scale: 1.10;
  }

  &:active {
    scale: 0.93;
  }
`
)

type ActiveAccountParams = {
  isExpanded: boolean
}

export const ActiveAccount = styled('a')<ActiveAccountParams>(
  ({ theme, isExpanded }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    textDecoration: 'none',
    border: '1px solid transparent',
    borderRadius: 12,
    boxSizing: 'border-box',
    cursor: 'pointer',
    padding: 12,
    position: 'relative',
    transition: 'color 200ms',
    width: isExpanded ? '100%' : 'fit-content',
    margin: isExpanded ? 0 : '0 auto',
    whiteSpace: 'nowrap',
    background: theme.palette.success[300],
    color: theme.palette.neutral[900],
    svg: {
      color: theme.palette.neutral[900]
    }
  })
)

type DrawerWrapperProps = {
  width: number | string
  isExpanded: boolean
}

export const DrawerWrapper = styled(Drawer)<DrawerWrapperProps>(
  ({ theme, width, isExpanded }) => ({
    '& .MuiDrawer-paper': {
      background: theme.palette.neutral['800'],
      border: 'none',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      width,
      zIndex: '1',
      overflow: 'visible !important',
      padding: isExpanded ? '21px 12px' : '21px 0 52px',
      transition: 'width 140ms'
    },

    ['&:hover button']: {
      opacity: 1
    }
  })
)

export const WrapperDrawer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%'
}))

interface ButtonProProps {
  isEnabledUser?: boolean
}

export const ButtonPro = styled(Button)<ButtonProProps>(
  ({ theme, isEnabledUser }) => ({
    width: '100%',
    textTransform: 'none',

    alignSelf: 'center',
    backgroundColor: isEnabledUser ? theme.palette.info[200] : 'transparent',

    border: `2px solid ${
      isEnabledUser ? 'transparent' : theme.palette.info[200]
    }`,

    color: isEnabledUser ? '#fff' : theme.palette.info[200],
    fontWeight: isEnabledUser ? 500 : 600,
    padding: '7px 30px',
    ':hover': {
      width: '100%',
      backgroundColor: isEnabledUser ? theme.palette.info[200] : 'transparent'
    }
  })
)
