import { styled } from '@mui/material'

export const Link = styled('a')(() => ({
  margin: 0,
  padding: 0,
  fontWeight: 600,
  color: '#231F20',
  cursor: 'pointer'
}))

export const Separator = styled('div')(() => ({
  width: '1fr',
  flexGrow: '1',
  borderTop: '0.5px dashed #CBCBCB',
  alignSelf: 'center',
  marginTop: '2px'
}))
