import { DialogContentText, DialogTitle } from '@mui/material'
import styled from '@mui/system/styled'
import { darken } from 'polished'

import { DialogProps } from './Dialog'

export const Wrapper = styled('div')(() => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  boxSizing: 'border-box',
  padding: 32
}))

export const CloseIconWrapper = styled('div')(() => ({
  position: 'absolute',
  right: 9,
  top: 7,
  cursor: 'pointer',
  padding: 5,
  width: 25,
  height: 25,
  borderRadius: 25,
  display: 'grid',
  placeItems: 'center',
  '&:hover': {
    background: darken(0.05, '#F2F5F2')
  }
}))

export const IconWrapper = styled('div')(() => ({
  width: 68,
  height: 68,
  padding: 10,
  background: '#F8F8F8',
  borderRadius: 12,
  margin: '0 auto',

  '& img, & svg': {
    width: '100%',
    height: '100%'
  }
}))

export const Heading = styled(DialogTitle)(() => ({
  margin: 0,
  padding: 0,
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: 20
}))

export const Description = styled(DialogContentText)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.neutral[300],
  textAlign: 'center'
}))

type ActionsWrapper = Pick<DialogProps, 'cancelButton'>
export const ActionsWrapper = styled('div')<ActionsWrapper>(
  ({ cancelButton }) => ({
    display: 'grid',
    gridTemplateColumns: cancelButton ? '180px 180px' : '1fr',
    gridTemplateRows: 'auto',
    gap: 8,
    boxSizing: 'border-box'
  })
)
