import { Button } from '@clientbase/clientbase-library'
import { Box, Typography } from '@mui/material'
import styled from '@mui/system/styled'

import { theme } from 'styles/theme'

type WrapperProps = {
  bgColor: string
  link?: boolean
}

type ChipProps = {
  bgColor: string
}

export const Wrapper = styled(Box)(({ bgColor, link }: WrapperProps) => ({
  backgroundColor: bgColor,
  gap: 20,
  ...(link && { cursor: 'pointer' }),
  alignItems: 'center',
  padding: '35px',
  position: 'relative',
  borderRadius: 6,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column'
  },
  display: 'flex'
}))

export const Chip = styled(Typography)(({ bgColor }: ChipProps) => ({
  minWidth: 'fit-content',
  backgroundColor: bgColor,
  fontSize: 12,
  borderRadius: 9,
  padding: 7,
  zIndex: 3,
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
    top: -12,
    left: 35
  }
}))

export const Title = styled(Typography)(() => ({
  fontSize: 24,
  [theme.breakpoints.down('md')]: {
    fontSize: 15
  }
}))
export const Subtitle = styled(Typography)(() => ({
  fontSize: 15,
  [theme.breakpoints.down('md')]: {
    fontSize: 12
  }
}))

export const ButtonCustom = styled(Button)(() => ({
  minWidth: 'fit-content',
  marginLeft: 40,
  padding: '0 50px',
  zIndex: 3,
  textTransform: 'uppercase',
  [theme.breakpoints.down('md')]: {
    marginLeft: 20,
    alignSelf: 'end'
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  },
  ':hover': {
    padding: '0 50px'
  }
}))

export const ButtonText = styled(Typography)(() => ({
  fontSize: 15,
  fontWeight: 600,
  [theme.breakpoints.down('md')]: {
    fontSize: 12
  }
}))

export const Div = styled('div')(() => ({
  position: 'relative'
}))
