import React from 'react'

import { Icon, Select } from '@clientbase/clientbase-library'
import { MenuItem, SelectChangeEvent, useMediaQuery } from '@mui/material'

import Button from 'components/v2/Button'

import { OrderParams, OrderProps } from './Order.interface'
import * as S from './Order.styles'

export const Order: React.FC<OrderProps> = ({
  disableArrow = false,
  handleOnChange,
  options,
  value
}) => {
  function handleOnChangeLocal(
    order: OrderParams,
    event: SelectChangeEvent<unknown>
  ) {
    const {
      target: { value }
    } = event

    return handleOnChange(value as string, order)
  }

  const isMobile = useMediaQuery('(max-width: 600px)', { noSsr: false })

  function handleOnChangeSort() {
    const sort = value.sort
    if (sort === 'asc') {
      handleOnChange(String(value.label), 'desc')
    } else {
      handleOnChange(String(value.label), 'asc')
    }
  }

  return (
    <S.Wrapper>
      {value.label !== 'initial' && !disableArrow && (
        <Button
          onClick={handleOnChangeSort}
          variant="text"
          style={{ maxWidth: '50px' }}
        >
          <Icon icon={value.sort === 'asc' ? 'arrowUpward' : 'arrowDownward'} />
        </Button>
      )}

      <S.SelectWrapper sx={{ width: isMobile ? '100%' : 'auto' }}>
        <Select
          sx={{ border: 0, '*': { border: 0, outline: 0 } }}
          fullWidth
          onChange={(event: SelectChangeEvent<unknown>) =>
            handleOnChangeLocal(value.sort, event)
          }
          value={value.label}
        >
          {options.map(({ label, value }, index) => (
            <MenuItem key={index} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </S.SelectWrapper>
    </S.Wrapper>
  )
}
