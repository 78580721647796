import { DataService } from 'api/DataService'
import moment from 'moment'

export class BillingService {
  public static async getBillingsNegativation(
    customer_uuid: string
  ): Promise<any> {
    const response = await DataService({
      type: 'GET',
      url: `/v1/billings?per=100&filter_by=customer_uuid=${customer_uuid},due_date_lower_than=${moment().format(
        'yyyy-MM-DD'
      )},status=no_payment|expiring|open_payment`
    })

    if (response.status === 200) {
      return response?.data
    }

    return {} as any
  }
}
