type HandleGetDataTableParams = {
  filterBy: any
  uuid?: string
}

const filterTransformToUrl = ({ filterBy, uuid }: HandleGetDataTableParams) => {
  let queryString = ''

  const filter = { ...filterBy, ...(!!uuid && { customer_uuid: uuid }) }
  Object.entries(filter).forEach(([k, val]) => {
    if (!k || !val) return

    queryString += `${k}=${val},`
  })

  return queryString.slice(0, -1)
}

export default filterTransformToUrl
