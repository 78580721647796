import styled from '@mui/system/styled'
import moment from 'moment'
import { rgba } from 'polished'

import { theme } from 'styles/theme'

const getStyle = (status: string, dueDate?: string) => {
  const today = moment().startOf('day').format('YYYY-MM-DD')
  switch (status) {
    case 'pending':
      return {
        color: theme.palette.neutral[400],
        background: rgba(theme.palette.neutral[700], 0.3)
      }
    case 'processing':
      return {
        color: theme.palette.neutral[400],
        background: rgba(theme.palette.neutral[700], 0.3)
      }
    case 'active':
      return {
        color: theme.palette.primary[100],
        background: rgba(theme.palette.primary[200], 0.1)
      }
    case 'created_payment':
      return {
        color: theme.palette.neutral[700],
        background: rgba(theme.palette.neutral[700], 0.3)
      }
    case 'open_payment':
      if (dueDate && moment(dueDate).isBefore(today)) {
        return {
          color: theme.palette.alert[300],
          background: rgba(theme.palette.alert[400], 0.3)
        }
      }
      return {
        color: theme.palette.primary[200],
        background: rgba(theme.palette.primary[200], 0.1)
      }
    case 'no_payment':
      return {
        color: theme.palette.error[300],
        background: rgba(theme.palette.error[300], 0.1)
      }
    case 'paid':
      return {
        color: theme.palette.primary[200],
        background: rgba(theme.palette.primary[200], 0.2)
      }
    case 'confirmada':
      return {
        color: theme.palette.primary[200],
        background: rgba(theme.palette.primary[200], 0.2)
      }
    case 'cancelling':
      return {
        color: theme.palette.error[300],
        background: rgba(theme.palette.error[300], 0.1)
      }
    case 'canceled':
      return {
        color: theme.palette.error[300],
        background: rgba(theme.palette.error[300], 0.1)
      }
    case 'cancelled':
      return {
        color: theme.palette.error[300],
        background: rgba(theme.palette.error[300], 0.1)
      }
    case 'with_error':
      return {
        color: theme.palette.error[300],
        background: rgba(theme.palette.error[300], 0.1)
      }
    case 'expired':
      return {
        color: theme.palette.error[300],
        background: rgba(theme.palette.error[300], 0.1)
      }
    case 'finished':
      return {
        color: theme.palette.primary[200],
        background: rgba(theme.palette.primary[200], 0.1)
      }
    case 'suspended':
      return {
        color: theme.palette.neutral[700],
        background: rgba(theme.palette.neutral[700], 0.3)
      }
    case 'succeeded':
      return {
        color: theme.palette.primary[200],
        background: rgba(theme.palette.primary[200], 0.2)
      }
    case 'failed':
      return {
        color: theme.palette.error[300],
        background: rgba(theme.palette.error[300], 0.1)
      }
    case 'blocked':
      return {
        color: theme.palette.error[300],
        background: rgba(theme.palette.error[300], 0.1)
      }
    case 'deleted':
      return {
        color: theme.palette.error[300],
        background: rgba(theme.palette.error[300], 0.1)
      }
    case 'archived':
      return {
        color: theme.palette.neutral[400],
        background: rgba(theme.palette.neutral[700], 0.3)
      }
    case 'client':
      return {
        color: theme.palette.primary[200],
        background: rgba(theme.palette.primary[200], 0.2)
      }
    case 'billing':
      return {
        color: theme.palette.primary[200],
        background: rgba(theme.palette.secondary[200], 0.2)
      }
    default:
      window.newrelic.noticeError(
        new Error(
          `Erro ao mapear estilos da Status: ${status} (Status/status.styles)`
        )
      )
      return {
        color: theme.palette.neutral[400],
        background: rgba(theme.palette.neutral[700], 0.3)
      }
  }
}

type StatusProps = {
  status: string
  fontIsThini?: boolean
  dueDate?: string
}

export const Status = styled('div')<StatusProps>(
  ({ status, fontIsThini, dueDate }) => ({
    boxSizing: 'border-box',
    width: 'fit-content',
    padding: '4px 8px',
    fontWeight: fontIsThini ? 400 : 600,
    borderRadius: 9999,
    ...getStyle(status, dueDate),
    ...(fontIsThini && {
      padding: '2px 10px'
    })
  })
)
