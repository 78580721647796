import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { Button, Textfield } from '@clientbase/clientbase-library'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Tooltip, Typography } from '@mui/material'
import { DataService } from 'api/DataService'
import { useOnboardingContext } from 'context/OnboardingContext'
import { setCookie } from 'nookies'
import { ActivateAccountFormsProps } from 'templates/ActivateAccount/ActivateAccount'

import { DatePicker } from 'components/DatePicker'
import InputController from 'components/InputController'

import { currency, Debouncer, onlyNumber } from 'utils'

import {
  InformationFormSchema,
  schemaInformationForm
} from './Information.schema'
import * as S from './Information.styles'

export const initialInformationFormSchemaValue = {
  isCPF: true,
  document: '',
  legal_name: '',
  revenue: '0'
}

const InformationForm = ({
  sucessSubmit,
  handleBackStep
}: ActivateAccountFormsProps) => {
  const { values, handleChange } = useOnboardingContext()

  const { handleSubmit, control, watch, setValue } =
    useForm<InformationFormSchema>({
      defaultValues: values['1'],
      resolver: yupResolver(schemaInformationForm)
    })
  const { document, legal_name } = watch()
  const [isCNPJ, setIsCNPJ] = useState(onlyNumber(document || '').length === 14)
  const awaitToSubmit = Debouncer(() => autoCompleteDocument(), 700)

  const onSubmit: SubmitHandler<InformationFormSchema> = async (data) => {
    // eslint-disable-next-line
    const { isCPF, revenue, ...rest } = data
    const value = currency(revenue).replace('.', '').replace(',', '.')
    const parsedValue = revenue.length > 8 ? value.replace('.', '') : value

    const response = await DataService({
      type: 'PATCH',
      data: {
        ...rest,
        revenue: parsedValue
      },
      url: '/v1/onboarding/merchant'
    })
    setCookie(undefined, 'nextauth.document', document, {
      maxAge: 60 * 60 * 24 * 6
    })

    handleChange(1, data)
    return sucessSubmit({ error: response?.error, message: response?.message })
  }

  const autoCompleteDocument = async () => {
    if (!document) return
    const url = `/v1/onboarding/enrich/${onlyNumber(document)}`
    const response = await DataService({ url, type: 'GET' })
    if (!legal_name) setValue('legal_name', response?.data?.record?.name)
  }

  useEffect(() => {
    setIsCNPJ(onlyNumber(document || '').length === 14)
    setValue('isCPF', onlyNumber(document || '').length === 11)
    awaitToSubmit('')
  }, [document])

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Typography variant="mdBold">
          Você quer ativar a sua conta como empresa (CNPJ) ou autônomo (CPF)?
        </Typography>
        <ul>
          <li>
            <Typography variant="smLight">
              CPF deve receber em uma conta bancária PF, da mesma titularidade.
            </Typography>
          </li>
          <li>
            <Typography variant="smLight">
              CNPJ deve receber em uma conta bancária PJ, da mesma titularidade.
            </Typography>
          </li>
        </ul>
      </div>

      <S.InputWrapper>
        <InputController control={control} name="document">
          <Textfield
            label="CPF/CNPJ:"
            placeholder="00.000.000/0000-00"
            mask="cpfCnpj"
          />
        </InputController>
        {isCNPJ && (
          <>
            <InputController control={control} name="legal_name">
              <Textfield label="Razão Social:" />
            </InputController>
            <InputController control={control} name="owner_name">
              <Textfield label="Nome do sócio:" />
            </InputController>
            <InputController control={control} name="owner_document">
              <Textfield label="CPF do sócio:" mask="cpfCnpj" />
            </InputController>
            <>
              <Controller
                control={control}
                name={'owner_birthdate'}
                render={({
                  field: { onChange, value },
                  fieldState: { error }
                }) => {
                  return (
                    <DatePicker
                      helperText={error?.message}
                      error={!!error?.message}
                      label={'Data de nascimento do sócio'}
                      maxDate={new Date()}
                      onChange={onChange}
                      renderInput={(props: any) => (
                        <Textfield {...props} name="owner_birthdate" />
                      )}
                      toolbarPlaceholder="Data de nascimento do sócio"
                      value={value ? value : null}
                    />
                  )
                }}
              />
            </>
          </>
        )}

        {onlyNumber(document || '').length === 11 && (
          <div>
            <InputController control={control} name="legal_name">
              <Textfield label="Nome completo:" />
            </InputController>
          </div>
        )}
        {(onlyNumber(document || '').length === 11 || isCNPJ) && (
          <>
            <Box gridRow={isCNPJ ? 3 : 'auto'}>
              <Controller
                control={control}
                name={'birthdate'}
                render={({
                  field: { onChange, value },
                  fieldState: { error }
                }) => {
                  return (
                    <DatePicker
                      helperText={error?.message}
                      error={!!error?.message}
                      label={
                        onlyNumber(document || '').length < 14
                          ? 'Data de nascimento'
                          : 'Fundação da empresa'
                      }
                      maxDate={new Date()}
                      onChange={onChange}
                      renderInput={(props: any) => (
                        <Textfield {...props} name="birthdate" />
                      )}
                      toolbarPlaceholder="Complete com  a data de fundação da empresa"
                      value={value ? value : null}
                    />
                  )
                }}
              />
            </Box>
            <div>
              <Controller
                control={control}
                name="revenue"
                render={({
                  field: { onChange, value },
                  fieldState: { error }
                }) => {
                  return (
                    <Textfield
                      label={
                        <Grid
                          container={true}
                          gap={'3px'}
                          alignItems={'center'}
                        >
                          <Grid item={true}>
                            Faturamento {isCNPJ && 'da empresa'}
                          </Grid>
                          <Grid item={true}>
                            <div>
                              <Tooltip
                                arrow
                                placement="top"
                                title={'Valor de faturamento projetado, mensal'}
                              >
                                <Box display={'flex'}>
                                  <S.IconStyled icon="info" />
                                </Box>
                              </Tooltip>
                            </div>
                          </Grid>
                        </Grid>
                      }
                      mask="money"
                      startAdornment={true}
                      customStartAdornment={
                        <Typography mr="6px" variant="mdLight">
                          R$
                        </Typography>
                      }
                      value={value}
                      onChange={onChange}
                      helperText={error?.message}
                      error={!!error?.message}
                    />
                  )
                }}
              />
            </div>
          </>
        )}
      </S.InputWrapper>
      <Box mt={2} display="flex" gap={1.5} width="100%">
        <Button
          fullWidth
          variantButton="secondary"
          type="button"
          onClick={handleBackStep}
        >
          {'<-'} VOLTAR
        </Button>
        <Button fullWidth variantButton="green" type="submit">
          CONTINUAR
        </Button>
      </Box>
    </S.Form>
  )
}

export default InformationForm
