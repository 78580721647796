import React from 'react'

import Modal from 'components/v2/Modal'

import { FormNegativationProvider } from './context'
import FormNegativation from './FormNegativation'
import { ModalNegativationProps } from './index.interface'

const ModalNegativation: React.FC<ModalNegativationProps> = ({
  toggleModal,
  modalNegativation,
  invoice
}) => {
  return (
    <Modal
      isOpen={modalNegativation}
      handleToggle={() => toggleModal('formNegativation')}
      heading="Nova negativação"
      icon="thumbDownAlt"
      colorIcon="#56BB4D"
      orderHead="bottom"
    >
      <FormNegativationProvider>
        <FormNegativation invoice={invoice} />
      </FormNegativationProvider>
    </Modal>
  )
}

export default ModalNegativation
