import { GetServerSidePropsContext, PreviewData } from 'next'
import { destroyCookie } from 'nookies'
import { ParsedUrlQuery } from 'querystring'

export const destroySession = (
  ctx?: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>
) => {
  destroyCookie(ctx, 'nextauth.uuid')
  destroyCookie(ctx, 'nextauth.token')
  destroyCookie(ctx, 'nextauth.user')
  destroyCookie(ctx, 'nextauth.email')
  destroyCookie(ctx, 'nextauth.installment_billings')
  destroyCookie(ctx, 'nextauth.status')
  destroyCookie(ctx, 'nextauth.user_logo_url')
  destroyCookie(ctx, 'nextauth.automatic_transfer')
  destroyCookie(ctx, 'nextauth.merchant_user_name')
  destroyCookie(ctx, 'nextauth.pro')
  destroyCookie(ctx, 'nextauth.role')
  destroyCookie(ctx, '6fa69047bb57a1a2837f271d59d45914')
}
