import { useEffect, useState } from 'react'

import { TimezoneHelper } from 'utils/timezone-helper'

import { Time } from './Weather.styles'

function Weather() {
  const [data, setData] = useState({
    label: TimezoneHelper.format(new Date(), 'DD/MM/YYYY'),
    value: TimezoneHelper.format(new Date(), 'HH:mm')
  })

  useEffect(() => {
    const timeout = setTimeout(
      () =>
        setData({
          label: TimezoneHelper.format(new Date(), 'DD/MM/YYYY'),
          value: TimezoneHelper.format(new Date(), 'HH:mm')
        }),
      3000
    )

    return () => clearTimeout(timeout)
  }, [data])

  useEffect(() => {
    const interval = setInterval(
      () =>
        setData({
          label: TimezoneHelper.format(new Date(), 'DD/MM/YYYY'),
          value: TimezoneHelper.format(new Date(), 'HH:mm')
        }),
      1000
    )
    return () => clearInterval(interval)
  }, [data])

  return (
    <div>
      <Time>
        <span>{data.label}</span>
        <span>{data.value}</span>
      </Time>

      {/*
        Comentado em 18/10/2023
        <Time>{data.label}</Time>
        <Time>{data.value}</Time>
      */}
    </div>
  )
}

export default Weather
