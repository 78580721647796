import {
  Box,
  IconButton,
  styled,
  Typography,
  TypographyProps
} from '@mui/material'

import { ModalRootProps } from './Modal.interface'

const sizes = {
  extraLarge: {
    height: '95%',
    maxHeight: '100%',
    width: '900px'
  },
  hue: {
    height: '95%',
    maxHeight: '100%',
    width: '95%'
  },
  large: {
    height: '75%',
    maxHeight: '100%',
    width: '800px'
  },
  small: {
    height: '60%',
    maxHeight: '100%',
    width: '400px'
  }
}

export const Container = styled(Box)<ModalRootProps>(
  ({ theme, size, width, height, maxWidth, maxHeight }) => ({
    ...sizes[size],
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgb(255, 255, 255)',
    overflow: 'hidden',
    borderRadius: '8px',
    boxShadow:
      'rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px',
    boxSizing: 'border-box',
    left: '50%',
    padding: '0 0 32px 0',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    ...(width && { width }),
    ...(width && { height }),
    ...(maxWidth && { maxWidth }),
    ...(maxHeight && { maxHeight }),

    ...(size !== 'hue' && {
      [theme.breakpoints.down('md')]: {
        maxHeight: '100vh',
        maxWidth: '100vw',
        height: '100%',
        width: '100%'
      }
    })
  })
)

export const Content = styled(Box)(() => ({
  height: '100%',
  padding: '32px',
  overflowY: 'auto'
}))

export const Header = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  marginBottom: 12
}))

export const Heading = styled((props: TypographyProps) => (
  <Typography {...props} variant="lgBold" />
))(({ theme }) => ({
  color: theme.palette.neutral[200]
}))

export const CloseButton = styled(IconButton)(({ theme }) => ({
  '&:hover, &:focus-within': {
    background: theme.palette.neutral[700]
  }
}))

export const Footer = styled(Box)(() => ({
  boxSizing: 'border-box',
  backgroundColor: 'rgb(255, 255, 255)',
  width: '100%',
  padding: '0 32px'
}))
