/* eslint-disable sort-keys */
import React from 'react'

import { Icon } from '@clientbase/clientbase-library'
import { Box, Tooltip } from '@mui/material'
import { CustomerParams } from 'models/Customers'

import { ButtonRounded } from 'components/button'
import Can from 'components/Can'
import { TableNameWrapper, TableNickname } from 'components/DynamicTable'

import { theme } from 'styles/theme'

interface ReturnCreateDataTableParams {
  name: React.ReactNode | string | null | undefined
  email: string | null | undefined
  phone: string | null | undefined
  action: React.ReactNode | null | undefined
  uuid: string
}

export function createDataTable(customer: CustomerParams, actions: any) {
  return {
    uuid: customer?.uuid,
    name: (
      <TableNameWrapper>
        <p>{customer?.name}</p>
        <TableNickname>{customer?.nickname}</TableNickname>
      </TableNameWrapper>
    ),
    email: customer.email,
    phone: formatPhone(customer?.phone ?? ''),
    action: (
      <Box display="flex">
        <Tooltip
          enterTouchDelay={0}
          arrow={true}
          placement="top"
          title="Gerenciar cliente"
        >
          <Box>
            <ButtonRounded
              onClick={() => {
                actions.handleOpenDetail(customer.uuid ? customer.uuid : '')
              }}
              variant="transparent"
            >
              <Icon
                icon="manageAccounts"
                sx={{ color: theme.palette.secondary[300] }}
              />
            </ButtonRounded>
          </Box>
        </Tooltip>

        <Can roles={['admin', 'user', 'financial_operator', 'operator']}>
          <Tooltip
            enterTouchDelay={0}
            arrow={true}
            placement="top"
            title="Nova cobrança"
          >
            <Box ml="6px">
              <ButtonRounded
                onClick={() =>
                  actions.handleOpenCharge({
                    label: (customer.name && customer.name) || '',
                    value: (customer.uuid && customer.uuid) || ''
                  })
                }
                variant="transparent"
                w="0px"
              >
                <Icon
                  icon="paid"
                  sx={{ color: theme.palette.secondary[550] }}
                />
              </ButtonRounded>
            </Box>
          </Tooltip>
        </Can>
      </Box>
    )
  } as ReturnCreateDataTableParams
}

export const formatPhone = (phone: string) => {
  if (!phone) {
    return ''
  }

  const phoneLength = phone.length

  const phoneEnum = {
    8: {
      mask: () => {
        const parte1 = phone.slice(0, 4)
        const parte2 = phone.slice(4, 8)

        return `${parte1}-${parte2}`
      }
    },
    9: {
      mask: () => {
        const parte1 = phone.slice(0, 5)
        const parte2 = phone.slice(5, 9)
        return `${parte1}-${parte2}`
      }
    },
    10: {
      mask: () => {
        const parte1 = phone.slice(0, 2)
        const parte2 = phone.slice(2, 6)
        const parte3 = phone.slice(6, 15)

        return `(${parte1}) ${parte2}-${parte3}`
      }
    },
    11: {
      mask: () => {
        const parte1 = phone.slice(0, 2)
        const parte2 = phone.slice(2, 7)
        const parte3 = phone.slice(7, 15)

        return `(${parte1}) ${parte2}-${parte3}`
      }
    },
    12: {
      mask: () => {
        const parte1 = phone.slice(0, 2)
        const parte2 = phone.slice(2, 4)
        const parte3 = phone.slice(4, 8)
        const parte4 = phone.slice(8, 13)

        return `+${parte1} (${parte2}) ${parte3}-${parte4}`
      }
    },
    13: {
      mask: () => {
        const parte1 = phone.slice(0, 2)
        const parte2 = phone.slice(2, 4)
        const parte3 = phone.slice(4, 9)
        const parte4 = phone.slice(9, 14)

        return `+${parte1} (${parte2}) ${parte3}-${parte4}`
      }
    }
  }

  if (phoneLength >= 9 && phoneLength <= 13) {
    return phoneEnum[phoneLength].mask()
  } else {
    return phone
  }
}
