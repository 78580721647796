import * as yup from 'yup'

export const RevisionSchema = yup.object({
  name: yup.string().required('Nome é obrigatório.'),
  nickname: yup.string().nullable(),
  email: yup.string().required('E-mail é obrigatório.'),
  document: yup.string().required('Documento é obrigatório.'),
  phone: yup.string().required('Telefone é obrigatório.'),
  cep: yup.string().required('CEP é obrigatório.'),
  street: yup.string().required('Endereço é obrigatório.'),
  number: yup.string().required('Número é obrigatório.'),
  complement: yup.string().nullable(),
  neighborhood: yup.string().required('Bairro é obrigatório.'),
  city: yup.string().required('Cidade é obrigatória.'),
  description: yup.string().nullable()
})

export type RevisionSchemaType = yup.InferType<typeof RevisionSchema>
