import { keyframes } from '@emotion/react'
import { darken, Button as MButton, styled } from '@mui/material'

const hoverEff = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`

const spinEff = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

interface Props {
  background: string
  borderradius: string
  padding: string
  border: string
  w?: string
  size: string
  hasFocus: boolean
}

export const Button = styled(MButton)<Props>(
  ({
    background,
    borderradius,
    padding,
    border,
    w,
    size,
    theme,
    hasFocus
  }) => ({
    '&:hover': {
      backgroundColor: background,
      border,
      borderRadius: borderradius,
      height: size,
      padding
    },
    '.spinner': {
      animation: `${spinEff} infinite 5s linear`
    },

    ...(hasFocus && {
      ['&:hover, &:focus']: {
        animation: `${hoverEff} 300ms forwards`,
        outline: `2px solid ${darken(theme.palette.primary[700], 0.2)}`
      }
    }),

    backgroundColor: background,
    border,
    borderRadius: borderradius,
    height: size,
    minWidth: '46px',
    padding,
    textTransform: 'none',
    width: w
  })
)
