import React, { useContext, useEffect, useState } from 'react'

import { Box } from '@mui/material'
import { useSelectRowTable } from 'hooks/use-select-row-table'
import { BillingService } from 'services/BillingService'
import { ChargeContext } from 'templates/Charge/context'

import { Table as TableComponent } from 'components/Table'

import { FormNegativationContext } from '../../../context'
import Empty from '../Empty'
import { convertToRows } from './utils'

const Table: React.FC = () => {
  const {
    selectedCustomer,
    billings,
    billingSelected,
    updateBillingSelected,
    updateBillings
  } = useContext(FormNegativationContext)

  const { updateModal } = useContext(ChargeContext)

  const [, setLoading] = useState<boolean>(false)

  const { select, setSelect, resetSelectedFields } = useSelectRowTable({
    useSelected: false
  })

  const handleOpenModal = (uuid: string) => {
    updateModal((prevState) => ({
      ...prevState,
      chargeDetail: true,
      chargeUuid: uuid
    }))
  }

  const getBillings = async () => {
    try {
      setLoading(true)
      resetSelectedFields({
        selected: [],
        useSelect: true
      })
      const response = await BillingService.getBillingsNegativation(
        selectedCustomer?.value
      )
      updateBillings(response?.records)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (billingSelected) {
      const uuidBilling = billings.find(
        (billing: any) => billing?.uuid === billingSelected?.uuid
      )?.uuid

      setSelect((prevState) => ({
        ...prevState,
        selected: [uuidBilling]
      }))
    }
  }, [])

  useEffect(() => {
    if (billings)
      if (select?.selected.length > 1) {
        const uuid = select?.selected.shift()

        updateBillingSelected(
          billings?.find((billing: any) => billing.uuid === uuid)
        )

        setSelect((prevState) => ({
          selected: prevState.selected.filter(
            (uuidLocal) => uuidLocal !== uuid
          ),
          useSelect: true
        }))
      } else {
        const uuid = select?.selected[0]

        updateBillingSelected(
          billings.find((billing: any) => billing.uuid === uuid)
        )
      }
  }, [select?.selected])

  useEffect(() => {
    if (selectedCustomer && !billings) getBillings()
  }, [selectedCustomer])

  if (billings === null) {
    return null
  }

  if (billings !== null && billings.length === 0) {
    return <Empty />
  }

  return (
    <Box sx={{ overflow: 'auto', maxHeight: '26rem' }}>
      <TableComponent
        header={[
          { id: 'name', label: 'Cliente' },
          { id: 'due_date', label: 'Vencimento' },
          { id: 'status', label: 'Status' },
          { id: 'amount', label: 'Valor' },
          { id: 'actions', label: '' }
        ]}
        rows={convertToRows({
          records: billings,
          actions: { handleOpenModal }
        })}
        hiddePagination
        select={{
          useSelect: true,
          selected: select.selected,
          setSelected: setSelect
        }}
      />
    </Box>
  )
}

export default Table
