import * as yup from 'yup'

export const schemaInformationForm = yup.object({
  document: yup.string().required('CPF/CNPJ Obrigatório').nullable(true),
  isCPF: yup.boolean(),
  legal_name: yup.string().required('Razão social obrigatória').nullable(true),
  owner_document: yup.string().when('isCPF', {
    is: true,
    then: yup.string().nullable(true).notRequired(),
    otherwise: yup.string().required('CPF do sócio obrigatório')
  }),
  owner_name: yup.string().when('isCPF', {
    is: true,
    then: yup.string().nullable(true).notRequired(),
    otherwise: yup.string().required('Razão social obrigatória').nullable(true)
  }),
  owner_birthdate: yup.date().when('isCPF', {
    is: true,
    then: yup.date().nullable().notRequired(),
    otherwise: yup.date().required('Data de nascimento do sócio obrigatória.')
  }),
  birthdate: yup.date().nullable().required('Data obrigatório.'),
  revenue: yup
    .string()
    .max(10, 'Valor deve ser menor que R$100.000.000,00')
    .typeError('Faturamento obrigatório.')
    .required('Faturamento obrigatório.')
})

export type InformationFormSchema = yup.InferType<typeof schemaInformationForm>
