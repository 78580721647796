import moment from 'moment'

import { DiscountField } from './Discounts.interface'

export const filterDiscountsToDate = (array: DiscountField[], day: Date) => {
  return array.filter(
    ({ date }) => formatDate((!!date && date) || new Date()) === formatDate(day)
  )
}

export const formatDate = (date: Date) => {
  return moment(date).format('DD/MM/YYYY')
}

export const extractUuid = (discounts: DiscountField[], array: string[]) => {
  discounts.forEach(({ uuid }) => {
    array.push((!!uuid && uuid) || '')
  })
}

export const handleAddedError = (
  discount: DiscountField,
  name: string,
  message: string
) => {
  return {
    ...discount,
    errors: {
      ...discount.errors,
      [name]: {
        message: message,
        isError: true
      }
    }
  }
}

export const handleRemoveError = (discount: DiscountField, name: string) => {
  return {
    ...discount,
    errors: {
      ...discount.errors,
      [name]: undefined
    }
  }
}
