import { createContext, useRef, useState } from 'react'

import { ObjectLabelValue } from 'utils/types/object-label-value'

import {
  ConfirmationDialogParams,
  FormNegativationProps,
  FormNegativationState
} from './index.interface'

export const FormNegativationContext = createContext(
  {} as FormNegativationState
)

export function FormNegativationProvider({ children }: FormNegativationProps) {
  const [step, setStep] = useState(0)

  const [customers, setCustomers] = useState<Array<ObjectLabelValue>>([])
  const [billings, setBillings] = useState<any>(null)

  const [selectedCustomer, setSelectedCustomer] = useState<any>()
  const [billingSelected, setBillingSelected] = useState<any>()

  const [acceptTerm, setAcceptTerm] = useState<boolean>(false)
  const [confirmationDialog, setConfirmationDialog] =
    useState<ConfirmationDialogParams>({ open: false, uuid: '' })

  const [infosCustomer, setInfosCustomer] = useState<any>()

  const fromRef = useRef<HTMLFormElement>()

  return (
    <FormNegativationContext.Provider
      value={{
        step,
        updateStep: setStep,
        acceptTerm,
        updateAcceptTerm: setAcceptTerm,
        confirmationDialog,
        updateConfirmationDialog: setConfirmationDialog,
        customers,
        updateCustomers: setCustomers,
        selectedCustomer,
        updateSelectedCustomer: setSelectedCustomer,
        billingSelected,
        updateBillingSelected: setBillingSelected,
        fromRef,
        infosCustomer,
        updateInfosCustomer: setInfosCustomer,
        billings,
        updateBillings: setBillings
      }}
    >
      {children}
    </FormNegativationContext.Provider>
  )
}
