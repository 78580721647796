export default {
  canceled: {
    label: 'Cancelada'
  },
  confirmed: {
    label: 'Confirmada'
  },
  failed: {
    label: 'Cancelada'
  },
  pending: {
    label: 'Pendente'
  },
  succeeded: {
    label: 'Confirmada'
  },
  processing: {
    label: 'Pendente'
  }
} as const
