import { Box, useMediaQuery } from '@mui/material'

type SvgProps = {
  direction?: 'right' | 'left'
  curveColor: string
}

const Svg = ({ direction = 'left', curveColor }: SvgProps) => {
  const isMobile = useMediaQuery('(max-width: 770px)')

  return (
    <Box
      position="absolute"
      zIndex={0}
      {...(isMobile ? { bottom: 0 } : { top: 0 })}
      sx={{ rotate: isMobile ? '180deg' : '0deg' }}
      {...(direction === 'right'
        ? { right: isMobile ? 50 : 20 }
        : { left: 30 })}
    >
      {isMobile ? (
        <svg
          width="191"
          height="49"
          viewBox="0 0 191 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              d="M77.6357 48.2759C47.3336 54.5329 13.2527 18.6991 0 0H191C104.499 22.6546 115.513 40.4547 77.6357 48.2759Z"
              fill={curveColor}
            />
          </g>
        </svg>
      ) : (
        <svg
          width="356"
          height="91"
          viewBox="0 0 356 91"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              d="M144.5 89.5C88.1 101.1 24.6667 34.6667 0 0H355.5C194.5 42 215 75 144.5 89.5Z"
              fill={curveColor}
            />
          </g>
        </svg>
      )}
    </Box>
  )
}

export default Svg
