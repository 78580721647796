export interface CustomerFieldsParams {
  createdAt: string
  customerUuid: string
  fieldLabel: string
  fieldValue: string
  fieldType: string
  merchantUuid: string
  updatedAt: string
  uuid: string
}

export function constructorCustomerFields(data: any) {
  const customerFields: CustomerFieldsParams[] = data.map(
    (customerField: any) => {
      return {
        createdAt: customerField?.created_at,
        customerUuid: customerField?.customer_uuid,
        fieldLabel: customerField?.field_label,
        fieldValue: customerField?.field_value,
        fieldType: customerField?.field_type,
        merchantUuid: customerField?.merchant_uuid,
        updatedAt: customerField?.updated_at,
        uuid: customerField?.uuid
      }
    }
  )

  return customerFields
}
