import React, { memo, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { Button, Textfield } from '@clientbase/clientbase-library'
import { yupResolver } from '@hookform/resolvers/yup'
import { DataService } from 'api/DataService'

import InputController from 'components/InputController/InputController'
import { ModalChargeDetailContext } from 'components/Modal/ModalChargeDetail/context'

import { formatCents } from 'utils'
import { currency, onlyNumber } from 'utils/FormatMoney'
import { toastProps } from 'utils/types/toast-props'

import {
  FormReadjustmentSchemaParams,
  ReadjustmentProps
} from './Reverse.interface'
import { FormReadjustmentSchema } from './Reverse.schema'

const ReverseForm: React.FC<ReadjustmentProps> = ({
  uuid,
  handleClose,
  setReloadTable,
  setDialog
}) => {
  const { invoice } = useContext(ModalChargeDetailContext)

  const [loading, setLoading] = useState<boolean>(false)
  const { control, handleSubmit, watch } =
    useForm<FormReadjustmentSchemaParams>({
      resolver: yupResolver(FormReadjustmentSchema),
      defaultValues: {
        amount: onlyNumber(formatCents(invoice.amountPaid)) || '0'
      }
    })

  const handleOpenDialog = () => {
    const returnType =
      watch('amount') == onlyNumber(formatCents(invoice.amountPaid))
        ? 'estorno'
        : 'estorno parcial'

    setDialog({
      isOpenDialog: true,
      title: 'Estorno de cartão',
      description: `Confirma o ${returnType} de R$ ${currency(
        watch('amount') || '0,00'
      )}?`,
      icon: undefined,
      action: () => handleSubmit(onSubmit)()
    })
  }

  const onSubmit = async (dataForm: FormReadjustmentSchemaParams) => {
    setLoading(true)
    const id = toast.loading('Realizando estorno...')
    const data = {
      amount: currency(String(dataForm.amount))
        .replaceAll('.', '')
        .replaceAll(',', '.')
    }

    const response = await DataService({
      url: `v1/transactions/${uuid}/reverse`,
      type: 'POST',
      data
    })

    const errorMessage: string =
      response.error && (response.detail_error || response.message)

    toast.update(id, {
      render: response.error ? errorMessage : response.data.message,
      type: response.error ? 'error' : 'success',
      ...toastProps
    })

    !!handleClose && handleClose(response.status === 200)
    if (response.status === 200) {
      !!setReloadTable && setReloadTable((s) => !s)
    }
    setLoading(false)
  }

  const handleSubmitForm = (e: React.FormEvent) => {
    e.preventDefault()
    handleOpenDialog()
  }

  return (
    <form onSubmit={handleSubmitForm}>
      <InputController control={control} name="amount">
        <Textfield mask="money" autoFocus />
      </InputController>

      <Button
        variantButton="green"
        onClick={handleOpenDialog}
        sx={{ mt: '24px' }}
        fullWidth
        loading={loading}
        disabled={loading}
      >
        ESTORNAR
      </Button>
    </form>
  )
}

export default memo(ReverseForm)
