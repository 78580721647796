export interface CreditCardParams {
  cardBrand?: string
  createdAt: string
  customerId: number
  deleted: boolean
  expirationMonth: number
  expirationYear: number
  first4Digits: string
  holderName: string
  id: number
  isActive: boolean
  isValid: boolean
  isVerified: boolean
  last4Digits: string
  updatedAt: string
  uuid: string
}

export function constructorCreditCard(creditCard: any) {
  return {
    createdAt: creditCard?.created_at,
    customerId: creditCard?.customer_id,
    deleted: creditCard?.deleted,
    expirationMonth: creditCard?.expiration_month,
    expirationYear: creditCard?.expiration_year,
    first4Digits: creditCard?.first_4_digits,
    holderName: creditCard?.holder_name,
    id: creditCard?.id,
    isActive: creditCard?.is_active,
    isValid: creditCard?.is_valid,
    isVerified: creditCard?.is_verified,
    last4Digits: creditCard?.last_4_digits,
    updatedAt: creditCard?.updated_at,
    uuid: creditCard?.uuid,
    cardBrand: creditCard?.cardBrand
  } as CreditCardParams
}
