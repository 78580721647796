import { DataService } from 'api/DataService'
import {
  ConstructorReceivableParams,
  ReceivablesCalendar
} from 'models/Receivables'

export class ReceivablesService {
  public static async getCalendarDetails(): Promise<
    Array<ReceivablesCalendar>
  > {
    const response = await DataService({
      type: 'GET',
      url: `/v1/receivables/calendar`
    })

    if (response.status === 200) {
      return response?.data?.records
    }

    return [] as Array<ReceivablesCalendar>
  }
  public static async getCalendarByDateDetails(
    date: string
  ): Promise<{ details: Array<ConstructorReceivableParams>; balance: string }> {
    const response = await DataService({
      type: 'GET',
      url: `/v1/receivables/calendar/${date}`
    })

    if (response.status === 200) {
      return {
        details: response?.data?.records,
        balance: response?.data?.amount
      }
    }

    return { details: [], balance: '0.0' }
  }

  public static async antecipate(): Promise<{ message: string }> {
    const response = await DataService({
      type: 'POST',
      url: `/v1/receivables/antecipate`
    })

    if (response.status === 200) {
      return {
        message: response?.data?.message
      }
    }

    return { message: 'Ocorreu um erro.' }
  }
}
