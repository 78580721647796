import { CustomerService } from 'services/CustomerService'

export const getChargeEnum = (status: string) => {
  switch (status) {
    case 'boleto':
      return 'Boleto'
    case 'cash':
      return 'Dinheiro'
    case 'credit_card':
      return 'Cartão de crédito'
    case 'other':
      return 'Outros'
    case 'pix':
      return 'Pix'
    case 'transfer':
      return 'Transferência (TED/DOC)'
    case 'credit_card_recurring':
      return 'Cartão recorrente'
    case 'credit_card_installments':
      return 'Cartão parcelado'
    case 'boleto_pix':
      return 'Pix'
    case 'direct_payment':
      return 'Recebimento manual'
    default:
      window.newrelic.noticeError(
        new Error(`Erro ao mapear tradução de status de produtos: ${status}`)
      )
      return 'Status desconhecido'
  }
}

export const getAutoCompleteCustomer = async () => {
  const customersList = await CustomerService.getCustomerAutocomplete()
  const groupsList = await CustomerService.getCustomerGroupsAutocomplete()

  return {
    groupsList,
    customersList
  }
}
