import { Tab as CTab, styled, TabProps as TabProsMUI } from '@mui/material'

type TabProps = {
  fontSize?: number
} & TabProsMUI

const Tab = styled((props: TabProps) => (
  <CTab disableRipple={true} {...props} />
))(({ theme, fontSize = 15 }) => ({
  '&.Mui-focusVisible': {
    // backgroundColor: theme.palette.primary.main,
  },
  '&.Mui-selected': {
    color: theme.palette.neutral.main,
    fontWeight: 'bold'
  },
  color: theme.palette.neutral[200],
  fontSize: theme.typography.pxToRem(fontSize),
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  textTransform: 'none'
}))

export default Tab
