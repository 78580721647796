import { useEffect, useState } from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch
} from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  Button,
  Loader,
  Select,
  Textarea,
  Textfield
} from '@clientbase/clientbase-library'
import { Box, Grid, MenuItem, Typography } from '@mui/material'
import { DataService } from 'api/DataService'
import { DiscountParams } from 'models/Discounts'
import { RecurrencesParams } from 'models/Recurrences'
import BillingMethod from 'templates/Customers/Modals/BodyModalNewChargeOrRecurrence/Components/BillingMethod'
import OptionsAdditionals from 'templates/Customers/Modals/BodyModalNewChargeOrRecurrence/Components/OptionsAdditionals'
import PopoverNewVariant from 'templates/Customers/Modals/components/NewVariant'
import { v4 as uuidv4 } from 'uuid'

import IdentifyInternal from 'components/IdentifyInternal/IdentifyInternal'
import { IdentifyInternal as IIdentifyInternal } from 'components/IdentifyInternal/IdentifyInternal.interface'

import {
  currency,
  formatCents,
  formatCentsToSendToBackEnd,
  onlyNumber
} from 'utils'
import { toastProps } from 'utils/types/toast-props'

import { EditRecurrenceBodyModalForm } from '../../interface'
import { DiscountField } from './templates/Discounts.interface'
import { daysDueDate } from './templates/Discounts.utils'
import { expirationOptions } from './templates/ExpirationOptions.utils'

interface Props {
  handleSubmit: UseFormHandleSubmit<EditRecurrenceBodyModalForm>
  control: Control<EditRecurrenceBodyModalForm, any>
  watch: UseFormWatch<EditRecurrenceBodyModalForm>
  onClose: (get: boolean) => void
  uuid: string
  currentCycle: number
  paymentType: string[]
  discounts: DiscountParams[]
  setValue: UseFormSetValue<EditRecurrenceBodyModalForm>
  register: UseFormRegister<EditRecurrenceBodyModalForm>
  errors: FieldErrors<EditRecurrenceBodyModalForm>
  recurrence: RecurrencesParams
  firstDueDate?: string
}

function RenderEditChargeModal({
  handleSubmit,
  control,
  watch,
  uuid,
  onClose,
  currentCycle,
  setValue,
  register,
  discounts,
  errors,
  recurrence
}: Props) {
  const { payments, description } = watch()
  const [loading, setLoading] = useState(false)
  const [addFieldDiscount, setAddFieldDiscount] = useState<DiscountField[]>([])
  const [identifyInternal, setIdentifyInternal] = useState<IIdentifyInternal[]>(
    []
  )

  const [storedCycleValue, setStoredCycleValue] = useState(
    recurrence.totalCycles
  )
  const [isUnique, setIsUnique] = useState(false)

  useEffect(() => {
    if (discounts.length > 0) {
      const array: DiscountField[] = []
      discounts.forEach(({ amount, days }) => {
        const parseAmount = onlyNumber(formatCents(amount)) || 0
        array.push({
          uuid: uuidv4(),
          amount: parseAmount.toString(),
          days: days.toString(),
          errors: undefined
        })
      })
      setAddFieldDiscount(array)
    } else {
      setAddFieldDiscount([
        {
          days: undefined,
          amount: undefined,
          errors: undefined,
          uuid: uuidv4()
        }
      ])
    }

    if (recurrence?.metadata) {
      const metadata = [] as IIdentifyInternal[]

      Object.keys(recurrence?.metadata).map((key) => {
        metadata.push({
          name: key,
          value: recurrence?.metadata[key],
          uuid: uuidv4()
        })
      })
      setIdentifyInternal(metadata)
    }
  }, [])

  const handleSubmitForm: SubmitHandler<EditRecurrenceBodyModalForm> = async (
    dataForm
  ) => {
    setLoading(true)

    const idToast = toast.loading('Editando recorrência...')

    let isError = false

    if (dataForm.discountPolicy && addFieldDiscount.length > 0) {
      addFieldDiscount.forEach((current) => {
        if (current.errors?.amount?.message) {
          isError = true
        }
        if (current.errors?.days?.message) {
          isError = true
        }
      })
      setAddFieldDiscount((current) => {
        return current.map((obj) => {
          let messageDate = undefined
          let messageAmount = undefined
          if (!obj.errors?.days?.isError)
            if (obj.days === undefined) {
              isError = true
              messageDate = 'Campo obrigatório.'
            }
          if (!obj.errors?.amount?.isError)
            if (obj.amount === undefined || obj.amount === '') {
              isError = true
              messageAmount = 'Campo obrigatório.'
            }

          if (messageAmount || messageDate) {
            return {
              ...obj,
              errors: {
                amount: messageAmount
                  ? { message: messageAmount, isError: true }
                  : { ...obj?.errors?.amount },
                days: messageDate
                  ? { message: messageDate, isError: true }
                  : { ...obj?.errors?.days }
              }
            }
          }
          return { ...obj }
        })
      })
    }

    if (identifyInternal.length > 0) {
      identifyInternal.forEach((current) => {
        if (
          current.errors?.name?.isError ||
          current?.name === undefined ||
          current?.name === ''
        ) {
          isError = false
        }
        if (
          current.errors?.value?.isError ||
          current?.value === undefined ||
          current?.value === null
        ) {
          isError = false
        }
      })

      setIdentifyInternal((current) => {
        return current.map((obj) => {
          let messageName = undefined
          let messageValue = undefined
          if (!obj.errors?.name?.isError) {
            if (obj.name === undefined || obj.name === '') {
              messageName = 'Campo obrigatório.'
            }
          }
          if (!obj.errors?.value?.isError) {
            if (obj.value === undefined || obj.value === '') {
              messageValue = 'Campo obrigatório.'
            }
          }

          if (messageName || messageValue) {
            return {
              ...obj,
              errors: {
                name: messageName
                  ? { message: messageName, isError: true }
                  : { ...obj?.errors?.name },
                value: messageValue
                  ? { message: messageValue, isError: true }
                  : { ...obj?.errors?.value }
              }
            }
          }
          return { ...obj }
        })
      })
    }

    if (isError) {
      setLoading(false)
      return
    }

    const amountFormated = formatCentsToSendToBackEnd(String(dataForm.amount))

    const payments = dataForm?.payments?.join(';')

    const data = {
      ...(dataForm.credit_card_uuid && {
        credit_card_uuid: dataForm?.credit_card_uuid?.value
      }),
      amount: 0,
      description: dataForm?.description,
      discount_policy:
        dataForm.discountPolicy && addFieldDiscount.length > 0 ? 4 : 1,
      discounts_attributes: [] as {
        amount: number
        days: number
        policy: number
      }[],
      due_day: dataForm.dueDay,
      interest_policy: dataForm.interestPolicy ? 3 : 1,
      ...(dataForm.interestPolicy && {
        interest_fee: dataForm.interestFee,
        interest_fine: dataForm.interestFine
      }),
      payment_type: payments,
      total_cycles:
        dataForm.totalCycles === 13
          ? Number(dataForm?.totalCyclesCustom)
          : dataForm?.totalCycles,
      nfse_policy: !dataForm?.interestInvoice
        ? 'no_nfse'
        : dataForm?.issue_when,
      ...(dataForm?.interestInvoice && {
        nfse_scheduled_attributes: {
          amount_type: dataForm?.amount_type,
          ...(dataForm?.amount_type === 'amount_custom' &&
            dataForm?.amountCustom && {
              amount: currency(dataForm?.amountCustom)
                .replace('.', '')
                .replace(',', '.')
            }),
          description: dataForm?.descriptionInvoice,
          iss_retention: dataForm?.iss_retention === 'yes' ? true : false,
          nfse_issuer_uuid: dataForm?.nfse_issuer_uuid,
          nfse_issuer_service_uuid: dataForm?.service_list_code,
          nature_operation: Number(dataForm?.nature_operation)
        }
      }),
      limit_days_for_payment: dataForm.expirationField,
      metadata: {}
    }

    if (dataForm.discountPolicy) {
      addFieldDiscount.forEach(({ amount, days }, i) => {
        const amountBilled = watch(`amount` as any)
        const attributes = {
          amount:
            addFieldDiscount[i]?.type === '0'
              ? Number(
                  (
                    Number(
                      formatCentsToSendToBackEnd(onlyNumber(amountBilled))
                    ) *
                    (Number(addFieldDiscount[i]?.amount) / 100)
                  ).toFixed(2)
                )
              : Number(
                  currency((!!amount && amount) || '0')
                    .replace('.', '')
                    .replace(',', '.')
                ),
          days: Number(days),
          policy: dataForm.discountPolicy ? 2 : 1
        }
        data.discounts_attributes.push(attributes)
      })
    }

    if (identifyInternal.length > 0) {
      identifyInternal.map(({ name, value }) => {
        data.metadata = { ...data.metadata, [name as string]: value }
      })
    }
    const response = await DataService({
      data: {
        ...data,
        amount: Number(amountFormated)
      },
      type: 'PATCH',
      url: `/v3/recurrences/${uuid}`
    })

    const errorMessage =
      response.error && (response.detail_error || response.message)

    toast.update(idToast, {
      render: response.error ? errorMessage : response.data.message,
      type: response.error ? 'error' : 'success',
      ...toastProps
    })

    setLoading(false)
    onClose(true)
  }

  const marks = [
    {
      label: '0',
      value: 0
    }
  ]

  let index = 1
  let currentCycles = currentCycle === 0 ? 1 : currentCycle
  for (index; index <= 24; index++) {
    marks.push({ label: `${currentCycles}`, value: index })
    currentCycles++
  }

  if (loading) {
    return <Loader hasOverlay />
  }

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mb="12px"
      >
        <Typography variant="lgBold">Editar recorrência</Typography>
      </Box>

      <Grid container={true} spacing={2}>
        <Grid item={true} xs={4}>
          <Controller
            control={control}
            name="amount"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Textfield
                customStartAdornment={
                  <Typography mr="6px" variant="mdLight">
                    R$
                  </Typography>
                }
                helperText={error?.message}
                error={!!error?.message}
                label="Qual o valor a ser cobrado?"
                mask="money"
                onChange={onChange}
                startAdornment={true}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item={true} xs={4}>
          <Controller
            control={control}
            name="dueDay"
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Select
                  helperText={error?.message}
                  error={!!error?.message}
                  fullWidth={true}
                  label="Informe o dia de vencimento da recorrência:"
                  name="dueDay"
                  onChange={onChange}
                  value={value}
                >
                  {daysDueDate.map(({ label, value }, index) => (
                    <MenuItem key={index} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              )
            }}
          />
        </Grid>
        <Grid item={true} xs={4}>
          <Controller
            control={control}
            name="expirationField"
            render={({ fieldState: { error }, field: { onChange, value } }) => (
              <Select
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 400
                    }
                  }
                }}
                label="Expiração da recorrência"
                helperText={error?.message}
                error={!!error?.message}
                fullWidth
                name="limitDaysForPayment"
                value={value}
                displayEmpty
                onChange={onChange}
              >
                {expirationOptions.map((item) => (
                  <MenuItem key={item.label} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
      </Grid>

      <BillingMethod
        control={control}
        payments={payments}
        register={register}
        watch={watch}
        errors={errors}
        customerUuid={recurrence?.customerUuid}
        storedCycleValue={storedCycleValue}
        setStoredCycleValue={setStoredCycleValue}
        isUnique={isUnique}
        setIsUnique={setIsUnique}
      />

      <Box mt="18px">
        <Controller
          control={control}
          name="description"
          render={({ field: { onChange, value } }) => (
            <Textarea
              fullWidth={true}
              onChange={onChange}
              placeholder="Descrição"
              value={value}
              maxLength={2000}
            />
          )}
        />
        <PopoverNewVariant
          setDescription={(message) => setValue('description', message)}
          description={description}
        />
      </Box>

      <OptionsAdditionals
        addFieldDiscount={addFieldDiscount as any}
        control={control}
        setAddFieldDiscount={setAddFieldDiscount as any}
        watch={watch}
        setValue={setValue}
        recurrence={true}
      />

      <Box mt="18px" width="100%">
        <IdentifyInternal
          indetifyInternal={identifyInternal}
          setIdentifyInternal={setIdentifyInternal}
        />
      </Box>

      <Box mt="18px" width="100%">
        <Button
          onClick={handleSubmit(handleSubmitForm)}
          type="submit"
          variantButton="green"
          width="100%"
        >
          Salvar
        </Button>
      </Box>
    </Box>
  )
}

export default RenderEditChargeModal
