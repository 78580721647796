import axios from 'axios'
import { AxiosError } from 'axios'
import { GetServerSidePropsContext, PreviewData } from 'next'
import { ParsedUrlQuery } from 'querystring'

import { destroySession } from 'utils/DestroySession'

import { getAPIClient } from './axios'

export type DataServiceProps = {
  ctx?: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>
  type?: 'POST' | 'GET' | 'DELETE' | 'PATCH'
  data?: any
  url: string
}

export type Response<T = any> = {
  data: T
  error: boolean | string
  detail_error: string
  message: string
  status: number
}

export const DataService = async <T = any>({
  ctx = undefined,
  type = 'POST',
  data,
  url
}: DataServiceProps): Promise<Response<T> | any> => {
  const client = getAPIClient(ctx)

  try {
    const response = await client({
      data,
      method: type,
      url
    })

    if (response.data?.token && url === '/v1/sign_in') {
      client.defaults.headers.common.Authorization = `Bearer ${response.data.token}`
    }

    return response
  } catch (err) {
    const errors = err as Error | AxiosError
    if (axios.isAxiosError(errors) && errors.response) {
      if (errors.response.status === 401) {
        destroySession(ctx)
        if (!ctx && url !== '/v1/sign_in') {
          window.location.replace('/')
        }
      }

      return {
        data: { records: {}, statistics: {} },
        ...(!!errors.response?.data?.detail_error && {
          detail_error:
            typeof errors.response?.data?.detail_error === 'string'
              ? errors.response?.data?.detail_error
              : errors.response?.data?.detail_error[0]
        }),
        error: errors.response.data?.error,
        message: errors.response.data?.message,
        status: errors.response.status
      }
    }
  }
}
