import { MenuItem as MenuItemMUI, styled } from '@mui/material'

import { theme } from 'styles/theme'

export const Section = styled('div')(() => ({
  marginTop: 15,
  marginBottom: 2
}))

export const SessionWrapper = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  gap: '12px',
  justifyItems: 'space-between',
  marginBottom: '52px'
}))

export const MenuItemsWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  minWidth: 200
}))

export const MenuItem = styled(MenuItemMUI)(() => ({
  display: 'flex',
  fontSize: 14,
  color: '#646464',
  gap: '8px'
}))

export const ContentWrapper = styled('div')(() => ({
  minWidth: '300px'
}))

export const ChargeSession = styled('div')(() => ({
  width: 'auto',
  marginTop: 12,
  padding: 12,
  border: '1px solid #EDEBEB',
  borderRadius: '5px'
}))

export const FixedWrapper = styled('div')(() => ({
  display: 'flex',
  flex: 'wrap',
  justifyContent: 'end',
  alignItems: 'top',
  border: '1px solid white',
  position: 'fixed',
  bottom: '0%',
  right: '0%',
  padding: '0 30px',
  backgroundColor: 'white',
  width: '100%',
  height: '60px',
  [theme.breakpoints.down(508)]: {
    position: 'relative',
    padding: '0px',
    left: '2.5%',
    marginBottom: '15px'
  }
}))

export const FixedWrapperChildren = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  justifyContent: 'flex-end',
  borderTop: '2px solid #F7FAF7',
  [theme.breakpoints.down(450)]: {
    justifyContent: 'center',
    paddingTop: '0px'
  }
}))

export const ButtonWrapper = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px',
  alignContent: 'center',
  [theme.breakpoints.down(450)]: {
    justifyContent: 'center'
  }
}))

export const Separator = styled('div')(() => ({
  width: '1fr',
  flexGrow: '1',
  borderTop: '0.5px dashed #CBCBCB',
  alignSelf: 'center',
  marginTop: '2px'
}))

export const Divider = styled('div')(() => ({
  borderRight: '1px solid #CBCBCB'
}))

export const ContainerIcon = styled('div')(() => ({
  width: '35px',
  height: '35px',
  background: '#F8F8F8',
  borderRadius: '8px',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

export const ButtonRecurrenceView = styled('button')(({ theme }) => ({
  all: 'inherit',
  borderRadius: 999,
  cursor: 'pointer',
  outline: '1px solid transparent',
  ['&:focus-within, &:hover']: {
    outlineColor: theme.palette.neutral[100]
  }
}))

export const Link = styled('a')(() => ({
  margin: 0,
  padding: 0,
  fontWeight: 600,
  color: '#231F20',
  cursor: 'pointer'
}))
