import { AddressesParams, constructorAddresses } from 'models/Addresses'
import {
  CustomerFieldsParams,
  constructorCustomerFields
} from 'models/ExtraFields'
import { Group, GroupParams, constructorGroup } from 'models/Overview/Group'

export interface CustomerParams {
  status?: string | null
  name?: string | null
  nickname?: string | null
  document?: string | null
  email?: string | null
  phone?: string | null
  createdAt?: string | null
  updatedAt?: string | null
  uuid?: string | null
  merchantUuid?: string | null
  address?: AddressesParams | null
  logo?: string
  customerGroups?: Group[]
  customerFields?: CustomerFieldsParams[]
}

export function constructorCustomer(data: any) {
  const customer: CustomerParams = {
    address: data?.address ? constructorAddresses(data.address) : null,
    customerFields: data?.customer_fields
      ? constructorCustomerFields(data?.customer_fields)
      : [],
    createdAt: data?.created_at,
    document: data?.document,
    email: data?.email,
    merchantUuid: data?.merchant_uuid ? data?.merchant_uuid : null,
    name: data?.name,
    nickname: data?.nickname,
    phone: data?.phone,
    status: data?.status,
    updatedAt: data?.updated_at,
    uuid: data?.uuid,
    logo: data?.logo_url ? data?.logo_url : '',
    customerGroups: data?.customer_groups
      ? data?.customer_groups?.map((item: GroupParams) =>
          constructorGroup(item)
        )
      : []
  }

  return customer
}

export interface CustomerAutocompleteParams {
  label?: string
  value?: string
}
