import BoletoIcon from 'components/Icons/Boleto'
import CreditCardIcon from 'components/Icons/CreditCard'
import CreditCardRecurrencing from 'components/Icons/CreditCardRecurrencing'
import PixIcon from 'components/Icons/Pix'

export const paymentMethods = [
  {
    name: 'pix',
    label: 'Pix',
    icon: <PixIcon />
  },
  {
    name: 'boleto',
    label: 'Boleto',
    icon: <BoletoIcon />
  },
  {
    name: 'credit_card',
    label: 'Crédito',
    icon: <CreditCardIcon />
  },
  {
    name: 'credit_card_recurring',
    label: 'Crédito recorrente',
    icon: <CreditCardRecurrencing />
  }
]

export const icons = [
  'acUnit',
  'accountBalance',
  'analytics',
  'beachAccess',
  'bookmark',
  'buildCircle',
  'cardGiftcard',
  'castle',
  'cellTower',
  'chair',
  'desktopMac',
  'favorite',
  'public'
]
