import moment from 'moment'

type CreditCardChargeItem = {
  chargedAt: string
  status: string
  description: string
}

function mapperStatus(status: string) {
  switch (status) {
    case 'authorized':
      return 'Autorizado'
    case 'captured':
      return 'Capturado'
    case 'unathorized':
      return 'Não autorizado'
    case 'refunded':
      return 'Estornado'
    default:
      return 'Não identificado'
  }
}

export type CreditCardCharge = CreditCardChargeItem[]

const creditCardChargesMapper = (creditCardCharges: CreditCardCharge) => {
  return creditCardCharges.map((item) => ({
    chargedAt: moment(item?.chargedAt).format('DD/MM/YYYY HH:mm:ss'),
    status: mapperStatus(item.status),
    description: item?.description
  }))
}

export default creditCardChargesMapper
