import React from 'react'

import { Modal } from '@clientbase/clientbase-library'

import { ModalChargeDetailProvider } from './context'
import ModalChargeDetailBody from './ModalChargeDetailBody'

type ModalChargeDetailProps = {
  uuid: string
  handleCloseChargeDetail: (reloadTable?: boolean) => void
  isOpenChargeDetail: boolean

  zIndex?: number
  disablePortal?: boolean
  setReloadTable?: React.Dispatch<React.SetStateAction<boolean>>
}

const ModalChargeDetail = ({
  uuid,
  handleCloseChargeDetail,
  isOpenChargeDetail,
  setReloadTable
}: ModalChargeDetailProps) => {
  return (
    <Modal
      disablePortal={false}
      handleClose={() => handleCloseChargeDetail(false)}
      open={isOpenChargeDetail}
      maxWidth="1150px"
      width="100%"
      height="calc(100%-200px)"
    >
      <ModalChargeDetailProvider>
        <ModalChargeDetailBody
          setReloadTable={setReloadTable}
          onClose={(v) => handleCloseChargeDetail(v)}
          uuid={uuid}
        />
      </ModalChargeDetailProvider>
    </Modal>
  )
}

export default ModalChargeDetail
