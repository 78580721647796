import Actions from './components/Actions/Actions'
import Status from './components/Status'
import { Wrapper } from './Header.styles'

const Header = () => {
  return (
    <Wrapper>
      <Status />
      <Actions />
    </Wrapper>
  )
}

export default Header
