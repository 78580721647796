import React from 'react'
import { default as PhoneInputt } from 'react-phone-input-2'

import { Typography } from '@mui/material'

import 'react-phone-input-2/lib/high-res.css'
import { PhoneinputProps } from './PhoneInput.interface'
import { Container } from './PhoneInput.styles'

export const PhoneInput: React.FC<PhoneinputProps> = ({
  label,
  errorLabel,
  ...rest
}) => {
  return (
    <Container>
      <Typography id="label" mb="4px" variant="mdLight">
        {label}
      </Typography>
      <PhoneInputt
        {...rest}
        enableSearch
        enableTerritories
        inputStyle={{
          height: '42px',
          width: '100%',
          fontFamily: "'Inter', Roboto, sans-serif",
          color: !rest?.disabled
            ? 'rgba(0, 0, 0, 0.87)'
            : 'rgba(0, 0, 0, 0.38)',
          fontSize: '1rem',
          lineHeight: '1.4375em'
        }}
      />
      <Typography color="#990017" mt="4px" variant="smBold">
        {errorLabel}
      </Typography>
    </Container>
  )
}
