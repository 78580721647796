import moment, { Moment } from 'moment'

function formatDate(date: string) {
  if (!date) {
    return null
  }
  return date?.substring(0, 10)?.split('-')?.reverse()?.join('/')
}

function stringToDate(date: string) {
  if (!date) return
  const [year, month, day] = date.split('-')
  return new Date(Number(year), Number(month) - 1, Number(day))
}

const formatMomentDate = (date: Moment) => {
  return moment(date).format('YYYY-MM-DD')
}

type DateFormatParams = {
  options?: Intl.DateTimeFormatOptions
  date: Date | null
}

const dateFormat = ({
  options = { month: 'long', year: 'numeric' },
  date
}: DateFormatParams) => {
  if (!date) return ''
  return Intl.DateTimeFormat('pt-BR', options).format(moment(date).toDate())
}

export { formatDate, stringToDate, formatMomentDate, dateFormat }
