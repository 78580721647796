import BetweenDateFormat from './between-date-to-query-string'
import Debouncer from './Debouncer'
import formatCents, { formatCentsToSendToBackEnd } from './format-cents'
import formatCep from './FormatCep'
import formatCpfCnpj from './FormatCpfCnpj'
import { currency, formatMoney, onlyNumber } from './FormatMoney'
import formatMonth from './FormatMonth'
import formatPercent from './FormatPercent'
import formatPhone from './FormatPhone'
import formatStatus from './FormatStatus'
import objectToQueryString from './object-to-query-string'
import UFOptions from './UF'

function isEmpty(obj: any) {
  return Object.keys(obj).length === 0
}

export {
  UFOptions,
  Debouncer,
  formatCep,
  objectToQueryString,
  formatCpfCnpj,
  formatMoney,
  currency,
  onlyNumber,
  formatMonth,
  formatPercent,
  formatPhone,
  formatCentsToSendToBackEnd,
  formatCents,
  formatStatus,
  BetweenDateFormat,
  isEmpty
}
