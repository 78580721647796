import { AccordionSummary as CAccordionSummary } from '@clientbase/clientbase-library'
import { Grid as Grids, styled } from '@mui/material'

export const Grid = styled(Grids)(() => ({}))

export const Col = styled(Grids)(() => ({}))

export const Container = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  padding: '6px 0',

  width: '100%',

  border: '1px dashed #E3E6E3',
  borderRadius: '8px',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '#fbfbfb'
  }
}))

export const AccordionSummary = styled(CAccordionSummary)(() => ({
  '.MuiAccordionSummary-content': { margin: '16px 0px' },
  '.MuiAccordionSummary-expandIconWrapper': {
    position: 'absolute',
    right: '14px',
    transform: 'rotate(0deg)'
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)'
  }
}))
