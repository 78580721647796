import dayjs from 'dayjs'

const getCurrentMonth = () => {
  const month = dayjs(new Date())
  const dateFormat = 'YYYY-MM-DD'

  const startOfMonth = dayjs(month.startOf('month')).format(dateFormat)
  const endOfMonth = dayjs(month.endOf('month')).format(dateFormat)

  return [startOfMonth.toString(), endOfMonth.toString()]
}

export default getCurrentMonth
