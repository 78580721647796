import { Box, css, styled } from '@mui/material'

import { theme } from 'styles/theme'

import { DropContainerProps, UploadMessageProps } from '.'

const dragActive = css`
  border-color: #78e5d5;
`

const dragReject = css`
  border-color: #e57878;
`

export const DropContainer = styled(Box)<DropContainerProps>`
  border: ${(props) =>
    props?.sucess
      ? `1px solid ${theme.palette.success[300]}`
      : '1px dashed #ddd'};
  border-radius: 4px;
  cursor: pointer;
  transition: height 0.2s ease;
  ${(props) => props.isDragActive && dragActive};
  ${(props) => props.isDragReject && dragReject};
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  height: 100%;
`

const messageColors = {
  default: '#999',
  error: '#e57878',
  success: '#78e5d5'
}

export const UploadMessage = styled('p')<UploadMessageProps>`
  display: flex;
  color: ${(props) => messageColors[props.type || 'default']};
  justify-content: center;
  align-items: center;
  padding: 15px 0;
`
