import { useEffect, useState } from 'react'

import { Icon, Loader } from '@clientbase/clientbase-library'
import { Modal, Skeleton, Typography } from '@mui/material'
import {
  ConstructorReceivableParams,
  ReceivablesParams
} from 'models/Receivables'
import { ReceivablesService } from 'services/ReceivablesService'

import { Table } from 'components/Table'

import { formatMoney } from 'utils'

import ModalChargeDetail from '../ModalChargeDetail'
import * as S from './ModalBalaceDetail.styles'
import { convertToRows } from './ModalBalaceDetail.utils'
export type Record = {
  receivable?: ReceivablesParams
}

export type ModalBalanceProps = {
  isOpen: boolean
  handleClose: () => void
  date: string
}

export const ModalBalanceDetail = ({
  isOpen,
  handleClose,
  date
}: ModalBalanceProps) => {
  const [tableFetchLoading, setTableFetchLoading] = useState(false)
  const [records, setRecords] = useState<ConstructorReceivableParams[]>([])
  const [balance, setBalance] = useState<string>('0.0')

  const [modal, setModal] = useState({
    isOpenDetailInvoice: false,
    uuid: ''
  })

  const handleCloseModalBalance = () => {
    handleClose()
  }

  const handleOpenDetailInvoice = (uuid: string) => {
    setModal((prevState) => ({
      ...prevState,
      isOpenDetailInvoice: true,
      uuid
    }))
  }

  const handleCloseDetailInvoice = () => {
    setModal((prevState) => ({
      ...prevState,
      isOpenDetailInvoice: false,
      uuid: ''
    }))
  }
  useEffect(() => {
    if (!isOpen) return

    const getData = async () => {
      setTableFetchLoading(true)
      const { balance: BalanceResponse, details } =
        await ReceivablesService.getCalendarByDateDetails(date)

      setRecords(details)
      setBalance(BalanceResponse)
      setTableFetchLoading(false)
    }

    getData()
  }, [isOpen])

  if (records.length === 0 && isOpen === true) {
    return (
      <S.Overlay>
        <Loader />
      </S.Overlay>
    )
  }

  return (
    <>
      <Modal
        aria-hidden={!isOpen}
        onClose={handleCloseModalBalance}
        open={isOpen}
      >
        <S.Content size="extraLarge">
          <S.CloseButton onClick={handleCloseModalBalance}>
            <Icon icon="close" />
          </S.CloseButton>

          <S.Header>
            <S.ContainerDetailsBalance>
              <S.Heading variant="lgLight">
                <Icon icon="accountBalance" />
                Detalhes do saldo a receber
              </S.Heading>
              <Typography variant="mdBold">
                Saldo: {formatMoney(Number(balance))}
              </Typography>
            </S.ContainerDetailsBalance>
          </S.Header>

          {!!records.length && tableFetchLoading ? (
            <Skeleton height="500px" variant="rectangular" width="100%" />
          ) : (
            <S.TableWrapper>
              <Table
                header={[
                  { id: 'name', label: 'Cliente' },
                  { id: 'dueDate', label: 'Data de Vencimento' },
                  { id: 'paidDate', label: 'Data Pagamento' },
                  { id: 'amountPaid', label: 'Valor Pago' },
                  { id: 'fee', label: 'Tarifa bancária' },
                  { id: 'amountLiquid', label: 'Valor Líquido' },
                  { id: 'paymentType', label: 'Método de Pagamento' },
                  { id: 'actions', label: '' }
                ]}
                rows={convertToRows({ records, handleOpenDetailInvoice })}
                rowAction={handleOpenDetailInvoice}
                select={{ useSelect: false }}
                hiddePagination={true}
              />
            </S.TableWrapper>
          )}
        </S.Content>
      </Modal>

      <ModalChargeDetail
        handleCloseChargeDetail={handleCloseDetailInvoice}
        isOpenChargeDetail={modal.isOpenDetailInvoice}
        uuid={modal.uuid}
        disablePortal={false}
      />
    </>
  )
}
