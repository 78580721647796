export const NegativateStatus = Object.freeze({
  analysing: 'Em análise',
  processing: 'Processando',
  waiting_return: 'Aguardando retorno',
  negativated: 'Negativado',
  recovering: 'Recuperando',
  recovered: 'Recuperado',
  canceling: 'Cancelando',
  cancelled: 'Cancelado',
  failed: 'Falha'
})

export const NegativateEnum = Object.freeze({
  analysing: 1, // Em Análise
  processing: 2, // Processando
  waiting_return: 3, // Aguardando retorno
  negativated: 4, // Negativado
  recovering: 5, // Recuperando
  recovered: 6, // Recuperado
  canceling: 7, // Cancelando
  cancelled: 8, //Cancelado
  failed: 9 // Falha
})
