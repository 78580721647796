import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { Button, Icon, Icons, Select } from '@clientbase/clientbase-library'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, MenuItem, Radio, Typography } from '@mui/material'
import { DataService } from 'api/DataService'
import { Products, constructorProducts } from 'models/Plans'

import Dialog from 'components/v2/Dialog'

import { currency } from 'utils'
import { toastProps } from 'utils/types/toast-props'

import {
  DialogConfirm,
  FormLinkPlanParams,
  FormLinkPlansSchema
} from './FormLinkPlan.interface'
import { FormLinkPlanSchema } from './FormLinkPlan.schema'
import { Wrapper } from './FormLinkPlan.styles'

const FormLinkPlan: React.FC<FormLinkPlanParams> = ({
  recurrence,
  handleRefreshPlan,
  setIsOpenDialog
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [confirm, setConfirm] = useState<DialogConfirm>({
    open: false,
    dataForm: undefined
  })
  const [plans, setPlans] = useState<Products[]>([])
  const { control, watch, handleSubmit } = useForm<FormLinkPlansSchema>({
    resolver: yupResolver(FormLinkPlanSchema)
  })

  const getPlans = async () => {
    const response = await DataService({
      url: `/v1/prodcuts?filter_by=amount=${Number(recurrence?.amount)}`,
      type: 'GET'
    })

    setPlans(constructorProducts(response?.data?.records))
  }

  useEffect(() => {
    getPlans()
  }, [])

  const openDialog = (dataForm: FormLinkPlansSchema) => {
    setConfirm({ open: true, dataForm: dataForm })
  }

  const onSubmit = async () => {
    setLoading(true)
    const id = toast.loading('Vinculando a recorrência ao produto...')
    const data = {
      product_uuid: confirm?.dataForm?.uuid
    }

    const response = await DataService({
      url: `/v3/recurrences/${recurrence?.uuid}/link_plan`,
      data,
      type: 'PATCH'
    })

    const errorMessage: string =
      response.error && (response.detail_error || response.message)

    toast.update(id, {
      render: response.error ? errorMessage : response.data.message,
      type: response.error ? 'error' : 'success',
      ...toastProps
    })

    if (response.status === 200) {
      !!handleRefreshPlan && handleRefreshPlan()
      !!setIsOpenDialog && setIsOpenDialog()
    }

    setLoading(false)
  }

  const handleCloseDialog = () => {
    !!setIsOpenDialog && setIsOpenDialog()
  }

  const namePlan =
    plans.filter((plan) => plan.uuid === watch('uuid')).length > 0
      ? plans.filter((plan) => plan.uuid === watch('uuid'))[0].name
      : ''

  return (
    <Wrapper>
      <form className="form" onSubmit={handleSubmit(openDialog)}>
        <Controller
          control={control}
          name="uuid"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Select
                helperText={error?.message}
                error={!!error?.message}
                fullWidth={true}
                onChange={onChange}
                sx={{ height: '42px', width: '100%' }}
                value={value}
                renderValue={(selected) => {
                  const uuid = selected as string
                  const name = plans.filter((plan) => plan.uuid === uuid)

                  return name[0].name
                }}
              >
                {plans.map(
                  (
                    { amount, name, dueDay, uuid, icon, totalCycles },
                    index
                  ) => (
                    <MenuItem key={index} value={uuid} sx={{ width: '100%' }}>
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                        p="12px"
                        alignItems="center"
                      >
                        <Box display="flex" alignItems="center" gap="12px">
                          <Icon icon={icon as Icons} />
                          <Box>
                            <Typography variant="smBold">{name}</Typography>
                            <Box display="flex" gap="4px">
                              <Typography variant="smLight" color="#828282">
                                Valor: <strong>R$ {currency(amount)}</strong>
                              </Typography>
                              {totalCycles && (
                                <Typography variant="smLight" color="#828282">
                                  Número de parcelas:{' '}
                                  <strong>{totalCycles}x</strong>
                                </Typography>
                              )}
                              {dueDay && (
                                <Typography variant="smLight" color="#828282">
                                  Dia do vencimento: <strong>{dueDay}</strong>
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        </Box>
                        <Radio checked={uuid === value} />
                      </Box>
                    </MenuItem>
                  )
                )}
              </Select>
            )
          }}
        />
        <Box display="flex" gap="8px">
          <Button fullWidth onClick={handleCloseDialog}>
            Cancelar
          </Button>
          <Button fullWidth type="submit">
            Vincular
          </Button>
        </Box>
      </form>

      <Dialog
        icon="addCircle"
        title="Vincular Produto"
        description={`Confirma vincular a recorrência ao produto: ${namePlan}`}
        setIsOpenDialog={() => setConfirm({ open: false, dataForm: undefined })}
        isOpenDialog={confirm.open}
        maxWidth="sm"
        fullWidth
      >
        <Box display="flex" gap="8px">
          <Button
            onClick={() => setConfirm({ open: false, dataForm: undefined })}
            fullWidth
            disabled={loading}
          >
            Não
          </Button>
          <Button
            onClick={onSubmit}
            fullWidth
            disabled={loading}
            loading={loading}
          >
            Sim
          </Button>
        </Box>
      </Dialog>
    </Wrapper>
  )
}

export default FormLinkPlan
