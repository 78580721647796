import Image from 'next/image'

import * as S from './EmptyReport.styles'

const EmptyReport = () => (
  <S.Wrapper>
    <S.Header>
      <S.Heading>Ops, não encontramos nenhum resultado.</S.Heading>
      <S.Subtitle>Você já movimentou sua Base de Clientes? 🤔</S.Subtitle>
    </S.Header>

    <S.ImageWrapper>
      <Image
        src="/images/empty/report.svg"
        alt="pessoa lamentando pagina não encontrada"
        layout="fill"
      />
    </S.ImageWrapper>

    <S.Description>
      Acompanhe aqui os relatórios sobre pendências e pagamentos de suas
      cobranças.
    </S.Description>
  </S.Wrapper>
)

export default EmptyReport
