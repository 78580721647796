import { Icon as IconUnstyled } from '@clientbase/clientbase-library'
import ContentCopyIconUnstyled from '@mui/icons-material/ContentCopy'
import {
  Box,
  IconButton as IconButtonUnstyled,
  MenuItem as MenuItemUnstyled,
  Menu as MenuUnstyled,
  Tooltip as TooltipUnstyled,
  styled
} from '@mui/material'

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '70%',
  justifyContent: 'flex-end',
  gap: '8px',
  [theme.breakpoints.down(450)]: {
    width: '100%',
    gridRow: '2',
    gridColumn: 'span 2'
  }
}))

export const Tooltip = styled(TooltipUnstyled)(({ theme }) => ({
  [theme.breakpoints.down(450)]: {
    gridRow: '0',
    gridColumn: '1'
  }
}))

export const IconButton = styled(IconButtonUnstyled)(({ theme }) => ({
  color: theme.palette.neutral[100],
  [theme.breakpoints.down(450)]: {
    gridRow: 0,
    gridColumn: 2,
    justifySelf: 'end'
  }
}))

export const Icon = styled(IconUnstyled)(({ theme }) => ({
  color: theme.palette.neutral[100],
  cursor: 'pointer'
}))

export const Menu = styled(MenuUnstyled)(() => ({
  maxWidth: '950px',
  minWidth: '300px',
  mt: 5
}))

export const MenuItemsWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  minWidth: 200
}))

export const NfButtonContent = styled('div')(() => ({
  marginTop: '8px',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center'
}))
export const NfIconWrapper = styled('div')(() => ({
  marginTop: '17px',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '20px',
  marginRight: '8px'
}))

export const MenuItem = styled(MenuItemUnstyled)(() => ({
  display: 'flex',
  fontSize: 14,
  color: '#646464',
  gap: '8px'
}))

export const ContentCopyIcon = styled(ContentCopyIconUnstyled)(() => ({
  color: '#777877',
  cursor: 'pointer',
  fill: 'black'
}))
