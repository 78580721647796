import { Box, IconButton, keyframes, styled } from '@mui/material'

import { theme } from 'styles/theme'

interface ResultsProps {
  hasResults: boolean
}

interface TypeProps {
  color: string
}

const spinEff = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  left: '50%',
  position: 'fixed',
  top: '35%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  height: 'auto',
  ':focus': {
    boxShadow: '0 0 0 0',
    border: '0 none',
    outline: '0'
  },
  [theme.breakpoints.down('lg')]: {
    width: '60%'
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    top: 0,
    left: 0,
    transform: 'translate(0)',
    backgroundColor: theme.palette.neutral[800]
  }
}))

export const Content = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  fontSize: '16px'
}))

export const SearchBox = styled(Box)<ResultsProps>(({ hasResults }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  backgroundColor: 'rgb(255, 255, 255)',
  boxShadow:
    'rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px',
  borderRadius: hasResults ? '8px 8px 0px 0px' : '8px',
  boxSizing: 'border-box',
  fontSize: '16px',
  padding: '20px 24px',
  '.spinner': {
    animation: `${spinEff} infinite 5s linear`
  },
  [theme.breakpoints.down('sm')]: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  }
}))

export const ResultsList = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  backgroundColor: 'rgb(255, 255, 255)',
  borderRadius: '0px 0px 8px 8px',
  fontSize: '16px',
  padding: '16px 20px',
  boxSizing: 'border-box',
  height: '40vh',
  overflowY: 'scroll',
  [theme.breakpoints.down('sm')]: {
    height: '100vh',
    backgroundColor: 'transparent'
  }
}))

export const CloseButton = styled(IconButton)(() => ({
  width: '30px',
  height: '30px',
  position: 'absolute',
  right: -10,
  top: -10,
  backgroundColor: '#FFF',
  border: `solid 1px ${theme.palette.neutral[700]}`,
  ':hover': {
    backgroundColor: theme.palette.neutral[600]
  },
  [theme.breakpoints.down('sm')]: {
    position: 'inherit'
  }
}))

export const Suggestions = styled('span')(() => ({
  color: theme.palette.neutral[400],
  fontSize: '12px',
  marginBottom: 10
}))

export const Result = styled('a')(() => ({
  textDecoration: 'none',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: '4px',
  ':hover': {
    backgroundColor: theme.palette.neutral[800],
    boxShadow: `0 0 0 8px ${theme.palette.neutral[800]}`
  }
}))

export const ResultTitle = styled('span')(() => ({
  color: theme.palette.neutral[100],
  fontWeight: 600,
  fontSize: '15px'
}))

export const ResultSubtitle = styled('span')(() => ({
  color: theme.palette.neutral[400],
  fontWeight: 400,
  fontSize: '12px'
}))

export const ResultDetails = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 5
}))

export const ResultType = styled('div')(({ color }: TypeProps) => ({
  color: theme.palette.neutral[400],
  fontWeight: 400,
  fontSize: '10px',
  backgroundColor: color,
  padding: 6,
  borderRadius: '20px'
}))

export const ResultsContainer = styled('div')(
  ({ hasResults }: ResultsProps) => ({
    visibility: hasResults ? 'visible' : 'hidden',
    position: 'relative',
    top: -4
  })
)

export const MobileSearchBox = styled('div')(() => ({
  backgroundColor: '#FFF',
  width: '100%',
  display: 'flex',
  padding: '12px 20px',
  borderRadius: '8px',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)'
}))

export const TabButtons = styled(Box)(() => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 15,
  marginBottom: '10px'
}))
