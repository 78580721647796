import { Grid, styled } from '@mui/material'

export const Wrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '50px 96px',
  backgroundColor: '#F8F8F8',
  [theme.breakpoints.down('md')]: {
    padding: '25px 48px'
  }
}))
