import React, { useContext } from 'react'

import { Icon } from '@clientbase/clientbase-library'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from '@mui/material'

import { theme } from 'styles/theme'

import { ModalChargeDetailContext } from '../../context'
import { cardTittle } from '../../ModalChargeDetail.utils'
import { S } from '../BillingDetails'

const InternalIdentifier: React.FC = () => {
  const { invoice } = useContext(ModalChargeDetailContext)

  if (!invoice?.metadata) {
    return null
  }

  return (
    <Accordion
      sx={{
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        '&:before': {
          display: 'none'
        }
      }}
    >
      <AccordionSummary
        expandIcon={<Icon icon="keyboardArrowDown" />}
        aria-controls="payerDetails-content"
        id="payerDetails"
      >
        {cardTittle(
          'Identificador interno',
          'bookmark',
          `${theme.palette.primary[200]}`
        )}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
          borderTop: '1px solid #E3E6E3'
        }}
      >
        {Object.keys(invoice?.metadata).map((field) => (
          <>
            <div>
              <Box
                display="flex"
                gap={1.25}
                alignItems="end"
                margin="10px 0px 10px 0px"
              >
                <Typography variant="mdLight" color="#777777">
                  Nome do campo:
                </Typography>
                <S.Separator />
                <Typography variant="mdBold">{field}</Typography>
              </Box>
            </div>
            <div>
              <Box
                display="flex"
                gap={1.25}
                alignItems="end"
                margin="10px 0px 10px 0px"
              >
                <Typography variant="mdLight" color="#777777">
                  Informação:
                </Typography>
                <S.Separator />
                <Typography variant="mdBold">
                  {invoice?.metadata[field]}
                </Typography>
              </Box>
            </div>
          </>
        ))}
      </AccordionDetails>
    </Accordion>
  )
}

export default InternalIdentifier
