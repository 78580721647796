import * as React from 'react'
import { SVGProps } from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}
const CreditCardRecurrencing = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#494949"
      d="M2.007 12.747c.48.007.961.007 1.442.021.149.007.155-.098.176-.196.27-1.362.744-2.64 1.462-3.814 1.983-3.234 4.799-5.163 8.475-5.659 5.395-.72 10.512 2.53 12.38 7.797.075.202.054.28-.155.335-.325.09-.643.21-.962.335-.182.077-.243.028-.318-.16-.995-2.718-2.762-4.709-5.314-5.917-5.726-2.725-12.53.684-14.025 7.013-.047.196-.027.273.196.266.413-.02.82-.007 1.293-.007-.799 1.006-1.557 1.963-2.328 2.927a620.2 620.2 0 0 0-2.227-2.794c-.028-.035-.041-.098-.102-.098.007-.014.007-.035.007-.049ZM28 17.205a.046.046 0 0 0 0 .042c-.446 0-.893.006-1.34-.014-.183-.008-.244.062-.277.237a11.402 11.402 0 0 1-1.564 3.968c-1.99 3.123-4.772 4.981-8.373 5.463-5.687.762-11.075-2.941-12.625-8.628-.054-.202-.027-.272.176-.314.339-.07.67-.168 1.002-.272.17-.05.223 0 .27.174 1.185 3.87 3.676 6.357 7.5 7.329 5.294 1.34 10.852-2.229 12.077-7.699.04-.189.027-.258-.183-.252-.42.021-.84.008-1.313.008.792-1 1.55-1.95 2.329-2.928.5.63.981 1.237 1.469 1.845.277.342.568.691.852 1.04Z"
    />
    <path
      fill="#494949"
      d="M14.97 18.078v-2.613c0-.112-.047-.14-.142-.168a5.36 5.36 0 0 1-1.063-.419c-1.07-.573-1.631-1.628-1.482-2.766.149-1.132.968-1.991 2.145-2.25.027-.007.048-.007.075-.014.44-.09.447-.09.467-.552.007-.118.034-.181.156-.167.108.007.216 0 .331 0 .176 0 .393-.056.522.028.128.083.04.32.04.496 0 .146.095.14.183.153.379.05.744.147 1.09.322.73.363 1.191.957 1.38 1.767.021.084.068.16-.08.175-.529.056-1.056.125-1.584.195-.143.021-.143-.084-.17-.167a1.331 1.331 0 0 0-.656-.797c-.115-.063-.163-.056-.163.098.007.748.007 1.502 0 2.25 0 .16.095.16.19.181.433.112.853.266 1.272.433 1.997.839 1.814 3.284.765 4.325-.575.566-1.259.866-2.024.999-.162.028-.21.084-.203.244.014.28 0 .56.007.838.007.133-.027.19-.17.182a6.062 6.062 0 0 0-.71 0c-.156.007-.183-.063-.176-.203.007-.244-.014-.488.007-.733.02-.217-.048-.3-.264-.328a3.267 3.267 0 0 1-1.307-.461c-.805-.496-1.225-1.25-1.4-2.173-.021-.098-.008-.14.094-.147.548-.056 1.09-.119 1.638-.181.149-.021.129.104.156.181.176.566.514.978 1.076 1.272Zm1.05-2.452v2.41c0 .084-.008.153.121.118.677-.16 1.32-.83 1.036-1.67-.183-.537-.65-.719-1.158-.858Zm-1.05-4.395a1.18 1.18 0 0 0-.907 1.076c-.027.447.298.915.751 1.097.068.028.156.084.156-.063v-2.11Z"
    />
  </svg>
)
export default CreditCardRecurrencing
