import { styled } from '@mui/material'

import { theme } from 'styles/theme'

interface ToggleShortcutBoxProps {
  isActive: boolean
}

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',

  maxWidth: '100%',
  overflowX: 'auto',
  minHeight: '70px',

  '::-webkit-scrollbar-thumb': {
    backgroundColor: 'transparent'
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: '#FCFCFC'
  },
  '&:hover': {
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#dad7d7'
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: '#F4F4F4'
    }
  }
}))

export const ActiveShortcuts = styled('div')(() => ({
  display: 'flex',
  gap: 22,
  alignItems: 'center',
  marginTop: 4,
  marginLeft: 8,
  marginRight: 8
}))

export const ModalTitle = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 15,
  justifyContent: 'center'
}))

export const ToggleShortcutBox = styled('div')<ToggleShortcutBoxProps>(
  ({ theme, isActive }) => ({
    border: `${
      isActive
        ? `solid 1px ${theme.palette.neutral[800]}`
        : `dashed 1px ${theme.palette.neutral[700]}`
    }`,
    backgroundColor: `${isActive ? theme.palette.neutral[800] : 'transparent'}`,
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    padding: '12px 22px',
    alignItems: 'center'
  })
)

export const ModalFooter = styled('div')(() => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 15,
  [theme.breakpoints.down('md')]: {
    display: 'flex',

    flexDirection: 'column'
  }
}))
