import React, { useState } from 'react'

import {
  Box,
  Checkbox,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableSortLabel
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'

import { EnhancedTableProps, Order, TableParams } from './Table.interface'
import {
  Table as EnhancedTable,
  TableCell as EnhancedTableCell,
  TableRow as EnhancedTableRow,
  TableHeadCell
} from './Table.styles'
import { getComparator, stableSort } from './Table.utils'

export const Table: React.FC<TableParams> = ({
  rows,
  header,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPage,
  totalCount = 0,
  hoverInRow = false,
  rowAction,
  hiddePagination = false,
  disableFilter,
  select
}) => {
  const { useSelect, selected, setSelected } = select
  const [order, setOrder] = useState<Order>(undefined)
  const [orderBy, setOrderBy] = useState<string>('')

  const isSelected = (uuid: string) => selected && selected.indexOf(uuid) !== -1

  function EnhancedTableHead(props: EnhancedTableProps) {
    const {
      order,
      orderBy,
      onRequestSort,
      numSelected,
      onSelectAllClick,
      rowCount
    } = props
    const createSortHandler =
      (property: string) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property)
      }

    return (
      <TableHead>
        <EnhancedTableRow>
          {useSelect && (
            <EnhancedTableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </EnhancedTableCell>
          )}
          {header.map((headCell) =>
            disableFilter ? (
              <TableHeadCell key={headCell.id}>{headCell.label}</TableHeadCell>
            ) : (
              <EnhancedTableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {!disableFilter && (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                )}
              </EnhancedTableCell>
            )
          )}
        </EnhancedTableRow>
      </TableHead>
    )
  }

  const handleRequestSort = (
    _: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    const isDesc = orderBy === property && order === 'desc'

    setOrderBy(isDesc ? '' : isAsc ? property : property)
    setOrder(isDesc ? undefined : isAsc ? 'desc' : 'asc')
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows && rows.map((n) => n.uuid)
      !!setSelected &&
        setSelected((prevState: any) => ({
          ...prevState,
          selected: newSelected
        }))
      return
    }
    !!setSelected &&
      setSelected((prevState) => ({ ...prevState, selected: [] }))
  }

  const handleClick = (uuid: string) => {
    if (selected) {
      const selectedIndex = selected.indexOf(uuid)
      let newSelected: string[] = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, uuid)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        )
      }

      !!setSelected &&
        setSelected((prevState) => ({ ...prevState, selected: newSelected }))
    }
  }
  return (
    <TableContainer>
      <EnhancedTable>
        <EnhancedTableHead
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          numSelected={(selected && selected.length) || 0}
          onSelectAllClick={handleSelectAllClick}
          rowCount={(rows && rows.length) || 0}
        />
        <TableBody>
          {stableSort(rows || [], getComparator(order, orderBy)).map(
            ({ uuid, backgroundColor, ...row }, index) => {
              const isItemSelected = isSelected(uuid as string)
              return (
                <EnhancedTableRow
                  sx={{
                    backgroundColor: backgroundColor ?? ''
                  }}
                  key={index}
                  hoverInRow={hoverInRow}
                  {...(useSelect && {
                    onClick: () => handleClick(uuid as string)
                  })}
                >
                  {useSelect && (
                    <EnhancedTableCell component="th" scope="row">
                      <Checkbox color="primary" checked={isItemSelected} />
                    </EnhancedTableCell>
                  )}
                  {Object.keys(row).map((key, i) => (
                    <EnhancedTableCell
                      component="th"
                      key={String(i)}
                      scope="row"
                      {...(i < Object.keys(row)?.length - 1 &&
                        rowAction && {
                          onClick: () => rowAction(String(uuid))
                        })}
                    >
                      {row[key]}
                    </EnhancedTableCell>
                  ))}
                </EnhancedTableRow>
              )
            }
          )}
        </TableBody>
        {!hiddePagination && (
          <TableFooter>
            <TablePagination
              count={totalCount}
              labelDisplayedRows={({ count, to, from }) =>
                `${from}–${to} ${
                  count !== -1 ? `de ${count}` : `mais que ${to}`
                }`
              }
              labelRowsPerPage="Registros por página:"
              onPageChange={(event, newPage) =>
                !!handleChangePage && handleChangePage(event, newPage)
              }
              onRowsPerPageChange={(event) =>
                !!handleChangeRowsPerPage && handleChangeRowsPerPage(event)
              }
              page={page ? page : 0}
              rowsPerPage={rowsPerPage ? rowsPerPage : 25}
              rowsPerPageOptions={[25, 50, 100]}
            />
          </TableFooter>
        )}
      </EnhancedTable>
    </TableContainer>
  )
}
