import { styled } from '@mui/material'

import { theme } from 'styles/theme'

export const ContainerIcon = styled('div')(() => ({
  width: '35px',
  height: '35px',
  background: '#F8F8F8',
  borderRadius: '8px',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

export const Separator = styled('div')(() => ({
  width: '1fr',
  flexGrow: '1',
  borderTop: '0.5px dashed #CBCBCB',
  alignSelf: 'center',
  marginTop: '2px'
}))

export const RecurrenceContent = styled('div')(() => ({
  marginTop: 12,
  padding: 12,
  border: '1px solid #EDEBEB',
  borderRadius: '5px'
}))

export const Link = styled('a')(() => ({
  margin: 0,
  padding: 0,
  fontWeight: 600,
  color: '#231F20',
  cursor: 'pointer'
}))

export const AccordionWrapper = styled('a')(() => ({
  marginTop: '12px',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  width: 'calc(40vh + 12px)',
  [theme.breakpoints.up(450)]: {
    width: 'auto'
  }
}))

export const FooterWrapper = styled('a')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  borderTop: '1px solid  #EDEBEB',
  width: '100%',
  maxWidth: '100%',
  [theme.breakpoints.down(450)]: {
    maxWidth: 'auto'
  }
}))
