import { createContext, useRef, useState } from 'react'

import { Icons } from '@clientbase/clientbase-library'
import { Dialog as DialogType } from 'context/DialogContext'
import { InvoiceParams } from 'models/Invoice'
import { NFeParams } from 'models/NFe'
import { PaymentParams } from 'models/Payment'

import UploadComponent from 'components/UploadComponent'

import {
  ModalChargeDetailContextProviderProps,
  ModalChargeDetailProviderState,
  ModalProps
} from './context.interface'

export const ModalChargeDetailContext = createContext(
  {} as ModalChargeDetailProviderState
)

export function ModalChargeDetailProvider({
  children
}: ModalChargeDetailContextProviderProps) {
  const [reload, setReload] = useState(false)

  const [dialogReverse, setDialogReverse] = useState({
    isOpen: false,
    uuid: ''
  })

  const [modalNfDetails, setModalNfDetails] = useState<boolean>(false)

  const [dialog, setDialog] = useState<DialogType>({
    title: '',
    isOpenDialog: false,
    description: 'Estorno de cartão',
    icon: 'info' as Icons,
    action: () => ({})
  })
  const [invoice, setInvoice] = useState<InvoiceParams>({
    dueDate: '00-00-0000',
    expirationField: '00-00-0000'
  } as InvoiceParams)

  const [paymentType, setPaymentType] = useState<any>([])
  const [paids, setPaids] = useState<PaymentParams[] | null | undefined>()

  const [nfe, setNfe] = useState<NFeParams>({} as NFeParams)

  const [modal, setModal] = useState<ModalProps>({
    isOpenEditCharge: false,
    isOpenModalRecurrenceDetail: false,
    isOpenDialogAntecipate: false,
    isOpenDialogCancel: false,
    isOpenModalPaid: false,
    formNegativation: false,
    loading: false,
    uuid: '',
    uuidRecurrenceDetail: ''
  })

  const upload = useRef<UploadComponent>()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [tab, setTab] = useState(0)

  return (
    <ModalChargeDetailContext.Provider
      value={{
        updateModal: setModal,
        modal,
        invoice,
        updateInvoice: setInvoice,
        anchorEl,
        updateAnchorEl: setAnchorEl,
        paymentType,
        updatePaymentType: setPaymentType,
        paids,
        updatePaids: setPaids,
        dialog,
        updateDialog: setDialog,
        reload,
        updateReload: setReload,
        dialogReverse,
        updateDialogReverse: setDialogReverse,
        tab,
        updateTab: setTab,
        nfe,
        updateNfe: setNfe,
        upload,
        modalNfDetails,
        updateModalNfDetails: setModalNfDetails
      }}
    >
      {children}
    </ModalChargeDetailContext.Provider>
  )
}
