import { Typography } from '@mui/material'
import styled from '@mui/system/styled'

import { theme } from 'styles/theme'

export const Wrapper = styled('button')(() => ({
  position: 'relative',
  outline: 0,
  border: 0,
  background: 'none',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 4,
  borderRadius: 60,
  width: 'fit-content',
  maxWidth: 54
}))

type WrapperProps = {
  size: 'xxs' | 'xs' | 'small' | 'normal'
  color?: string
  isHovered: boolean
}

const groupWrapperModifiers = {
  normal: () => ({
    width: '56px',
    height: '56px'
  }),
  small: () => ({
    width: '40px',
    height: '40px'
  }),
  xs: () => ({
    width: '30px',
    height: '30px'
  }),
  xxs: () => ({
    width: '26px',
    height: '26px'
  })
}

export const IconWrapper = styled('div')<WrapperProps>(
  ({ size, color, isHovered }) => ({
    ...groupWrapperModifiers[size](),
    borderRadius: 56,
    outline: `${isHovered ? '4px' : '2px'} solid ${color || '#A3A6A2'}`,
    outlineOffset: isHovered ? 0 : 2,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: isHovered ? color : 'transparent'
  })
)

export const NewBanner = styled(Typography)(() => ({
  position: 'absolute',
  zIndex: 2,
  backgroundColor: theme.palette.success[300],
  fontSize: 11,
  color: '#fff',
  padding: '1px 7px',
  borderRadius: '5px',
  top: -10
}))
