import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState
} from 'react'
import { toast } from 'react-toastify'

import {
  Button,
  Icon,
  Icons,
  Modal,
  Switch
} from '@clientbase/clientbase-library'
import { Box, Grid, Typography } from '@mui/material'
import { DataService } from 'api/DataService'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { parseCookies, setCookie } from 'nookies'
import InvoiceIcon from 'templates/Invoice/components/InvoiceIcon'

import RoundedItem from 'components/RoundedItem'

import getCurrentMonth from 'utils/get-current-month'
import { ChargeTabs, CustomerTabs, settingsTabsParams } from 'utils/tabs'
import { toastProps } from 'utils/types/toast-props'

import * as S from './Shortcuts.styles'

interface shortcutOption {
  id: string
  name: string
  icon: Icons | JSX.Element
  iconSelected?: JSX.Element
  iconModal?: JSX.Element
  route: string
  isNew?: boolean
}

const shortcutOptions: shortcutOption[] = [
  {
    id: `charges`,
    name: `Cobranças`,
    icon: `event`,
    route: `/cobrancas?tab=${
      ChargeTabs.CHARGES
    }&due_date_between=${getCurrentMonth().join(`|`)}`
  },
  {
    id: `clients`,
    name: `Clientes`,
    icon: `person`,
    route: `/clientes?tab=${CustomerTabs.CUSTOMERS}`
  },
  {
    id: `contracts`,
    name: `Contratos`,
    icon: `assignment`,
    route: `/clientes?tab=${CustomerTabs.CONTRACTS}`
  },
  {
    id: `groups`,
    name: `Grupos`,
    icon: `supervisorAccount`,
    route: `/clientes?tab=${CustomerTabs.GROUPS}`
  },
  {
    id: `recurrences`,
    name: `Recorrências`,
    icon: `update`,
    route: `/cobrancas?tab=${ChargeTabs.RECURRENCES}`
  },
  {
    id: `communication`,
    name: `Comunicação`,
    icon: `sms`,
    route: `/relacionamento/comunicacao`
  },
  {
    id: `imports`,
    name: `Importações`,
    icon: `noteAdd`,
    route: `/settings?tab=${settingsTabsParams.IMPORTS}`
  },
  {
    id: `transfers`,
    name: `Transferências`,
    icon: `compareArrows`,
    route: `/cobrancas?tab=${
      ChargeTabs.TRANSFERS
    }&transfer_date_between=${getCurrentMonth().join(`|`)}`
  },
  {
    id: `personalize`,
    name: `Personalizar`,
    icon: `edit`,
    route: `/relacionamento/personalizar`
  },
  {
    id: `invoice`,
    name: `NFS-e`,
    icon: <InvoiceIcon />,
    route: `/cobrancas?tab=${ChargeTabs.NFS}`
  },
  {
    id: `products`,
    name: `Produtos`,
    icon: `star`,
    route: `/cobrancas?tab=${ChargeTabs.PRODUCTS}`
  },
  {
    id: `payment-link`,
    name: `Link de Pag.`,
    icon: `creditCardChecked`,
    route: `/cobrancas?tab=${ChargeTabs.PAYMENT_LINK}`
  }
]

interface ShortcutsProps {
  setIsExpandedMobileMenu?: Dispatch<SetStateAction<boolean>>
}

const Shortcuts = ({ setIsExpandedMobileMenu }: ShortcutsProps) => {
  const { push, asPath } = useRouter()

  const currentPath = asPath.split('&')[0]

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeShortcuts, setActiveShortcuts] = useState<string[]>([])
  const [temporaryShortcuts, setTemporaryShortcuts] = useState<string[]>([])
  const [merchantUserUuid, setMerchantUserUuid] = useState('')

  const getShortcuts = async () => {
    const {
      ['nextauth.merchant_user_uuid']: merchant_user_uuid,
      ['nextauth.shortcuts']: shortcuts
    } = parseCookies()

    setMerchantUserUuid(merchant_user_uuid)

    let shortcutsFromCookies = []

    if (shortcuts === undefined || shortcuts === '') {
      const response = await DataService({
        url: `/v1/merchant_users`,
        type: 'GET'
      })

      shortcutsFromCookies = response?.data?.records
        .find((user: any) => user.uuid === merchant_user_uuid)
        .shortcuts.split(';')

      handleSaveShortcutsCookies(shortcutsFromCookies.join(';'))
    } else {
      shortcutsFromCookies = shortcuts.split(';')
    }

    setActiveShortcuts(shortcutsFromCookies)
    setTemporaryShortcuts(shortcutsFromCookies)
  }

  useEffect(() => {
    getShortcuts()
  }, [])

  const handleSaveShortcutsCookies = (shortcutsString: string) => {
    setCookie(undefined, 'nextauth.shortcuts', shortcutsString, {
      maxAge: 60 * 60 * 24 * 6
    })
  }

  const handleShortcutsSubmit = async () => {
    const shortcutsString = temporaryShortcuts.join(';')

    const id = toast.loading('Atualizando atalhos...')

    const response = await DataService({
      type: 'PATCH',
      data: { shortcuts: shortcutsString },
      url: `/v1/merchant_users/${merchantUserUuid}`
    })

    const errorMessage =
      response.error && (response.detail_error || response.message)

    toast.update(id, {
      render: response.error
        ? errorMessage
        : 'Atalhos atualizados com sucesso!',
      type: response.error ? 'error' : 'success',
      ...toastProps
    })

    handleSaveShortcutsCookies(shortcutsString)

    setIsModalOpen(false)
    setActiveShortcuts(temporaryShortcuts)
  }

  const changeShortcutState = (
    event: ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    if (event.target.checked && !temporaryShortcuts.includes(id)) {
      setTemporaryShortcuts((previous) => [...previous, id])
    }

    if (!event.target.checked && temporaryShortcuts.includes(id)) {
      const tempArray = [...temporaryShortcuts]
      tempArray.splice(tempArray.indexOf(id), 1)
      setTemporaryShortcuts(tempArray)
    }
  }

  const handleClick = (
    event: React.MouseEvent<HTMLDivElement>,
    route: string
  ) => {
    const mouseEvent = event as React.MouseEvent
    const isNewTab = mouseEvent.ctrlKey || mouseEvent.metaKey

    if (isNewTab) {
      window.open(route, '_blank')
    } else {
      push(route)
    }
    !!setIsExpandedMobileMenu && setIsExpandedMobileMenu(false)
  }

  return (
    <S.Wrapper>
      <S.ActiveShortcuts>
        {shortcutOptions.map((shortcut) => {
          if (!activeShortcuts.includes(shortcut.id)) {
            return null
          }

          return (
            <Link
              href={shortcut.route}
              key={shortcut.id}
              onClick={(e) => e.preventDefault()}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <RoundedItem
                name={shortcut.name}
                icon={shortcut.icon}
                isNew={shortcut.isNew}
                isActive={
                  ['transfers', 'charges'].includes(shortcut.name)
                    ? currentPath == shortcut.route
                    : asPath == shortcut.route
                }
                onClick={(e) =>
                  handleClick(
                    e as unknown as React.MouseEvent<HTMLDivElement>,
                    shortcut.route
                  )
                }
                size="xs"
                color="#01F7C2"
              />
            </Link>
          )
        })}

        <RoundedItem
          tooltipTitle="Adicionar atalhos"
          icon="add"
          onClick={() => setIsModalOpen(true)}
          size="xs"
          color="black"
          name="Atalhos"
        />
      </S.ActiveShortcuts>

      <Modal
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        size="large"
        width="100%"
        maxWidth="50vw"
        maxHeight="90vh"
        height="auto"
        title={
          <S.ModalTitle>
            <RoundedItem icon="add" size="xxs" color="black" disableHover />
            <Typography variant="lgBold">Adicionar atalhos</Typography>
          </S.ModalTitle>
        }
      >
        <Grid container spacing={2} mb={4}>
          {shortcutOptions.map(({ id, name, icon }) => (
            <Grid item key={name} lg={2.4} md={3} sm={4} xs={4}>
              <S.ToggleShortcutBox isActive={temporaryShortcuts.includes(id)}>
                {typeof icon === 'object' ? (
                  <Box
                    bgcolor="#1E1E1E"
                    borderRadius="50%"
                    padding={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {React.cloneElement(icon, { color: 'white', width: 28 })}
                  </Box>
                ) : (
                  <Icon
                    icon={icon}
                    fontSize="large"
                    style={{
                      backgroundColor: '#1E1E1E',
                      borderRadius: '50%',
                      padding: 12,
                      color: 'white'
                    }}
                  />
                )}
                <Typography variant="smLight" textAlign="center">
                  {name}
                </Typography>
                <Switch
                  checked={temporaryShortcuts.includes(id)}
                  onChange={(e) => {
                    changeShortcutState(e, id)
                  }}
                />
              </S.ToggleShortcutBox>
            </Grid>
          ))}
        </Grid>

        <S.ModalFooter>
          <Button variantButton="gray" onClick={() => setIsModalOpen(false)}>
            Cancelar
          </Button>
          <Button onClick={handleShortcutsSubmit}>Salvar</Button>
        </S.ModalFooter>
      </Modal>
    </S.Wrapper>
  )
}

export default Shortcuts
