import { Box, Typography } from '@mui/material'

import * as S from './ChargeFineAndInterest.styles'
type ChargeFineAndInterestProps = {
  interestFine: number | string
  interestFee: number | string
  interestPolicy?: string
}

export default function ChargeFineAndInterest({
  interestFee,
  interestFine,
  interestPolicy
}: ChargeFineAndInterestProps) {
  if (interestPolicy === 'no_interest') {
    return (
      <Box
        display="flex"
        gap={1.25}
        alignItems="end"
        margin={'10px 0px 10px 0px'}
      >
        <Typography variant="mdLight" color="#777777">
          Juros:
        </Typography>
        <S.Separator sx={{ marginTop: '4px' }} />
        <Typography variant="mdBold">Não</Typography>
      </Box>
    )
  }

  const multa =
    Number(interestFine) % 1 === 0
      ? Number(interestFine).toFixed(0)
      : Number(interestFine).toFixed(2).toString()

  const juros =
    Number(interestFee) % 1 === 0
      ? Number(interestFee).toFixed(0)
      : Number(interestFee).toFixed(2).toString()

  return (
    <>
      <Box
        display="flex"
        gap={1.25}
        alignItems="end"
        margin={'10px 0px 10px 0px'}
      >
        <Typography variant="mdLight" color="#777777">
          Multa:
        </Typography>
        <S.Separator sx={{ marginTop: '4px' }} />
        <Typography variant="mdBold" display="flex" alignItems="center">
          {multa !== '0' ? `${multa}%` : 'Não'}
        </Typography>
      </Box>
      <Box
        display="flex"
        gap={1.25}
        alignItems="end"
        margin={'10px 0px 10px 0px'}
      >
        <Typography variant="mdLight" color="#777777">
          Juros:
        </Typography>
        <S.Separator sx={{ marginTop: '4px' }} />
        <Typography variant="mdBold" display="flex" alignItems="center">
          {juros !== '0' ? `${juros}% ao mês` : 'Não'}
        </Typography>
      </Box>
    </>
  )
}
