function formatPhone(phone: string) {
  phone.replace(' ', '')

  if (phone.substring(0, 2) === '55') {
    phone = phone.slice(2)
  }

  phone = phone.replace(/\D/g, '')

  phone = phone.replace(/(.{0})(\d)/, '$1($2')
  phone = phone.replace(/(.{3})(\d)/, '$1)$2')
  if (phone.length == 11) {
    phone = phone.replace(/(.{2})$/, '-$1')
  } else if (phone.length == 12) {
    phone = phone.replace(/(.{3})$/, '-$1')
  } else if (phone.length == 13) {
    phone = phone.replace(/(.{4})$/, '-$1')
  } else if (phone.length == 14) {
    phone = phone.replace(/(.{5})$/, '-$1')
  } else if (phone.length > 15) {
    phone = phone.replace(/(.{6})$/, '-$1')
  }
  return phone.replace(/(-\d{4})\d+?$/, '$1')
}

export default formatPhone
