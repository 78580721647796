import { MenuItem as MenuItemMUI } from '@mui/material'
import { styled } from '@mui/system'

import { theme } from 'styles/theme'

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 24,
  cursor: 'pointer',
  padding: '4px 8px',
  border: '1px solid transparent',

  '&:hover': {
    borderRadius: 8,
    borderColor: '#e1e1e1'
  }
}))

export const ImageWrapper = styled('div')(() => ({
  position: 'relative',
  width: '40px',
  height: '40px',
  borderRadius: 40,
  background: '#fff',
  overflow: 'hidden',
  border: '1px solid #e1e1e1',

  '& img': {
    objectFit: 'cover'
  }
}))

export const MenuItem = styled(MenuItemMUI)(() => ({
  ['&:last-child']: {
    marginTop: 4
  },
  alignItems: 'center',
  display: 'flex',
  gap: 8,
  minWidth: 300
}))

export const LogoHeadingWrapper = styled('div')(() => ({
  display: 'flex',
  gap: 10,
  alignItems: 'center'
}))

export const LogoWrapper = styled('div')(() => ({
  width: '32px',
  height: '32px',
  overflow: 'hidden',
  borderRadius: '50%',
  backgroundColor: '#FFF',
  outline: `2px solid ${theme.palette.neutral[700]}`
}))

export const HeadingWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 1
}))

export const Name = styled('p')(({ theme }) => ({
  margin: 0,
  font: 'normal 600 12px/120% Inter, sans-serif',
  color: theme.palette.neutral[100]
}))

export const MobileBox = styled('div')(() => ({
  position: 'relative'
}))

export const MobileLogo = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  borderRadius: '50%',
  border: `solid 1px ${theme.palette.neutral[700]}`,
  backgroundColor: '#FFF',
  width: '40px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '50%',
  left: '-5px',
  transform: 'translate(-50%, -50%)'
}))

export const MobileName = styled('span')(() => ({
  backgroundColor: '#FFF',
  borderRadius: 12,
  padding: '14px 24px',
  boxShadow: '0px 6px 15px 0px rgba(0, 0, 0, 0.10)',
  fontSize: '14px',
  fontWeight: 600
}))
