/* eslint-disable sort-keys */

import { Icon } from '@clientbase/clientbase-library'
import { Box, Tooltip } from '@mui/material'
import { BalanceDetailItem } from 'models/BalanceDetails'
import moment from 'moment'

import { ButtonRounded } from 'components/button'
import { TableNameWrapper } from 'components/DynamicTable/DynamicTable.styles'

import { formatMoney } from 'utils/FormatMoney'

import { theme } from 'styles/theme'

import * as S from './ModalBalance.styles'

type ConvertToRowsParams = {
  records: BalanceDetailItem[] | null
  handleOpenDetailInvoice: (uuid: string) => void
}

const formatDate = (date: string) => {
  if (date)
    return moment(date.slice(0, 10), 'YYYY-MM-DD')
      .format('DD/MM/YYYY')
      .toString()
  return
}

type CreateDataTable = {
  description: string
  type: 'Receivable' | 'Expense'
  dueDate: string
  paidDate: string
  amountPaid: string
  amount: string
  uuid: string
  handleOpenDetailInvoice: (uuid: string) => void
  installments?: number
  paymentType: string
}

export const createDataTable = ({
  description,
  dueDate,
  paidDate,
  amountPaid,
  amount,
  uuid,
  handleOpenDetailInvoice,
  type,
  installments,
  paymentType
}: CreateDataTable) => {
  const paymentTransform = {
    pix: 'Pix',
    boleto: 'Boleto',
    boleto_pix: 'Pix',
    credit_card: 'Cartão de crédito',
    credit_card_installments: 'Cartão de crédito',
    credit_card_recurring: 'Cartão de crédito'
  }

  const fee = Number(amountPaid) - Number(amount) ?? 0

  let payment_type = paymentTransform[paymentType]
  payment_type =
    paymentType.includes('credit_card') && Number(installments ?? 0) > 1
      ? payment_type + ' - Parcela ' + installments
      : payment_type

  return {
    uuid,
    type: (
      <Tooltip
        enterTouchDelay={0}
        title={type === 'Receivable' ? 'Crédito' : 'Débito'}
      >
        <S.ItemWrapper type={type}>
          <S.Item>{type === 'Receivable' ? '+' : '-'}</S.Item>
        </S.ItemWrapper>
      </Tooltip>
    ),
    description: <TableNameWrapper>{description}</TableNameWrapper>,
    dueDate: formatDate(dueDate),
    paidDate: formatDate(paidDate),
    amountPaid: formatMoney(amountPaid),
    fee: type == 'Receivable' ? formatMoney(fee) : '-',
    amountLiquid: formatMoney(amount),
    paymentType: type == 'Receivable' ? payment_type : '-',
    actions: (
      <Tooltip
        enterTouchDelay={0}
        arrow={true}
        placement="top"
        title={type == 'Receivable' ? 'Detalhes cobrança' : ''}
      >
        <Box>
          <ButtonRounded
            disabled={type == 'Expense'}
            onClick={() => handleOpenDetailInvoice(uuid)}
            variant="transparent"
          >
            <Icon
              icon="settings"
              sx={{
                color:
                  type == 'Receivable'
                    ? theme.palette.neutral[200]
                    : theme.palette.neutral[500]
              }}
            />
          </ButtonRounded>
        </Box>
      </Tooltip>
    )
  }
}

export const convertToRows = ({
  records,
  handleOpenDetailInvoice
}: ConvertToRowsParams) =>
  records?.map((record) => {
    if (!record) return {}
    return createDataTable({
      uuid: record?.billing_uuid,
      type: record.type,
      description: record.description,
      dueDate: record.due_date,
      paidDate: record.date_paid,
      amountPaid: record.amount_paid,
      amount: record.amount,
      handleOpenDetailInvoice,
      installments: record.installments,
      paymentType: record.payment_type
    })
  })
