import React, { ChangeEvent, useEffect, useState } from 'react'

import {
  Box,
  Checkbox,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination
} from '@mui/material'
import { DataService } from 'api/DataService'
import { useRouter } from 'next/router'

import Empty from 'components/Empty'

import {
  DataTableStates,
  DynamicTableParams,
  EnhancedTableProps
} from './DynamicTable.interface'
import {
  Table as EnhancedTable,
  TableCell as EnhancedTableCell,
  TableRow as EnhancedTableRow,
  WrapperPagination
} from './DynamicTable.styles'
import { DynamicTableSkeleton } from './skeleton'

export const DynamicTable = ({
  actions,
  configs,
  hasInCobrancaPage,
  filterBy,
  sort,
  dateGlobal,
  exportStatistics,
  rowAction,
  refresh = false,
  disablePagination = false,
  maxHeight = '100%',
  emptyComponent,
  height
}: DynamicTableParams) => {
  const router = useRouter()
  const {
    select: { selected = [], setSelected, useSelect = false }
  } = configs
  const [firstRender, setFirstRender] = useState(true)

  const [dataTable, setDataTable] = useState<DataTableStates>({
    header: [],
    loading: true,
    page: 0,
    rows: [],
    rowsPerPage: 25,
    statistics: {},
    totalCount: 0
  } as DataTableStates)

  useEffect(() => {
    !!exportStatistics && exportStatistics(dataTable.statistics)
  }, [dataTable])

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = dataTable.rows.map((n) => n.uuid)
      !!setSelected &&
        setSelected((prevState) => ({ ...prevState, selected: newSelected }))
      return
    }
    !!setSelected &&
      setSelected((prevState) => ({ ...prevState, selected: [] }))
  }

  const handleClick = (uuid: string) => {
    const selectedIndex = selected.indexOf(uuid)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, uuid)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    !!setSelected &&
      setSelected((prevState) => ({ ...prevState, selected: newSelected }))
  }

  const isSelected = (uuid: string) => selected.indexOf(uuid) !== -1

  const handleGetDataTable = async ({
    url,
    constructorModel,
    constructorStatistics,
    createDataTable,
    actions,
    page,
    rowsPerPage
  }: {
    url: string
    nameLocalStorage?: string
    constructorModel: any
    constructorStatistics?: any
    createDataTable: any
    actions?: any
    page?: any
    rowsPerPage?: any
  }) => {
    setDataTable((prevState) => ({
      ...prevState,
      loading: true
    }))

    let filterByQueryString = Object.keys(filterBy || {}).length
      ? '&filter_by='
      : ''
    let extensionSort = ''

    !!Object.keys(filterBy || {}).length &&
      Object.entries(filterBy).forEach(([k, val]) => {
        if (!k || !val) return
        filterByQueryString += `${k}:${val},`
      })

    if (sort && sort.label !== 'initial') {
      extensionSort = `&sort=${sort.label}:${sort.sort}`
    }

    const tablePage = page + 1 || dataTable.page + 1
    const tableRow = rowsPerPage || dataTable.rowsPerPage

    const getURL = `${url}${
      url.includes('?') ? '&' : '?'
    }page=${tablePage}&per=${tableRow}${filterByQueryString.slice(
      0,
      -1
    )}${extensionSort}`

    const { data, status } = await DataService({
      type: 'GET',
      url: getURL
    })

    if (status === 200) {
      const parseModel = data?.records.map((data: any) => {
        return constructorModel(data)
      })

      const parseStatistics = constructorStatistics
        ? constructorStatistics(data?.statistics)
        : {}

      const rows = parseModel?.map((data: any) => {
        return createDataTable(data, actions, router)
      })

      setDataTable((prevState) => ({
        ...prevState,
        loading: false,
        rows,
        statistics: parseStatistics,
        totalCount: data?.meta?.total_count
      }))
    }
  }

  const handleChangePage = async (_: unknown, newPage: number) => {
    setDataTable({ ...dataTable, page: newPage })
    await handleGetDataTable({ ...configs, actions, page: newPage })
  }

  const handleChangeRowsPerPage = async (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setDataTable((prevState) => ({
      ...prevState,
      page: 0,
      rowsPerPage: +event.target.value
    }))
    await handleGetDataTable({
      ...configs,
      actions,
      page: 0,
      rowsPerPage: +event.target.value
    })
  }

  function EnhancedTableHead({
    numSelected = 0,
    onSelectAllClick,
    rowCount = 0
  }: EnhancedTableProps) {
    return (
      <TableHead>
        <EnhancedTableRow>
          {useSelect && (
            <EnhancedTableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </EnhancedTableCell>
          )}
          {dataTable.header.map((headCell, i) => (
            <EnhancedTableCell key={String(i)}>
              {headCell.label}
            </EnhancedTableCell>
          ))}
        </EnhancedTableRow>
      </TableHead>
    )
  }

  useEffect(() => {
    if (hasInCobrancaPage === false && firstRender) {
      setFirstRender(false)
      return
    }

    const {
      header,
      nameLocalStorage,
      url,
      constructorModel,
      constructorStatistics,
      createDataTable
    } = configs

    setDataTable((prevState) => ({
      ...prevState,
      header,
      page: 0
    }))
    handleGetDataTable({
      actions,
      constructorModel,
      constructorStatistics,
      createDataTable,
      nameLocalStorage,
      page: 0,
      url
    })
  }, [filterBy, sort, dateGlobal, refresh])

  if (dataTable.loading) {
    return <DynamicTableSkeleton />
  }

  if (!dataTable.loading && dataTable.rows.length === 0 && !!emptyComponent) {
    return <>{emptyComponent}</>
  }

  if (!dataTable.loading && dataTable.rows.length === 0) {
    return <Empty description="Nenhum item encontrado!" pt />
  }

  return (
    <Box width="100%" height="100%">
      <TableContainer
        style={{
          maxHeight: maxHeight ? maxHeight : '100%',
          height: height ? height : undefined
        }}
        id="dynamic-table"
      >
        <EnhancedTable stickyHeader={true}>
          <EnhancedTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={dataTable.rows.length}
          />
          <TableBody>
            {dataTable.rows.map(({ uuid, ...row }, index) => {
              const isItemSelected = isSelected(uuid)

              return (
                <EnhancedTableRow
                  key={String(index)}
                  {...(useSelect && {
                    onClick: () => handleClick(uuid)
                  })}
                >
                  {useSelect && (
                    <EnhancedTableCell component="th" scope="row">
                      <Checkbox color="primary" checked={isItemSelected} />
                    </EnhancedTableCell>
                  )}

                  {Object.keys(row).map((key, i) => (
                    <EnhancedTableCell
                      component="th"
                      key={String(i)}
                      scope="row"
                      {...(i <= Object.keys(row)?.length - 2 &&
                        rowAction && { onClick: () => rowAction(uuid) })}
                    >
                      {row[key]}
                    </EnhancedTableCell>
                  ))}
                </EnhancedTableRow>
              )
            })}
          </TableBody>
        </EnhancedTable>
      </TableContainer>

      {!disablePagination && (
        <WrapperPagination>
          <TablePagination
            count={dataTable.totalCount}
            labelDisplayedRows={({ count, to, from }) =>
              `${from}–${to} ${count !== -1 ? `de ${count}` : `mais que ${to}`}`
            }
            labelRowsPerPage="Registros por página:"
            onPageChange={(event, newPage) => handleChangePage(event, newPage)}
            onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
            page={dataTable.page}
            rowsPerPage={dataTable.rowsPerPage}
            rowsPerPageOptions={[25, 50, 100, 500]}
          />
        </WrapperPagination>
      )}
    </Box>
  )
}
