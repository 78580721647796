import { styled } from '@mui/material'
import { rgba } from 'polished'

import { theme } from 'styles/theme'

export const Separator = styled('div')(() => ({
  width: '1fr',
  flexGrow: '1',
  borderTop: '0.5px dashed #CBCBCB',
  alignSelf: 'center',
  marginTop: '4px'
}))

const getStatus = (status: string) => {
  switch (status) {
    case 'pending':
      return {
        color: theme.palette.neutral[400],
        background: rgba(theme.palette.neutral[700], 0.3)
      }
    case 'paid':
      return {
        color: theme.palette.primary[200],
        background: rgba(theme.palette.primary[200], 0.2)
      }
    case 'cancelling':
      return {
        color: theme.palette.error[300],
        background: rgba(theme.palette.error[300], 0.1)
      }
    case 'reversed':
      return {
        color: theme.palette.error[300],
        background: rgba(theme.palette.error[300], 0.1)
      }
    case 'dusputed':
      return {
        color: theme.palette.error[300],
        background: rgba(theme.palette.error[300], 0.1)
      }
    case 'chargeback':
      return {
        color: theme.palette.error[300],
        background: rgba(theme.palette.error[300], 0.1)
      }
    case 'avaiable':
      return {
        color: theme.palette.secondary[300],
        background: rgba(theme.palette.secondary[300], 0.1)
      }
    case 'blocked':
      return {
        color: theme.palette.secondary[300],
        background: rgba(theme.palette.secondary[300], 0.1)
      }
    default:
      window.newrelic.noticeError(
        new Error(`Erro ao mapear estilos do Payment Details: ${status}`)
      )
      return {
        color: theme.palette.neutral[400],
        background: rgba(theme.palette.neutral[700], 0.3)
      }
  }
}

type StatusProps = {
  status: string
}

export const Status = styled('div')<StatusProps>(({ status }) => ({
  width: 'fit-content',
  padding: '4px 8px',
  fontWeight: 600,
  fontSize: '14px',
  borderRadius: 9999,
  ...getStatus(status)
}))
