interface ServiceProviderParams {
  certificate_url: string
  document: string
  legal_name: string
  uuid: string
}

type ServiceProviderAutoCompleteParams = Omit<
  ServiceProviderParams,
  'document' | 'certificate_url'
>

interface ServicesParams {
  uuid: string
  code: string
  description: string
  created_at: string
  updated_at: string
}

type ServiceAutoCompleteParams = Omit<
  ServicesParams,
  'created_at' | 'updated_at'
>

export const constructorAutoCompleteProvider = (
  data: ServiceProviderParams[]
): ServiceProviderAutoCompleteParams[] => {
  const parse = data.map(
    ({ uuid, legal_name }) =>
      ({
        uuid,
        legal_name
      } as ServiceProviderAutoCompleteParams)
  )
  return parse
}

export const constructorAutoCompleteServices = (
  data: ServicesParams[]
): ServiceAutoCompleteParams[] => {
  const parse = data.map(
    ({ uuid, description, code }) =>
      ({
        uuid,
        description,
        code
      } as ServiceAutoCompleteParams)
  )
  return parse
}
