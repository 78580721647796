import ModalMUI from '@mui/material/Modal'
import styled from '@mui/system/styled'

const mediaQuery = '@media (min-width: 500px)'
export const Wrapper = styled(ModalMUI)(() => ({}))

export const Content = styled('div')(() => ({
  background: '#FFF',
  position: 'relative',
  inset: '50px 0 0 0',
  padding: '0 2rem 1rem',
  overflowY: 'auto',
  boxSizing: 'border-box',
  animation: 'bottomToTop 500ms ease-in-out forwards',
  height: '100%',

  '@keyframes bottomToTop': {
    '0%': {
      bottom: '-100vh'
    },

    '100%': {
      bottom: 0
    }
  },

  [mediaQuery]: {
    top: '50px',
    padding: '0 2rem 1rem'
  }
}))

export const Header = styled('div')(() => ({
  marginTop: 15,
  marginBottom: 15,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 20,
  [mediaQuery]: {
    marginTop: 30,
    marginBottom: 30
  }
}))

type HeadingProps = {
  order: number
}

export const Heading = styled('h3')<HeadingProps>(({ order }) => ({
  margin: 0,
  order
}))

export const IconWrapper = styled('div')(() => ({
  display: 'grid',
  placeItems: 'center',
  background: '#F8F8F8',
  padding: 10,
  borderRadius: 12
}))

export const CloseButton = styled('div')(() => ({
  position: 'fixed',
  right: 16,
  top: 65,
  zIndex: 100,
  cursor: 'pointer'
}))
