export const NatureOption = [
  { value: '1', label: 'Trib. no Município' },
  { value: '2', label: 'Trib. Fora do Município' },
  { value: '3', label: 'Isento' },
  { value: '4', label: 'Imune' },
  { value: '5', label: 'Susp. Decisão Judicial' },
  { value: '6', label: 'Susp. Decisão Adm' }
]

export const FilterOptions = [
  { value: 'open_payment', label: 'Aberta' },
  { value: 'pending', label: 'Pendente' },
  { value: 'paid', label: 'Paga' }
]

export const enumNature = {
  tributacao_no_municipio: '1',
  tributacao_fora_do_municipio: '2',
  isento: '3',
  imune: '4',
  suspensa_por_decisao_judicial: '5',
  suspensa_por_decisao_administrativa: '6'
}
