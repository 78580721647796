import { DataService } from 'api/DataService'
import { Negativation } from 'models/Negativation'

interface CreateNegativationParams {
  customer_uuid: string
  billing_uuid: string
}

export class NegativationService {
  public static async createNegativation(
    data: CreateNegativationParams
  ): Promise<any> {
    const response = await DataService({
      url: `/v1/negativations`,
      type: 'POST',
      data: {
        negativation: {
          customer_uuid: data?.customer_uuid,
          billing_uuid: data?.billing_uuid
        }
      }
    })

    if (response.status === 200) {
      return response
    }

    return response
  }
  public static async getNegativations(
    filterBy: string | undefined
  ): Promise<any> {
    const response = await DataService({
      url: `/v1/negativations${filterBy}`,
      type: 'GET'
    })

    if (response.status === 200) {
      return response?.data
    }

    return {} as any
  }
  public static async getNegativationDetails(
    uuid: string
  ): Promise<Negativation> {
    const response = await DataService({
      url: `/v1/negativations/${uuid}`,
      type: 'GET'
    })

    if (response.status === 200) {
      return response?.data?.records
    }

    return {} as Negativation
  }
  public static async cancelNegativation(uuid: string): Promise<any> {
    try {
      const response = await DataService({
        url: `/v1/negativations/${uuid}`,
        type: 'DELETE'
      })

      if (response.status === 200) {
        return response?.data
      }
    } catch (err) {
      console.log(err)
    }
  }
  public static async recoverNegativation(uuid: string): Promise<any> {
    const response = await DataService({
      url: `/v1/negativations/${uuid}/recover`,
      type: 'PATCH'
    })

    if (response.status === 200) {
      return response
    }

    return response
  }
}
