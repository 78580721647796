import React from 'react'

import { Box, Skeleton } from '@mui/material'

const Loading: React.FC = () => {
  return (
    <Box display="flex" flexDirection="column">
      <Box alignItems="center" display="flex">
        <Skeleton height="48px" variant="circular" width="48px" />
        <Box ml="8px">
          <Skeleton height="60px" variant="text" width="250px" />
        </Box>
      </Box>
      <Box width="100%">
        <Skeleton height="100px" variant="rectangular" />
      </Box>
      <hr />
      <Box alignItems="center" display="flex">
        <Skeleton height="48px" variant="circular" width="48px" />
        <Box ml="8px">
          <Skeleton height="60px" variant="text" width="250px" />
        </Box>
      </Box>
      <Box width="100%">
        <Skeleton height="100px" variant="rectangular" />
      </Box>
    </Box>
  )
}

export default Loading
