import Check from '@mui/icons-material/Check'
import { StepIconProps } from '@mui/material'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'

import * as S from './Steps.styles'

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props

  return (
    <S.QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </S.QontoStepIconRoot>
  )
}

type StepsProps = {
  steps: string[]
  activeStep: number
}

const Steps = ({ steps, activeStep }: StepsProps) => {
  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      connector={<S.QontoConnector />}
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}
export default Steps
