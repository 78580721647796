import { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import {
  Autocomplete,
  Button,
  Dialog,
  StateParams,
  Textarea,
  Textfield
} from '@clientbase/clientbase-library'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Typography } from '@mui/material'
import { AutoCompleteBillingsParams } from 'models/Billings/AutoComplete'
import moment from 'moment'

import { DatePicker } from 'components/DatePicker'
import UploadComponent from 'components/UploadComponent'

import { formatMoney } from 'utils'
import { formatDate } from 'utils/FormatDate'

import { theme } from 'styles/theme'

import { schemaFormMarkAsPaid } from '../schemaMarkAsPaid'

export interface MarkAsPaidModalForm {
  amountPaid: string
  datePaid: Date
  directType: DirectTypeParams
  comment: string
}

interface DirectTypeParams {
  label: string
  value: string
}

interface MarkAsPaidBodyModalParams {
  amountBilled: string
  customerName: string
  dueDate: string
  submitInvoice: SubmitHandler<MarkAsPaidModalForm>
  onCloseModal: () => void
  uuid: string
  uploadRef: any
}

type DialogProps = {
  isOpen: boolean
  description: string
  title: string
  handleClose: () => void
  action: () => void
}

export const MarkAsPaidBodyModal = ({
  amountBilled,
  customerName,
  dueDate,
  submitInvoice,
  onCloseModal,
  uuid,
  uploadRef
}: MarkAsPaidBodyModalParams) => {
  const [loading, setLoading] = useState(false)
  const [dialog, setDialog] = useState<DialogProps>({
    isOpen: false,
    description: '',
    title: '',
    handleClose: () => null,
    action: () => null
  })

  const handleOpenDialog = ({
    title,
    description,
    handleClose,
    action
  }: {
    action: () => void
    handleClose: () => void
    title: string
    description: string
  }) => {
    setDialog({ title, description, isOpen: true, handleClose, action })
  }

  const handleCloseDialog = () => {
    setDialog((s) => ({ ...s, isOpen: false }))
  }

  const submit = async (dataForm: MarkAsPaidModalForm) => {
    setLoading(true)

    const response = await submitInvoice(dataForm)

    setLoading(response as boolean)
  }

  const handleClickToSubmit: SubmitHandler<MarkAsPaidModalForm> = async (
    dataForm
  ) => {
    handleOpenDialog({
      title: 'Registrar Recebimento',
      description: 'Confirma registrar recebimento para este cliente?',
      handleClose: handleCloseDialog,
      action: () => submit(dataForm)
    })
  }

  const { handleSubmit, control } = useForm<MarkAsPaidModalForm>({
    defaultValues: {
      datePaid: new Date(moment().format())
    } as MarkAsPaidModalForm,
    resolver: yupResolver(schemaFormMarkAsPaid)
  })

  const optionsPayment = [
    { label: 'Pix', value: 'pix' },
    { label: 'Cartão de Crédito', value: 'credit_card' },
    { label: 'Boleto', value: 'boleto' },
    { label: 'Dinheiro', value: 'cash' },
    { label: 'Transferência (TED/DOC)', value: 'transfer' },
    { label: 'Outro', value: 'other' }
  ]
  return (
    <>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          {renderFields('Cliente: ', customerName)}
          {renderFields('Valor: ', formatMoney(Number(amountBilled)))}
          {renderFields('Vencimento: ', formatDate(dueDate) || '')}
        </Grid>
        <Grid item={true} xs={12}>
          <Controller
            control={control}
            name="datePaid"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <DatePicker
                helperText={error?.message}
                error={!!error?.message}
                label="Escolha a data de pagamento:"
                onChange={onChange}
                renderInput={(props: any) => <Textfield {...props} />}
                toolbarPlaceholder="Escolha a data de vencimento"
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item={true} xs={6}>
          <Controller
            control={control}
            name="amountPaid"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Textfield
                customStartAdornment={
                  <Typography mr="6px" variant="mdLight">
                    R$
                  </Typography>
                }
                helperText={error?.message}
                error={!!error?.message}
                label="Valor pago?"
                mask="money"
                name="amountPaid"
                onChange={onChange}
                startAdornment={true}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item={true} xs={6}>
          <Controller
            control={control}
            name="directType"
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(_, value) => {
                  const newValue = value as StateParams
                  onChange(newValue)
                }}
                options={optionsPayment}
                renderOption={(props, option) => {
                  const optionTyped = option as AutoCompleteBillingsParams
                  return (
                    <Box component="li" {...props} key={optionTyped.value}>
                      {optionTyped.label}
                    </Box>
                  )
                }}
                renderInput={(params) => (
                  <Textfield
                    helperText={
                      control.getFieldState('directType.value')?.error?.message
                    }
                    error={
                      !!control.getFieldState('directType.value')?.error
                        ?.message
                    }
                    label="Meio de recebimento:"
                    {...params}
                  />
                )}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <Controller
            control={control}
            name="comment"
            render={({ field: { onChange, value } }) => (
              <Textarea
                fullWidth={true}
                onChange={onChange}
                placeholder="Descrição"
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <UploadComponent
            uuid={uuid}
            title="Enviar comprovante"
            subTitle="Anexe aqui seu comprovante de pagamento."
            maxFiles={1}
            type="billings"
            noSuppressAuto={true}
            docType="receipt"
            ref={uploadRef}
            sentIcon={false}
          />
        </Grid>

        <Grid item={true} xs={6}>
          <Button
            onClick={onCloseModal}
            sx={{ backgroundColor: theme.palette.neutral[400] }}
            width="100%"
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item={true} xs={6}>
          <Button
            onClick={handleSubmit(handleClickToSubmit)}
            sx={{ backgroundColor: theme.palette.primary[300] }}
            width="100%"
          >
            Confirmar
          </Button>
        </Grid>
      </Grid>

      <Dialog
        icon="description"
        title={dialog.title}
        description={dialog.description}
        setIsOpenDialog={dialog.handleClose}
        isOpenDialog={dialog.isOpen}
        cancelButton
        cancelButtonLabel="NÃO"
      >
        <Button
          autoFocus
          disabled={loading}
          hasFocus
          onClick={dialog.action}
          loading={loading}
          fullWidth
        >
          SIM
        </Button>
      </Dialog>
    </>
  )

  function renderFields(label: string, value: string) {
    return (
      <Box display="flex">
        <Box mr="4px">
          <Typography variant="mdLight">{label}</Typography>
        </Box>
        <Box>
          <Typography variant="mdBold">{value}</Typography>
        </Box>
      </Box>
    )
  }
}
