import styled from '@mui/system/styled'

import { theme } from 'styles/theme'

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}))

export const SelectWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#F5F7F5',
  height: '100%',
  font: 'normal 400 16px Inter, "sans-serif"',

  '&, & *': {
    border: 0
  },

  borderRadius: 4,

  outline: `0px solid ${theme.palette.neutral[200]}20`,
  transition: 'outline 70ms linear',

  '&:hover, &:focus-within': {
    outlineWidth: 2
  }
}))
