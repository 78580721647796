import { useAuthContext, UserRole, UserStatus } from 'context/AuthContext'

type CanProps = {
  roles: UserRole[] | UserRole
  status?: UserStatus[] | UserStatus
}

const CanLogical = ({ roles, status }: CanProps) => {
  const { user } = useAuthContext()

  if (!user) return null

  const userRoles = Array.isArray(roles) ? roles : [roles]

  if (userRoles.includes(user.role) && !status) {
    return true
  }

  const userStatus = Array.isArray(status) ? status : [status]

  if (userRoles.includes(user.role) && userStatus.includes(user.status)) {
    return true
  }

  return false
}

export default CanLogical
