import React from 'react'

import { Icon, Icons } from '@clientbase/clientbase-library'

import * as S from './Modal.styles'

export type ModalProps = {
  children?: React.ReactNode
  heading: string
  icon: Icons
  colorIcon?: string
  isOpen: boolean
  handleToggle: () => void
  handleReloadTable?: () => void
  headerOrder?: number
  orderHead?: 'top' | 'bottom'
}

const Modal = ({
  children,
  heading,
  icon,
  isOpen,
  handleToggle,
  headerOrder = 0,
  colorIcon,
  orderHead = 'top'
}: ModalProps) => (
  <S.Wrapper open={isOpen} onClose={handleToggle}>
    <S.Content id="modal">
      <S.Header>
        {orderHead === 'top' && (
          <S.Heading order={headerOrder}>{heading}</S.Heading>
        )}
        <S.IconWrapper>
          <Icon icon={icon} width="48px" height="48px" colorSX={colorIcon} />
        </S.IconWrapper>
        {orderHead === 'bottom' && (
          <S.Heading order={headerOrder}>{heading}</S.Heading>
        )}
      </S.Header>
      {children}
      <S.CloseButton onClick={handleToggle}>
        <Icon icon="close" />
      </S.CloseButton>
    </S.Content>
  </S.Wrapper>
)

export default Modal
