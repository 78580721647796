import moment from 'moment'
import 'moment/locale/pt-br'

moment.locale('pt-br')

export class TimezoneHelper {
  public static format(dateToFormat: Date, format = 'MM-DD-YYYY') {
    if (!dateToFormat) {
      return null
    }

    return moment(dateToFormat).format(format)
  }

  public static useMoment(data?: any, format?: any) {
    return moment(data, format)
  }
}
