import moment from 'moment'

export type PreviewCreditCardMapperParams = {
  uuid: string
  card_brand: number | null
  first_4_digits: string | null
  last_4_digits: string | null
  is_active: boolean
  is_valid: boolean
  is_verified: boolean
  holder_name: string
  created_at: string
}

const cardBrandMapper = {
  1: '/images/visa.svg',
  2: '/images/mastercard.svg',
  3: '/images/amex.svg',
  4: '/images/diners.svg',
  5: '/images/jcb.svg',
  6: '/images/discover.svg',
  7: '/images/hipercard.svg',
  8: '/images/elo.svg',
  9: '/images/aura.svg',
  10: '/images/hsbc.svg',
  11: '/images/unknown.svg'
}

export const previewCreditCardMapper = (
  params: PreviewCreditCardMapperParams
) =>
  ({
    uuid: params.uuid,
    name: params.holder_name,
    cardBrand: params?.card_brand && cardBrandMapper[params.card_brand],
    first4Digits: params.first_4_digits,
    last4Digits: params.last_4_digits,
    isActive: params?.is_active,
    isValid: params?.is_valid,
    isVerified: params?.is_verified,
    createdAt: params?.created_at
  } as const)

export const previewCreditCardsMapper = (
  params: PreviewCreditCardMapperParams[]
) =>
  params.map(
    (creditCard) =>
      ({
        uuid: creditCard.uuid,
        name: creditCard.holder_name,
        cardBrand: creditCard?.card_brand && cardBrandMapper[1],
        first4Digits: creditCard.first_4_digits,
        last4Digits: creditCard.last_4_digits,
        isActive: creditCard?.is_active,
        isValid: creditCard?.is_valid,
        isVerified: creditCard?.is_verified,
        createdAt: `Data do cadastro: ${moment(
          creditCard?.created_at,
          'YYYY-MM-DDTHH:mm:ss'
        )
          .format('DD/MM/YYYY HH:mm:ss')
          .toString()}`
      } as const)
  )

export const selectCreditCardsMapper = (
  params: PreviewCreditCardMapperParams[]
) => {
  return params.map(
    (creditCard) =>
      ({
        value: creditCard.uuid,
        label: `${creditCard.holder_name} - ${creditCard.first_4_digits} **** **** ${creditCard.last_4_digits}`
      } as const)
  )
}

export type Card = ReturnType<typeof previewCreditCardMapper>
export type Cards = ReturnType<typeof previewCreditCardsMapper>
