import React, { useContext, useEffect, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  Button as ClientBaseButton,
  Modal
} from '@clientbase/clientbase-library'
import { Box } from '@mui/material'
import { DataService } from 'api/DataService'
import { posExpiration } from 'models/Charge/NotificationDetail'
import { constructorInvoice, InvoiceParams } from 'models/Invoice'
import moment from 'moment'
import { NFeService } from 'services/NFeService'
import {
  MarkAsPaidBodyModal,
  MarkAsPaidModalForm
} from 'templates/Charge/Modals/MarkAsPaidBodyModal'
import { getChargeEnum } from 'templates/v2/Products/Page/Products.utils'

import ModalEditCharge from 'components/Modal/ModalEditCharge'
import ReverseForm from 'components/ReverseForm'
import ButtonV2 from 'components/v2/Button'
import Dialog from 'components/v2/Dialog'

import { currency } from 'utils'
import { toastProps } from 'utils/types/toast-props'

import ModalInvoiceDetailsContent from '../ModalInvoiceDetails/ModalInvoiceDetailsContent'
import ModalRecurrenceDetail from '../ModalRecurrenceDetail'
import Actions from './components/Actions'
import Attemps from './components/Attemps'
import BillingDetails from './components/BillingDetails'
import Header from './components/Header'
import Historic from './components/Historic'
import InternalIdentifier from './components/InternalIdentifier'
import Loading from './components/Loading'
import NegativateDetail from './components/NegativateDetail'
import NFe from './components/NFe'
import NotificationDetails from './components/NotificationDetails'
import PayerDetails from './components/PayerDetails'
import PaymentDetails from './components/PaymentDetails'
import { ModalChargeDetailContext } from './context'
import * as S from './ModalChargeDetail.styles'

interface DetailChargeBodyModalParams {
  uuid: string
  onClose: (reloadTable?: boolean) => void
  setReloadTable?: React.Dispatch<React.SetStateAction<boolean>>
}

const ModalChargeDetailBody = ({
  uuid,
  onClose,
  setReloadTable
}: DetailChargeBodyModalParams) => {
  const {
    updateInvoice,
    updatePaids,
    updatePaymentType,
    updateModal,
    updateTab,
    updateNfe,
    updateReload,
    updateDialog,
    updateDialogReverse,
    dialogReverse,
    modal,
    upload,
    invoice,
    dialog,
    updateModalNfDetails,
    modalNfDetails,
    reload
  } = useContext(ModalChargeDetailContext)

  const [loadingModal, setLoadingModal] = useState<boolean>(true)

  const handleReloadTable = () => {
    setReloadTable && setReloadTable((prevState) => !prevState)
  }

  //Verificar
  const handleCloseModalNFDetails = () => {
    updateModalNfDetails(false)
  }

  const getInvoice = async (uuid: string) => {
    try {
      setLoadingModal(true)
      const { data } = await DataService({
        type: 'GET',
        url: `https://api.clientbase.com.br/v1/billings/${uuid}/invoice`
      })
      const invoice = constructorInvoice(data.record)

      const types = invoice?.paymentType
        .split(';')
        .filter((type) => type !== 'boleto_pix')
        .map((type) => {
          return getChargeEnum(type)
        })

      const paymentPaid = invoice?.payments.filter(
        (payment) => payment.status === 'paid'
      )

      updatePaymentType(types.filter(Boolean))
      updatePaids(paymentPaid)
      updateInvoice(invoice)
      setLoadingModal(false)
      checkExpiration(invoice)
    } catch (err) {
      toast.error('Erro ao carregar a cobrança.')
    }
  }

  const submitInvoice: SubmitHandler<MarkAsPaidModalForm> = async (
    dataForm
  ) => {
    const id = toast.loading('Registrando cobrança como paga...')
    const { amountPaid, comment, datePaid, directType } = dataForm

    const data = {
      amount_paid: '',
      comment,
      date_paid: moment(datePaid).format('YYYY-MM-DD'),
      direct_type: directType.value
    }

    if (amountPaid) {
      const value = currency(amountPaid).replace('.', '').replace(',', '.')
      data.amount_paid = value
    }

    const response = await DataService({
      data,
      type: 'POST',
      url: `/v1/billings/${uuid}/pay`
    })

    const errorMessage =
      response.error && (response.detail_error || response.message)

    toast.update(id, {
      render: response.error ? errorMessage : response.data.message,
      type: response.error ? 'error' : 'success',
      ...toastProps
    })

    if (response.status === 200) {
      const ref = upload?.current
      ref?.state?.uploadedFiles.forEach((file) =>
        ref.processUpload(file, response?.data?.record?.uuid)
      )

      handleCloseMarkAsPaid()
      setTimeout(() => {
        getInvoice(uuid)
      }, 2500)
    }
    return false
  }

  const getNFdetails = async () => {
    const detail = await NFeService.getNFeDetails(invoice.nfse_uuid as string)
    updateNfe(detail)
  }

  useEffect(() => {
    if (invoice?.nfsesScheduled) {
      getNFdetails()
    }
  }, [invoice])

  const checkExpiration = (invoice: InvoiceParams) => {
    const hasProExpiration = invoice?.messageTracking?.some((item) => {
      return posExpiration.includes(item.campaign)
    })
    updateTab(hasProExpiration ? 1 : 0)
  }

  function handleDialogCancelClose() {
    updateModal((prevState) => ({
      ...prevState,
      isOpenDialogCancel: false
    }))
  }

  async function handleDialogCancelConfirm() {
    updateModal((prevState) => ({ ...prevState, loading: true }))

    const id = toast.loading('Cancelando a fatura...')

    const response = await DataService({
      type: 'DELETE',
      url: `/v1/billings/${modal.uuid}`
    })

    updateModal((prevState) => ({
      ...prevState,
      isOpenDialogCancel: false,
      isOpenModalRecurrenceDetail: true
    }))

    const errorMessage =
      response.error && (response.detail_error || response.message)

    toast.update(id, {
      render: response.error ? errorMessage : response.data.message,
      type: response.error ? 'error' : 'success',
      ...toastProps
    })

    !response.error && !!setReloadTable && setReloadTable((s) => !s)

    onClose(true)
  }

  const handleCloseDetailRecurrence = (reload?: boolean) => {
    updateModal((s) => ({
      ...s,
      uuidRecurrenceDetail: '',
      isOpenModalRecurrenceDetail: false
    }))

    !!reload && updateReload((prevState) => !prevState)
  }

  function handleCloseDialogAntecipate() {
    updateModal((prevState) => ({
      ...prevState,
      isOpenDialogAntecipate: false,
      uuid: ''
    }))
  }

  async function handleDialogAntecipateConfirm() {
    updateModal((prevState) => ({ ...prevState, loading: true }))

    const id = toast.loading('Antecipando a fatura...')

    const response = await DataService({
      type: 'GET',
      url: `/v1/billings/${modal.uuid}/antecipate`
    })

    const errorMessage =
      response.error && (response.detail_error || response.message)

    toast.update(id, {
      render: response.error ? errorMessage : response.data.message,
      type: response.error ? 'error' : 'success',
      ...toastProps
    })

    updateModal((prevState) => ({
      ...prevState,
      isOpenDialogAntecipate: false,
      loading: false,
      uuid: ''
    }))
  }

  function handleCloseMarkAsPaid() {
    updateModal((prevState) => ({
      ...prevState,
      isOpenModalPaid: false
    }))
    !!setReloadTable && setReloadTable((s) => !s)
  }

  const handleCloseEditCharge = () => {
    updateModal((prevState) => ({ ...prevState, isOpenEditCharge: false }))
  }

  const reloadCharge = () => {
    updateReload((prevState) => !prevState)
  }

  const handleCloseReverseForm = (success?: boolean) => {
    updateDialog((s) => ({ ...s, isOpenDialog: false }))
    !!success && updateDialogReverse({ isOpen: false, uuid: '' })
    !!success && updateReload((prevState) => !prevState)
  }

  const handleCloseCancelManuallyReceiptDialog = () => {
    updateDialog({
      isOpenDialog: false,
      action: () => ({}),
      description: '',
      title: '',
      icon: 'info'
    })
  }

  useEffect(() => {
    if (uuid) getInvoice(uuid)
  }, [uuid, reload])

  if (loadingModal) {
    return <Loading />
  }

  return (
    <div>
      <S.SessionWrapper>
        <Header />
        <BillingDetails />
        <PayerDetails />
        <PaymentDetails />
        <NotificationDetails />
        <NegativateDetail />
        <Historic />
        <NFe />
        <InternalIdentifier />
        <Attemps />
      </S.SessionWrapper>
      <Actions />
      {Object.keys(invoice).length > 1 && (
        <ModalEditCharge
          isOpenModalEditCharge={modal.isOpenEditCharge}
          handleCloseModal={handleCloseEditCharge}
          uuid={uuid}
          charge={invoice}
          reloadCharge={reloadCharge}
          setReloadTable={setReloadTable}
        />
      )}
      {modal.isOpenModalRecurrenceDetail && (
        <ModalRecurrenceDetail
          isOpenModalRecurrenceDetail={modal.isOpenModalRecurrenceDetail}
          handleCloseDetailRecurrence={() => handleCloseDetailRecurrence(false)}
          uuid={modal.uuidRecurrenceDetail}
        />
      )}

      <Dialog
        icon="info"
        title="CANCELAR FATURA"
        description="Confirmar o cancelamento desta fatura?"
        setIsOpenDialog={handleDialogCancelClose}
        isOpenDialog={modal.isOpenDialogCancel}
      >
        <Box display="flex" alignItems="center" gap={1} width="100%">
          <ClientBaseButton onClick={handleDialogCancelClose} fullWidth>
            Não
          </ClientBaseButton>
          <ClientBaseButton
            disabled={modal.loading}
            onClick={handleDialogCancelConfirm}
            fullWidth
            loading={modal.loading}
          >
            Sim
          </ClientBaseButton>
        </Box>
      </Dialog>
      <Dialog
        icon="info"
        title="ANTECIPAR FATURA"
        description="Confirmar o antecipamento desta fatura?"
        setIsOpenDialog={handleCloseDialogAntecipate}
        isOpenDialog={modal.isOpenDialogAntecipate}
      >
        <Box display="flex" alignItems="center" gap={1} width="100%">
          <ButtonV2
            variant="contained"
            onClick={handleCloseDialogAntecipate}
            fullWidth
          >
            Não
          </ButtonV2>
          <ButtonV2
            disabled={modal.loading}
            onClick={handleDialogAntecipateConfirm}
            variant="contained"
            fullWidth
          >
            Sim
          </ButtonV2>
        </Box>
      </Dialog>
      <Modal
        disablePortal={false}
        handleClose={handleCloseMarkAsPaid}
        open={modal.isOpenModalPaid}
        height="auto !important"
        width="100%"
        title="Informações do pagamento"
      >
        <MarkAsPaidBodyModal
          amountBilled={invoice?.amountBilled}
          customerName={invoice?.customer?.name || ''}
          dueDate={invoice?.dueDate}
          onCloseModal={handleCloseMarkAsPaid}
          submitInvoice={submitInvoice}
          uploadRef={upload}
          uuid={invoice?.customer?.uuid || ''}
        />
      </Modal>
      <Dialog
        icon="paid"
        iconColor="#56BB4D"
        title="Informe o valor que será estornado"
        description="Estornos parciais só podem ser feitos no dia seguinte ao pagamento"
        setIsOpenDialog={() => updateDialogReverse({ isOpen: false, uuid: '' })}
        isOpenDialog={dialogReverse.isOpen}
      >
        <ReverseForm
          uuid={dialogReverse.uuid}
          handleClose={handleCloseReverseForm}
          setDialog={updateDialog}
          setReloadTable={setReloadTable}
        />
      </Dialog>
      <Dialog
        icon={dialog.icon}
        title={dialog.title}
        description={dialog.description}
        setIsOpenDialog={handleCloseCancelManuallyReceiptDialog}
        isOpenDialog={dialog.isOpenDialog}
        cancelButton
      >
        <ButtonV2
          variant="contained"
          onClick={() => {
            setLoadingModal(true)
            dialog.action()
            setLoadingModal(false)
          }}
          disabled={loadingModal}
        >
          SIM
        </ButtonV2>
      </Dialog>
      <Modal
        handleClose={handleCloseModalNFDetails}
        open={modalNfDetails}
        width="790px"
      >
        <ModalInvoiceDetailsContent
          uuid={invoice.nfse_uuid as string}
          handleReloadTable={handleReloadTable}
          handleCloseModal={handleCloseModalNFDetails}
        />
      </Modal>
    </div>
  )
}

export default ModalChargeDetailBody
