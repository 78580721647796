import { SVGProps } from 'react'

interface SVGRProps {
  title?: string
  titleId?: string
}

const RingsIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    fill="none"
    aria-labelledby={titleId}
    {...props}
    style={{ transform: 'scale(0.8)' }}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#000"
      stroke="#000"
      strokeLinejoin="round"
      strokeWidth={0.2}
      d="M4.855 20.199a.1.1 0 0 0 .1.1h16.282a.1.1 0 0 0 .1-.1v-1.526a.1.1 0 0 0-.1-.1h-1.935v-7.634c0-1.446-.427-2.743-1.28-3.889-.837-1.125-1.939-1.841-3.3-2.145v-.479c0-.45-.159-.836-.474-1.152a1.572 1.572 0 0 0-1.152-.474c-.45 0-.836.158-1.152.474a1.572 1.572 0 0 0-.475 1.152v.48c-1.36.303-2.462 1.019-3.3 2.144C7.317 8.196 6.89 9.493 6.89 10.94v7.634H4.955a.1.1 0 0 0-.1.1v1.526Zm6.206.918a.1.1 0 0 0-.1.1c0 .586.21 1.09.627 1.508a2.06 2.06 0 0 0 1.508.627 2.06 2.06 0 0 0 1.508-.627 2.06 2.06 0 0 0 .627-1.508.1.1 0 0 0-.1-.1h-4.07Zm6.514-2.544H8.617v-7.634c0-1.246.434-2.302 1.306-3.173.871-.872 1.927-1.306 3.173-1.306s2.301.434 3.173 1.306c.871.871 1.306 1.927 1.306 3.173v7.634Z"
    />
  </svg>
)
export default RingsIcon
