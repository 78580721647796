import { Box, styled } from '@mui/material'

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'rgb(255, 255, 255)',
  overflow: 'hidden',
  borderRadius: '8px',
  boxShadow:
    'rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px',
  boxSizing: 'border-box',
  left: '50%',
  padding: '0 0 32px 0',
  position: 'absolute',
  top: '25%',
  transform: 'translate(-50%, -50%)',
  width: '40%'
}))

export const Content = styled(Box)(() => ({
  padding: '16px',
  position: 'relative'
}))

export const BigScreenButton = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  aspectRatio: '1/1',
  width: '50px',
  height: '50px',
  cursor: 'pointer',
  marginBottom: '6px'
}))
