import { styled } from '@mui/material'

export const Header = styled('div')(() => ({
  borderTopLeftRadius: '3px',
  borderTopRightRadius: '3px',
  background: '#F8F8F8',
  paddingTop: '4px',
  paddingBottom: '4px',
  fontSize: '16px',
  fontWeight: '600',
  textAlign: 'center'
}))

export const Content = styled('div')(() => ({
  border: '1px solid #EDEBEB'
}))

export const Section = styled('div')(() => ({
  width: '100%'
}))
