import styled from '@mui/system/styled'

type WrapperProps = {
  pt: boolean
}

export const Wrapper = styled('div')<WrapperProps>(({ pt }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  ...(!!pt && { paddingTop: '15vmin' })
}))

export const ImageWrapper = styled('div')(() => ({
  width: 90,
  height: 90,
  position: 'relative',
  '& img': {
    objectFit: 'fill'
  }
}))
