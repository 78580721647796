/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react'

import { Button, Icon } from '@clientbase/clientbase-library'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Input,
  Modal,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'

import Status from 'components/Status'
import { StatusKeys } from 'components/Status/Status'

import * as S from './SearchModal.styles'

interface SearchModalProps {
  isOpen: boolean
  handleCloseFunction: () => void
  placeholder: string
  inputValue: string
  handleInputChangeFunction: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void
  clearInputFunction: () => void
  results: ResultType[]
  loadingResults: boolean
}

export type ResultType = {
  title: string
  nickname?: string
  subtitle: string
  type: InfoKeys
  status: string
  dueDate?: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  clickFunction: Function
  uuid?: string
}

export type InfoType = {
  label: string
  color: string
}

const resultTypeInfos = {
  client: {
    label: 'Cliente',
    color: 'rgba(86, 187, 77, 0.2)'
  },
  billing: {
    label: 'Cobrança',
    color: 'rgba(89, 198, 211, 0.2)'
  }
}

type InfoKeys = keyof typeof resultTypeInfos

const SearchModal = ({
  isOpen,
  handleCloseFunction,
  placeholder,
  inputValue,
  handleInputChangeFunction,
  clearInputFunction,
  results,
  loadingResults
}: SearchModalProps) => {
  const { push } = useRouter()
  const [tab, setTab] = useState<'client' | 'billing'>('client')

  const clients = results.filter((result) => result.type == 'client')
  const billings = results.filter((result) => result.type == 'billing')
  const isMobile = useMediaQuery('(max-width: 600px)', { noSsr: false })

  const handleClientClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    uuid: string
  ) => {
    if (!e.ctrlKey) {
      push(`/clientes/${uuid}`)
      handleCloseFunction()
    } else {
      window.open(`/clientes/${uuid}`, '_blank')
    }
  }
  return (
    <Modal open={isOpen} onClose={handleCloseFunction}>
      <S.Container>
        <S.Content>
          {!isMobile && (
            <Tooltip
              enterTouchDelay={0}
              title="Fechar Pesquisa"
              placement="top"
            >
              <S.CloseButton size="small" onClick={() => handleCloseFunction()}>
                <Icon icon="close" fontSize="small" />
              </S.CloseButton>
            </Tooltip>
          )}

          <S.SearchBox hasResults={results.length > 0}>
            {loadingResults ? (
              <FontAwesomeIcon
                icon={faSpinner}
                className="spinner"
                style={{
                  marginRight: '8px'
                }}
                width={50}
                height={50}
              />
            ) : !isMobile ? (
              <Icon icon="search" fontSize="large" />
            ) : (
              <Image
                src="/only-icon.png"
                width={50}
                height={50}
                alt="only-icon"
              />
            )}

            {isMobile ? (
              <S.MobileSearchBox>
                <Input
                  placeholder={placeholder}
                  disableUnderline
                  value={inputValue}
                  onChange={handleInputChangeFunction}
                  sx={{ width: '100%' }}
                  autoFocus
                />

                {inputValue !== '' && (
                  <Tooltip
                    enterTouchDelay={0}
                    title="Limpar resultados"
                    placement="left"
                    hidden={inputValue == ''}
                  >
                    <Button
                      variantButton="transparent"
                      onClick={clearInputFunction}
                    >
                      Limpar
                    </Button>
                  </Tooltip>
                )}
              </S.MobileSearchBox>
            ) : (
              <>
                <Input
                  placeholder={placeholder}
                  disableUnderline
                  value={inputValue}
                  onChange={handleInputChangeFunction}
                  sx={{ width: '100%', fontSize: '20px' }}
                  autoFocus
                />

                {inputValue !== '' && (
                  <Tooltip
                    enterTouchDelay={0}
                    title="Limpar resultados"
                    placement="left"
                  >
                    <Button
                      variantButton="transparent"
                      onClick={clearInputFunction}
                    >
                      Limpar
                    </Button>
                  </Tooltip>
                )}
              </>
            )}

            {isMobile && (
              <Tooltip
                enterTouchDelay={0}
                title="Fechar Pesquisa"
                placement="top"
              >
                <S.CloseButton
                  size="small"
                  onClick={() => handleCloseFunction()}
                >
                  <Icon icon="close" fontSize="small" />
                </S.CloseButton>
              </Tooltip>
            )}
          </S.SearchBox>
          <S.ResultsContainer hasResults={inputValue !== ''}>
            <S.ResultsList>
              <S.TabButtons>
                <Button
                  onClick={() => setTab('client')}
                  variantButton={tab === 'client' ? 'contained' : 'gray'}
                  bgColor="#01F7C2"
                  style={{ borderRadius: '4px' }}
                >
                  Clientes
                </Button>

                <Button
                  onClick={() => setTab('billing')}
                  variantButton={tab === 'billing' ? 'contained' : 'gray'}
                  style={{ borderRadius: '4px' }}
                  bgColor="#01F7C2"
                >
                  Cobranças
                </Button>
              </S.TabButtons>
              {tab === 'client' &&
                clients.map((result) => (
                  <Link
                    key={result.title}
                    href={`/clientes/${result.uuid}`}
                    prefetch
                    passHref
                    legacyBehavior
                  >
                    <S.Result
                      key={result.title}
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      onClick={(e) => handleClientClick(e, result.uuid!)}
                    >
                      <S.ResultDetails>
                        <Box display="flex" gap="10px" alignItems="center">
                          <S.ResultTitle>{result.title}</S.ResultTitle>
                          {!!result.nickname && (
                            <S.ResultSubtitle>
                              {result.nickname}
                            </S.ResultSubtitle>
                          )}
                        </Box>
                        <S.ResultSubtitle>{result.subtitle}</S.ResultSubtitle>
                      </S.ResultDetails>
                      <Box fontSize={'12px'}>
                        {result.status === 'archived' && (
                          <Status status={result.status} />
                        )}
                      </Box>
                    </S.Result>
                  </Link>
                ))}

              {tab === 'billing' &&
                billings.map((result) => (
                  <S.Result
                    key={result.title}
                    onClick={() => {
                      result.clickFunction()
                      handleCloseFunction()
                    }}
                  >
                    <S.ResultDetails>
                      <Box display="flex" gap="10px" alignItems="center">
                        <S.ResultTitle>{result.title}</S.ResultTitle>
                        {!!result.nickname && (
                          <S.ResultSubtitle>{result.nickname}</S.ResultSubtitle>
                        )}
                      </Box>
                      <S.ResultSubtitle>{result.subtitle}</S.ResultSubtitle>
                    </S.ResultDetails>
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      width={'80px'}
                      fontSize={'12px'}
                    >
                      <Status
                        status={result.status as StatusKeys}
                        dueDate={result.dueDate as string}
                      />
                    </Box>
                  </S.Result>
                ))}

              {tab === 'client' && !clients.length ? (
                <Typography>
                  Não foi encontrado nenhum cliente para a busca informada.
                </Typography>
              ) : (
                <></>
              )}
              {tab === 'billing' && !billings.length ? (
                <Typography>
                  Não foi encontrada nenhuma cobrança para a busca informada.
                </Typography>
              ) : (
                <></>
              )}
            </S.ResultsList>
          </S.ResultsContainer>
        </S.Content>
      </S.Container>
    </Modal>
  )
}

export default SearchModal
