export const getStatus = (status: string) => {
  switch (status) {
    case 'processing':
      return {
        label: 'Processando'
      }
    case 'waiting_signatures':
      return {
        label: 'Aguardando Assinaturas'
      }
    case 'current':
      return {
        label: 'Vigente'
      }
    case 'closed':
      return {
        label: 'Encerrado'
      }
    case 'pending':
      return {
        label: 'Pendente'
      }
    case 'error':
      return {
        label: 'Error'
      }
    case 'cancelling':
      return {
        label: 'Cancelada'
      }
    default:
      window.newrelic.noticeError(
        new Error(
          `Erro ao mapear status do modal de detalhes de contrato: ${status}`
        )
      )
      return {
        label: 'Status desconhecido'
      }
  }
}
