import React from 'react'

import { Icon, Icons } from '@clientbase/clientbase-library'
import { Breakpoint, DialogContent } from '@mui/material'
import MUIDialog from '@mui/material/Dialog'

import Button from 'components/v2/Button'

import * as S from './Dialog.styles'

export type DialogProps = {
  title: string
  icon?: Icons
  children: React.ReactNode
  description?: string
  iconColor?: string
  cancelButton?: boolean
  cancelButtonVariant?: 'outlined' | 'text' | 'contained' | 'gray'
  isOpenDialog?: boolean
  setIsOpenDialog?: React.Dispatch<React.SetStateAction<boolean>>
  maxWidth?: Breakpoint | undefined
  fullWidth?: boolean
}

const Dialog = ({
  children,
  title,
  description,
  iconColor = '#888',
  cancelButton = false,
  cancelButtonVariant = 'contained',
  icon,
  isOpenDialog = true,
  setIsOpenDialog,
  maxWidth,
  fullWidth
}: DialogProps) => {
  const handleToggleDialog = () => {
    !!setIsOpenDialog && setIsOpenDialog((s) => !s)
  }

  return (
    <MUIDialog
      maxWidth={maxWidth}
      onClose={handleToggleDialog}
      open={isOpenDialog}
      fullWidth={fullWidth}
    >
      <S.Wrapper>
        <S.CloseIconWrapper onClick={handleToggleDialog}>
          <Icon icon="close" />
        </S.CloseIconWrapper>

        {!!icon && (
          <S.IconWrapper>
            <Icon icon={icon} sx={{ color: iconColor }} />
          </S.IconWrapper>
        )}

        <DialogContent>
          <S.Heading>{title}</S.Heading>
          {!!description && <S.Description>{description}</S.Description>}
        </DialogContent>

        <S.ActionsWrapper cancelButton={cancelButton}>
          {!!cancelButton && (
            <Button
              variant={cancelButtonVariant}
              fullWidth
              onClick={handleToggleDialog}
            >
              CANCELAR
            </Button>
          )}

          {children}
        </S.ActionsWrapper>
      </S.Wrapper>
    </MUIDialog>
  )
}

export default Dialog
