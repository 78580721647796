import React from 'react'

import { Box, Grid, Typography } from '@mui/material'
import Image from 'next/image'

import * as S from './Footer.styles'

const Footer: React.FC = () => {
  return (
    <S.Wrapper container={true} spacing={2}>
      <Grid
        item={true}
        xs={12}
        sm={12}
        md={5}
        lg={5}
        display="flex"
        justifyContent={{ sm: 'center', md: 'end' }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="14px"
          maxWidth="540px"
          width="100%"
        >
          <Typography
            variant="mdBold"
            color={(theme: any) => theme.palette.neutral[400]}
          >
            Atenção!
          </Typography>
          <Typography
            variant="smLight"
            color={(theme: any) => theme.palette.neutral[400]}
          >
            <b>
              Lembramos que a negativação de uma cobrança não paga deve ser
              feita com seriedade.
            </b>{' '}
            Recomendamos aguardar 30 dias após o vencimento antes de negativar.
            Além disso, é recomendado contatar o cliente de maneira amigável em
            algumas ocasiões antes de considerar a negativação. Muitas vezes, um
            diálogo aberto e direto pode resolver a situação sem a necessidade
            de medidas mais drásticas.
          </Typography>
        </Box>
      </Grid>
      <Grid
        item={true}
        xs={12}
        sm={12}
        md={5}
        lg={5}
        display="flex"
        justifyContent={{ sm: 'center', md: 'start' }}
      >
        <Image
          alt="imagem boa vista"
          src="./images/boa-vista.svg"
          width={524}
          height={250}
        />
      </Grid>
    </S.Wrapper>
  )
}

export default Footer
