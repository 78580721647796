export interface PaymentParams {
  uuid: string | null
  customeUuid: string
  billingUuid: string
  amountBilled: string
  amountPaid: string
  datePaid: string
  dueDate: string
  fee: string
  paymentType: string
  paymentableType: string
  status: string
  createdAt: string
  updatedAt: string
  dateNoPayment: string
  merchantUuid: string
  paymentable: PaymentbleParams
  receivables: Receivables[]
}

interface Receivables {
  uuid: string
  gross_amount: string
  amount: string
  status: string
  date_paid: string
  expected_on: string
  created_at: string
  updated_at: string
  installment: number
  merchant_uuid: string
  billing_uuid: string
  payment_uuid: string
  transfer_uuid: any
}

interface PaymentbleParams {
  cardBrand: string
  createdAt: string
  fee: string
  first4Digits: string
  gatewayAuthorizer: string
  id: number
  status: string
  transactionNumber: string
  updatedAt: string
  uuid: string
  uuidSalesReceipt: string
  uuidZoop: string
  directType: string
  max_installments?: number
  max_installments_without_fee?: number
  installments?: number
}

export function constructorPayment(data: any) {
  const payments: PaymentParams[] = data.map((payment: any) => {
    return {
      amountBilled: payment?.amount_billed,
      amountPaid: payment?.amount_paid,
      billingUuid: payment?.billing_uuid,
      createdAt: payment?.created_at,
      customeUuid: payment?.customer_uuid,
      dateNoPayment: payment?.date_no_payment,
      datePaid: payment?.date_paid,
      dueDate: payment?.due_date,
      fee: payment?.fee,
      merchantUuid: payment?.merchant_uuid,
      paymentType: payment?.payment_type,
      paymentable: constructorPaymentable(payment?.paymentable),
      paymentableType: payment?.paymentable_type,
      status: payment?.status,
      updatedAt: payment?.updated_at,
      uuid: payment?.uuid,
      receivables: payment?.receivables
    } as PaymentParams
  })

  return payments
}

function constructorPaymentable(data: any) {
  return {
    ...data,
    cardBrand: data?.card_brand,
    createdAt: data?.created_at,
    directType: data?.direct_type,
    fee: data?.fee,
    first4Digits: data?.first4_digits,
    gatewayAuthorizer: data?.gateway_authorizer,
    id: data?.id,
    status: data?.status,
    totalFee: data?.total_fee,
    transactionNumber: data?.transaction_number,
    updatedAt: data?.updated_at,
    uuid: data?.uuid,
    uuidSalesReceipt: data?.uuid_sales_receipt,
    uuidZoop: data?.uuid_zoop
  } as PaymentbleParams
}
