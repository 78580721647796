import styled from '@emotion/styled'
import { rgba } from 'polished'

import { theme } from 'styles/theme'

const statusModifiers = {
  pending: {
    color: theme.palette.neutral[400],
    background: rgba(theme.palette.neutral[700], 0.3)
  },
  created_payment: {
    color: theme.palette.neutral[400],
    background: rgba(theme.palette.neutral[700], 0.3)
  },
  open_payment: {
    color: theme.palette.secondary[200],
    background: rgba(theme.palette.secondary[200], 0.1)
  },
  no_payment: {
    color: theme.palette.error[300],
    background: rgba(theme.palette.error[300], 0.1)
  },
  paid: {
    color: theme.palette.primary[200],
    background: rgba(theme.palette.primary[200], 0.2)
  },
  cancelling: {
    color: theme.palette.error[300],
    background: rgba(theme.palette.error[300], 0.1)
  },
  cancelled: {
    color: theme.palette.error[300],
    background: rgba(theme.palette.error[300], 0.1)
  },
  expired: {
    color: theme.palette.error[300],
    background: rgba(theme.palette.error[300], 0.1)
  },
  suspended: {
    color: theme.palette.neutral[700],
    background: rgba(theme.palette.neutral[700], 0.3)
  }
}

type StatusProps = {
  status: string
}

export const Status = styled('div')<StatusProps>(({ status }) => ({
  width: 'fit-content',
  padding: '4px 8px',
  fontWeight: 600,
  borderRadius: 9999,
  ...statusModifiers[status]
}))
