import { AccordionSummary as CAccordionSummary } from '@clientbase/clientbase-library'
import { styled } from '@mui/material'

type ItemsWrapperParams = { minWidth?: number }

export const ItemsWrapper = styled('div')<ItemsWrapperParams>(
  ({ minWidth = 200 }) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fit, minmax(${minWidth}px, 1fr))`,
    gridTemplateRows: 'auto',
    gap: 15,
    width: '100%'
  })
)

export const AccordionSummary = styled(CAccordionSummary)(() => ({
  '.MuiAccordionSummary-content': {
    margin: '20px 0px'
  },
  '.MuiAccordionSummary-expandIconWrapper': {
    position: 'absolute',
    right: '14px',
    transform: 'rotate(0deg)'
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)'
  }
}))
