import { useState } from 'react'

export type SelectRowTable = {
  useSelect: boolean
  selected: string[]
}

type SelectRowTableParams = {
  useSelected: boolean
}

export const useSelectRowTable = (props: SelectRowTableParams) => {
  const [select, setSelect] = useState<SelectRowTable>({
    useSelect: props.useSelected,
    selected: []
  })

  const resetSelectedFields = ({
    selected = [],
    useSelect = false
  }: SelectRowTable) => setSelect(() => ({ useSelect, selected }))

  return { select, setSelect, resetSelectedFields }
}
