import * as yup from 'yup'

export const schemaFormMarkAsPaid = yup.object({
  amountPaid: yup.string().required('Campo obrigatório.'),
  comment: yup.string(),
  datePaid: yup.date().required('Campo obrigatório.'),
  directType: yup.object({
    label: yup.string().required('Campo obrigatório.'),
    value: yup.string().required('Campo obrigatório.')
  })
})
export type FormMarkAsPaidType = yup.InferType<typeof schemaFormMarkAsPaid>
